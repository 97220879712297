import React from "react";
import "./footer.scss";
import CopyRight from "../../images/C_Icons.svg";

const CopyRightFooter = () => {
  return (
    <div className="footer_div">
      <img src={CopyRight} alt="" />
      <div>2024 AlignedAround LLC. All rights reserved </div>&ensp;|&ensp;
      <div>
        Powered by{" "}
        <a target="_blank" href="https://www.jetz.ai/" rel="noreferrer">
          Jetz.AI
        </a>
      </div>
    </div>
  );
};
export default CopyRightFooter;
