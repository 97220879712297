/* eslint-disable react/jsx-no-bind */
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import CopyRightFooter from "../Footer/footer";
import login from "../../images/login_img.png";
import Logo from "../../images/aa_logo.svg";
import CustomButton from "../../component/Buttons/button";
import { videoURL } from "../../utils/baseUrl";
import "./intro.scss";
import Checkbox from "@mui/material/Checkbox";

const styles = {
  paperContainer: {
    backgroundImage: `url(${login})`,
    width: "100%",
    height: "100vh",
    backgroundSize: "100% 100%",
  },
};

const IntroPage = () => {
  const history = useHistory();
  const handleSubmit = () => {
    history.push("/User/demo");
  };
  const [istermsChecked, setIstermsChecked] = useState(false);
  return (
    <div className="login">
      <div className="container-fluid">
        <div
          className="row"
          style={{ marginRight: "0 !important", marginLeft: "0 !important" }}
        >
          <div
            className="col-sm-5"
            style={{
              paddingRight: "0 !important",
              paddingLeft: "0 !important",
            }}
          >
            <div style={styles.paperContainer} className="left_panel">
              <div className="IntroLogo">
                <img src={Logo} alt="" />
              </div>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="row justify-content-center">
              <div className="intro-head">
                <h6>
                  <span style={{ color: "#EE8E54" }}>AlignedAround</span>{" "}
                  Lightweight Assessment
                </h6>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="list">
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      AlignedAround is an alignment and decision-making
                      analytics platform that enables an organisation to Define,
                      Measure and Improve alignment around its most strategic
                      investments and initiatives.
                    </p>
                  </div>
                </div>

                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      AlignedAround offers a Lightweight, Comprehensive or
                      Facilitated alignment assessment. Your organisation has
                      chosen to conduct a Lightweight assessment.
                    </p>
                  </div>
                </div>

                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      This assessment consists of a short demographic survey and
                      eight statements.
                    </p>
                  </div>
                </div>
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      One alignment statement is presented for each of the seven
                      AlignedAround pillars of Vision, Strategy, Success,
                      Leader/Follower, Accountability, Investment, and Advocacy.
                      There is also one overall initiative sentiment statement.
                    </p>
                  </div>
                </div>

                <div className="intro_content2">
                  <div className="video_joint">
                    <div>
                      <div className="intro_align_content1">
                        <span />
                        <p>
                          You will be asked to rate each of these alignment
                          statements on a scale of Strongly Disagree, Disagree,
                          Somewhat Disagree, Neutral, Somewhat Agree, Agree and,
                          Strongly Agree. It should take about five to ten
                          minutes to complete the assessment.
                        </p>
                      </div>
                      <div className="intro_align_content1">
                        <span />
                        <p>
                          All of your responses and comments are anonymous and
                          only visible to you and the AlignedAround team. You
                          will be given the option of reviewing and discussing
                          your responses with an AlignedAround team member
                          and/or sharing them with your peers.
                        </p>
                      </div>
                    </div>

                    {/* <div className='reactPlayerView'>
                      <ReactPlayer url={videoURL} width={250} height={200} playing controls />
                      <div className='welcomeTitle'>Welcome Video</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="list">
                <Checkbox
                  value={istermsChecked}
                  onChange={(e) => setIstermsChecked(e.target.checked)}
                />
                I agree to the{" "}
                <a
                  target="_blank"
                  href="https://www.alignedaround.com/termsofuse"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href="https://www.alignedaround.com/privacy"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                of AlignedAround
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="btnBegin">
                <CustomButton
                  btnName="BEGIN"
                  custombtnCSS="Primary"
                  onBtnClick={handleSubmit}
                  btnDisable={!istermsChecked}
                />
              </div>
            </div>
            <div className="user-footer">
              <CopyRightFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IntroPage;
