/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Route, NavLink } from "react-router-dom";
import axios from "axios";
import DashboardIcon from "../../images/dashboard.svg";
import Human from "../../images/Human.svg";
import Notepad from "../../images/Notepad.svg";
import Book from "../../images/Book.svg";
import Sideimage from "../../images/Side.svg";
import { notification, Modal } from "antd";
import Logo from "../../images/aa_logo.svg";
import CopyRightFooter from "../../pages/Footer/footer";
import ManagePack from "../../images/managepack.svg";
import CustomButton from "../../component/Buttons/button";
import "./Navbar.scss";
import Labelbox from "../../helpers/labelbox/labelbox";
import { apiurl } from "../../utils/baseUrl";
// import Logout from "../../images/logout.png";
import changePassword from "../../images/changePassword.svg";
// ---------------------------------------------------Pages-------------------------------------------------------------//
import Dashboard from "../../pages/Dashboard/dashboard";
import AllClients from "../../pages/Dashboard/allClients";
import AssesmentLead from "../../pages/Dashboard/assesmentLead";
import QuestionBank from "../../pages/QuestionBank/QuestionBank";
import CreateClient from "../../pages/CreateClient/CreateClient";
import ValidationLibrary from "../../helpers/ValidationFunction/validationfunction";
import ManagePackage from "../../pages/ManagePackage/ManagePackage";
import SingleManageInitiative from "../../pages/ManagePackage/NewManageInitiative";
import SingleManageInitiative2 from "../../pages/ManagePackage/NewManageInitiative2";
import ScoreCardTab from "../../pages/ScoreCardDash/dash";
import CryptoJS from "crypto-js";
// ---------------------------------------------------------------------------------------------------------------------//

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 99,
    height: 90,
    display: "flex",
    alignItems: "flex-end",
    paddingRight: "2%",
  },
  content: {
    flexGrow: 1,
    padding: "30px",
    backgroundColor: "white",
  },
}));

const AdminNavbar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const url = window.location.href.split("/");
  const pathCheck = url[url.length - 1];
  const [ChangePasswordView, setChangePasswordView] = useState(false);
  const [isOneInitiative, setIsOneInitiative] = useState(false);
  const ChangePasswordIntial = {
    currentPassword: {
      value: "",
      validation: [
        { name: "required" },
        {
          name: "customValue",
          params: "123"
            ? "123"
            : CryptoJS.AES.decrypt(
                JSON.parse(localStorage.getItem("token")).password,
                "alignedaround"
              ).toString(CryptoJS.enc.Utf8),
          msg: "Password Not Matched with Current Password",
        },
      ],
      error: null,
      errmsg: null,
    },
    newPassword: {
      value: "",
      validation: [
        { name: "required" },
        {
          name: "customValue",
          params: null,
          msg: "Password not matched",
        },
      ],
      error: null,
      errmsg: null,
    },
    confirmNewPassword: {
      value: "",
      validation: [
        { name: "required" },
        {
          name: "customValue",
          params: null,
          msg: "Password not matched",
        },
      ],
      error: null,
      errmsg: null,
    },
  };
  const [ChangePassword, setChangePassword] = useState(
    JSON.parse(JSON.stringify(ChangePasswordIntial))
  );
  /**
   *
   */
  function handleLogout() {
    localStorage.clear();
    history.push("/");
    window.location.reload();
  }
  /**
   *
   */
  function onDashboardClick() {
    if (
      typeof window !== "undefined" &&
      JSON.parse(localStorage.getItem("token"))?.user_type == 4
    ) {
      history.push({
        pathname: "/Admin/dashboard/assesment-lead",
        state: { pathname: "admin", admin: true },
      });
    } else {
      history.push({
        pathname: "/Admin/dashboard",
        state: { pathname: "admin", admin: true },
      });
    }
    // history.push('/Admin/dashboard');
    // window.location.reload();
  }
  useEffect(() => {
    if (
      pathCheck !== "" &&
      pathCheck !== "Userlogin" &&
      !localStorage.getItem("user_type")
    ) {
      localStorage.clear();
      history.push("/");
      window.location.reload();
    }
  }, [history, pathCheck]);

  useEffect(() => {
    setTimeout(() => {
      if (
        Number(localStorage.getItem("user_type")) === 4 &&
        localStorage.getItem("dashboardStatus") == "1Initiative"
      ) {
        setIsOneInitiative(true);
      }
    }, 1000);
  }, []);

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   */
  function checkValidation(data, key) {
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      ChangePassword[key].validation
    );
    const dynObj = {
      value: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
      validation: ChangePassword[key].validation,
    };
    if (key === "confirmNewPassword") {
      setChangePassword((prevState) => ({
        ...prevState,
        newPassword: {
          ...ChangePassword.newPassword,
          validation: [
            { name: "required" },
            {
              name: "customValue",
              params: data,
              msg: "Password not matched",
            },
          ],
          error:
            ChangePassword.newPassword.value !== "" &&
            ChangePassword.newPassword.value !== data
              ? true
              : false,
          errmsg:
            ChangePassword.newPassword.value !== "" &&
            ChangePassword.newPassword.value !== data
              ? "Password not matched"
              : null,
        },
        [key]: dynObj,
      }));
    } else if (key === "newPassword") {
      setChangePassword((prevState) => ({
        ...prevState,
        confirmNewPassword: {
          ...ChangePassword.confirmNewPassword,
          validation: [
            { name: "required" },
            {
              name: "customValue",
              params: data,
              msg: "Password not matched",
            },
          ],
          error:
            ChangePassword.confirmNewPassword.value !== "" &&
            ChangePassword.confirmNewPassword.value !== data
              ? true
              : false,
          errmsg:
            ChangePassword.confirmNewPassword.value !== "" &&
            ChangePassword.confirmNewPassword.value !== data
              ? "Password not matched"
              : null,
        },
        [key]: dynObj,
      }));
    } else {
      setChangePassword((prevState) => ({
        ...prevState,
        [key]: dynObj,
      }));
    }
  }
  const handleChangePassword = () => {
    try {
      const mainvalue = {};
      const targetkeys = Object.keys(ChangePassword);
      for (const i in targetkeys) {
        var errorcheck = ValidationLibrary.checkValidation(
          ChangePassword[targetkeys[i]].value,
          ChangePassword[targetkeys[i]].validation
        );
        ChangePassword[targetkeys[i]].error = !errorcheck.state;
        ChangePassword[targetkeys[i]].errmsg = errorcheck.msg;
        mainvalue[targetkeys[i]] = ChangePassword[targetkeys[i]].value;
      }
      const filtererr = targetkeys.filter(
        (obj) => ChangePassword[obj].error === true
      );
      if (filtererr.length > 0) {
      } else {
        axios({
          method: "put",
          url: `${apiurl}change_password`,
          data: {
            id: JSON.parse(localStorage.getItem("id")),
            newPassword: CryptoJS.AES.encrypt(
              ChangePassword.newPassword.value,
              "alignedaround"
            ).toString(),
          },
        }).then((response) => {
          if (response.data.status === 1) {
            notification.success({
              message: "Password changed successfully",
            });
            history.push("/");
          } else if (response.data.status === 0) {
            notification.error({
              message:
                "An application error occurred while change the password. Please contact the AlignedAround support team.",
            });
          }
        });
        handleCancelChangePassword();
      }
      setChangePassword((prevState) => ({
        ...prevState,
      }));
    } catch (err) {
      notification.error({
        message:
          "An application error occurred while change the password. Please contact the AlignedAround support team.",
      });
    }
  };

  const handleCancelChangePassword = () => {
    setChangePasswordView(false);
    setChangePassword(JSON.parse(JSON.stringify(ChangePasswordIntial)));
  };
  return (
    <div className={`Admin_nav ${classes.root}`}>
      <div className="container-fluid">
        <div className="row margin-remove">
          <div className="side-menu-width">
            <div className="nav-bar-body adminnav">
              <div className="side_image">
                <img src={Sideimage} alt="" />{" "}
              </div>
              <div>
                <center>
                  <img src={Logo} alt="" />
                </center>
              </div>
              <ul>
                {/* <Link to='/Admin/dashboard'> */}
                {/* </Link> */}
                {Number(localStorage.getItem("user_type")) === 4 ? (
                  ""
                ) : (
                  <div>
                    <li
                      className={
                        pathCheck === "dashboard" ||
                        pathCheck === "ScoreCardDash" ||
                        pathCheck === "assesment-lead"
                          ? "active"
                          : ""
                      }
                      onClick={() => onDashboardClick()}
                    >
                      <span id="dash">Dashboard</span>
                      <img src={DashboardIcon} id="dash_icon" alt="" />
                    </li>{" "}
                    <NavLink to="/Admin/CreateClient">
                      <li
                        className={pathCheck === "CreateClient" ? "active" : ""}
                      >
                        <span id="client">Create Client</span>
                        <img src={Human} alt="" />
                      </li>
                    </NavLink>
                    <NavLink to="/Admin/AlignmentStatements">
                      <li
                        className={
                          pathCheck === "AlignmentStatements" ? "active" : ""
                        }
                      >
                        <span id="ques">Alignment Statements</span>
                        <img src={Book} alt="" />
                      </li>
                    </NavLink>
                    <NavLink to="/Admin/ManageAssessment">
                      <li
                        className={
                          pathCheck === "ManageAssessment" ? "active" : ""
                        }
                      >
                        <span id="pack">Manage Assessment</span>
                        <img src={ManagePack} alt="" />
                      </li>
                    </NavLink>
                    <NavLink to="/Admin/ManageInitiative">
                      <li
                        className={
                          pathCheck === "ManageInitiative" ? "active" : ""
                        }
                      >
                        <span id="pack">Create/Manage Initiative</span>
                        <img src={Notepad} alt="" />
                      </li>
                    </NavLink>
                  </div>
                )}
              </ul>
              <div className="version">Version 2.0.0</div>
            </div>
          </div>
          <div className="main-content-width main_content">
            <main>
              <div className="menu">
                <div className="nav_name">
                  {/* <div><NotificationsIcon className="customIcon_header" /></div> */}
                  <div className="user_name_text">
                    {Number(localStorage.getItem("user_type")) === 0
                      ? "Administrator"
                      : Number(localStorage.getItem("user_type")) === 4
                      ? JSON.parse(localStorage.getItem("token")).name ||
                        JSON.parse(localStorage.getItem("token")).mail_id
                      : localStorage
                          .getItem("user_name")
                          ?.substring(
                            1,
                            localStorage.getItem("user_name").length - 1
                          )}
                  </div>
                  <img
                    src={changePassword}
                    className="logout"
                    alt=""
                    onClick={() => setChangePasswordView(true)}
                  />
                  {/* <CustomButton
                    btnName="Change Password"
                    custombtnCSS="exit"
                    onBtnClick={()=>setChangePasswordView(true)}
                  /> */}
                  <CustomButton
                    btnName="Exit"
                    custombtnCSS="exit"
                    onBtnClick={handleLogout}
                  />
                </div>
              </div>

              <Route
                path={`${props.match.path}/dashboard`}
                component={Dashboard}
                exact
              />
              <Route
                path={`${props.match.path}/all-clients`}
                component={AllClients}
                exact
              />
              <Route
                path={`${props.match.path}/dashboard/assesment-lead`}
                component={AssesmentLead}
                exact
              />
              <Route
                path={`${props.match.path}/AlignmentStatements`}
                component={QuestionBank}
                exact
              />
              <Route
                path={`${props.match.path}/CreateClient`}
                component={CreateClient}
                exact
              />
              <Route
                path={`${props.match.path}/ManageAssessment`}
                component={ManagePackage}
                exact
              />
              <Route
                path={`${props.match.path}/ManageInitiative`}
                component={SingleManageInitiative}
                exact
              />
              <Route
                path={`${props.match.path}/ManageInitiative2`}
                component={SingleManageInitiative2}
                exact
              />
              <Route
                path={`${props.match.path}/ScoreCardDash`}
                component={ScoreCardTab}
                exact
              />
              <div className="myfooter">
                <CopyRightFooter />
              </div>
              <Modal
                title={<div> Change Password</div>}
                centered
                visible={ChangePasswordView}
                width={900}
                zIndex={1201}
                closable
                onCancel={handleCancelChangePassword}
                maskClosable={false}
                footer={[
                  <div className="okbtn">
                    <CustomButton
                      btnName="Submit"
                      custombtnCSS="Success"
                      onBtnClick={handleChangePassword}
                    />
                    <CustomButton
                      btnName="Cancel"
                      custombtnCSS="exit"
                      onBtnClick={handleCancelChangePassword}
                    />
                  </div>,
                ]}
              >
                <div className="package_change">
                  <Labelbox
                    labelname="Enter Current Password"
                    type="text"
                    changeData={(data) =>
                      checkValidation(data, "currentPassword")
                    }
                    value={ChangePassword.currentPassword.value}
                    error={ChangePassword.currentPassword.error}
                    errmsg={ChangePassword.currentPassword.errmsg}
                  />
                  <Labelbox
                    labelname="Enter New Password"
                    type="text"
                    changeData={(data) => checkValidation(data, "newPassword")}
                    value={ChangePassword.newPassword.value}
                    error={ChangePassword.newPassword.error}
                    errmsg={ChangePassword.newPassword.errmsg}
                  />
                  <Labelbox
                    labelname="Re-Enter New Password"
                    type="text"
                    changeData={(data) =>
                      checkValidation(data, "confirmNewPassword")
                    }
                    value={ChangePassword.confirmNewPassword.value}
                    error={ChangePassword.confirmNewPassword.error}
                    errmsg={ChangePassword.confirmNewPassword.errmsg}
                  />
                </div>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminNavbar;
