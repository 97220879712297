/// ///////******Master dropdowns*******//////
export const GET_PILLARS = 'GET_PILLARS';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_DASHBOARD_COUNT = 'GET_DASHBOARD_COUNT';

/// ///*********question bank***********/////
export const GET_QUESTION_BANK = 'GET_QUESTION_BANK';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_CLIENT_DASHBOARD_COUNT = 'GET_CLIENT_DASHBOARD_COUNT';
export const GET_INITIATIVE_DASHBOARD_COUNT = 'GET_INITIATIVE_DASHBOARD_COUNT';
export const GET_CLIENT_DASHBOARD = 'GET_CLIENT_DASHBOARD';
export const GET_CLIENT_DASHBOARD_MAIN = 'GET_CLIENT_DASHBOARD_MAIN';
export const UPDATE_PW_RW_EAS = 'UPDATE_PW_RW_EAS';
//* *************** score card *****************/
export const GET_MATRIX_RESPONDENT = 'GET_MATRIX_RESPONDENT';
export const GET_RESPONDENT_BY_INITIATIVE = 'GET_RESPONDENT_BY_INITIATIVE';
export const GET_MATRIX_INITIATIVE_OVERALL = ' GET_MATRIX_INITIATIVE_OVERALL';

//* ****************User***********************/
export const GET_RANGE = 'GET_RANGE';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_ETHNICITY = 'GET_ETHNICITY'; 
export const GET_NATIONALITY = 'GET_NATIONALITY'; 
export const GET_ANCESTRY = 'GET_ANCESTRY';
export const GET_QUESTION_PILLARWISE = 'GET_QUESTION_PILLARWISE';
export const INSERT_DEMO = 'INSERT_DEMO';
export const INSERT_ANSWERS = 'INSERT_ANSWERS';
export const GET_MANAGE_PACKAGE = 'GET_MANAGE_PACKAGE';
export const GET_MANAGE_INITIATIVE = 'GET_MANAGE_INITIATIVE';
export const GET_VIEW_INITIATIVE = 'GET_VIEW_INITIATIVE';
export const GET_SUMMARY_RES = ' GET_SUMMARY_RES';
export const GET_RESP_DEMO = 'GET_RESP_DEMO';
