/* eslint-disable func-names */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./individuals.scss";
import { GetSummaryByResp } from "../../../actions/UserAction";

const ISummary = (props) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [peer, setPeer] = useState({});
  const [Most, setMost] = useState([]);
  const [Least, setLeast] = useState([]);
  const [isMostAgree, setMostAgree] = useState(false);
  const [isDisAgree, setDisAgree] = useState(false);
  const [sentiment, setSentiment] = useState({});
  const [toolTip, setToolTip] = useState(false);
  const [currentDesc, setDesc] = useState("");
  const Descriptions = {
    alignment: {
      you: "This is your overall alignment score. Less than 5.5 is “Not aligned”.",
      group_avg:
        "This is the average alignment score of the entire group including you. Less than 5.5 is “Not aligned”.",
      your_std_dev:
        "This is the standard deviation (variability) of your alignment scores across the seven pillars.",
      group_min: "This is the minimum overall alignment score in the group.",
      group_max: "This is the maximum overall alignment score in the group.",
      your_coeff_var:
        "This is the coefficient of variation of your scores. A statistical measure of the relative dispersion of your alignment scores across the seven pillars.",
    },
    most_aligned_pillars:
      "These are the three alignment pillars which you scored the highest.",
    least_aligned_pillars:
      "These are the three alignment pillars which you scored the lowest.",
    sentiment: {
      you: " This is your score on the sentiment statement of “This initiative will be successful.”",
      group_avg:
        "This is the average sentiment score of the entire group including you.",
      your_std_dev:
        "This is the standard deviation (variability) of the group’s sentiment scores.",
      group_min: "This is the minimum sentiment score in the group.",
      group_max: "This is the maximum sentiment score in the group.",
      your_coeff_var:
        "This is the coefficient of variation of the group’s sentiment scores. A statistical measure of the relative dispersion of the group’s sentiment scores across the seven pillars.",
    },
  };

  /** ********* DISPATCH */
  useEffect(() => {
    dispatch(GetSummaryByResp({ resp_id: props.resp_id }));
  }, [dispatch, props.resp_id]);

  /** ******* PAYLOAD */
  const Result = useSelector((state) => state.UserReducer);
  console.log("Result : summary " + Result);
  useEffect(() => {
    Result.GetSummaryByResp.map((data, index) => {
      if (index === 0) {
        const nonSortableFields = [
          "Overall Initiative Success Sentiment",
          "OSS",
          "Mean",
          "EAS",
        ];
        const sortedVal = [];

        data.map((item) => {
          if (!nonSortableFields.includes(item.name)) {
            sortedVal.push(item);
          }
          return true;
        });

        const sort = sortedVal.sort(function (a, b) {
          return b.average - a.average;
        });

        const top = sort.slice(0, 3);
        const last = sort.slice(-3);
        setMost(top);
        setLeast(last.sort((a, b) => (a.average > b.average ? 1 : -1)));

        data.sort(function (a, b) {
          return a.average - b.average;
        });
      }
      if (index === 1) {
        data.map((item) => {
          setPeer(item);
          return true;
        });
      }

      if (index === 5) {
        data.map((item) => {
          setSentiment(item);
          return true;
        });
      }

      if (index === 3) {
        const MALength = data[0].most_Agreed.length;
        const MDALength = data[0].most_Dis_Agreed.length;
        const isMAEmpty = data[0].most_Agreed.filter(
          (item) => item.pillar.length === 0
        );
        const isMDAEmpty = data[0].most_Dis_Agreed.filter(
          (item) => item.pillar.length === 0
        );
        if (isMAEmpty.length === MALength) {
          setMostAgree(true);
        } else {
          setMostAgree(false);
        }
        if (isMDAEmpty.length === MDALength) {
          setDisAgree(true);
        } else {
          setDisAgree(false);
        }
      }
      return true;
    });
  }, [dispatch, Result]);

  /**
   * @function minalign used for minalign process
   * @param {string} val input value
   * @returns {string} colour value
   */
  function minalign(val) {
    if (val > 5.5) {
      return "green";
    }
    if (val < 5.5) {
      return "red";
    }
    if (val === 5.5) {
      return "yellow";
    }
    return true;
  }

  const showToolTip = (descValue) => {
    setDesc(descValue);
    setToolTip(true);
  };
  return (
    <div>
      {Result?.GetSummaryByResp.length > 0 &&
      Result?.GetSummaryByResp[4][0].completed === 1 ? (
        <div className="Ichart">
          <div className="row">
            <div className="col-sm-3">
              <div className="coresel c1">
                <div className="wave w1">Alignment</div>
                <div className="respond-grid">
                  <div className="item1">
                    <div
                      className="score"
                      onMouseEnter={() => {
                        showToolTip(Descriptions.alignment.you);
                      }}
                      onMouseLeave={() => setToolTip(false)}
                    >
                      <span style={{ color: "black" }}>You </span>
                      <br />
                      <span
                        className={peer.you && minalign(peer.you.toFixed(2))}
                      >
                        {(peer.you && peer.you.toFixed(2)) || "-"}
                      </span>
                    </div>
                  </div>

                  <div className="item1">
                    <div
                      className="score"
                      onMouseEnter={() => {
                        showToolTip(Descriptions.alignment.group_min);
                      }}
                      onMouseLeave={() => setToolTip(false)}
                    >
                      <span style={{ color: "black" }}>Group Min </span>
                      <br />
                      <span
                        className={peer.min && minalign(peer.min.toFixed(2))}
                      >
                        {(peer.min && peer.min.toFixed(2)) || "-"}
                      </span>
                    </div>
                  </div>
                  <div className="item3">
                    <div
                      className="score"
                      tooltip="Helo"
                      onMouseEnter={() => {
                        showToolTip(Descriptions.alignment.group_max);
                      }}
                      onMouseLeave={() => setToolTip(false)}
                    >
                      <span style={{ color: "black" }}>Group Max </span>
                      <br />
                      <span
                        className={peer.max && minalign(peer.max.toFixed(2))}
                      >
                        {(peer.max && peer.max.toFixed(2)) || "-"}
                      </span>
                    </div>
                  </div>
                  <div className="item3">
                    <div
                      className="score"
                      onMouseEnter={() => {
                        showToolTip(Descriptions.alignment.group_avg);
                      }}
                      onMouseLeave={() => setToolTip(false)}
                    >
                      <span style={{ color: "black" }}>Group Average</span>
                      <br />
                      <span
                        className={
                          peer.peer_avg && minalign(peer.peer_avg.toFixed(2))
                        }
                      >
                        {(peer.peer_avg && peer.peer_avg.toFixed(2)) || "-"}
                      </span>
                    </div>
                  </div>
                  {/* <div className="item3">
                    <div
                      className="score"
                      onMouseEnter={() => {
                        showToolTip(Descriptions.alignment.your_std_dev);
                      }}
                      onMouseLeave={() => setToolTip(false)}
                    >
                      <span style={{ color: "black" }}>
                        Your Std. Dev.
                      </span>
                      <br />
                      <span
                        className={
                          peer.std_dev && minalign(peer.std_dev.toFixed(2))
                        }
                      >
                        {(peer.std_dev && peer.std_dev.toFixed(2)) || "-"}
                      </span>
                    </div>
                  </div> */}
                  {/* <div className="item4">
                    <div
                      className="score"
                      onMouseEnter={() => {
                        showToolTip(Descriptions.alignment.your_coeff_var);
                      }}
                      onMouseLeave={() => setToolTip(false)}
                    >
                      <span style={{ color: "black" }}>                        
                        Your Coeff. of Var.
                      </span>
                      <br />
                      <span className={peer.cv && minalign(peer.cv.toFixed(2))}>
                        {(peer.cv && `${(peer.cv * 100).toFixed()}%`) || "-"}
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div
                className="coresel c3"
                onMouseEnter={() => {
                  showToolTip(Descriptions.most_aligned_pillars);
                }}
                onMouseLeave={() => setToolTip(false)}
              >
                <div className="wave w3">
                  Your 3 Most <br />
                  Aligned Pillars
                </div>
                <div className="metrics">
                  <div>
                    {Most.map((data, index) => {
                      return (
                        <div className="metrics-grid">
                          <div className="pname">{data.name}</div>
                          <div className="pvalue">
                            {(data.average && data.average.toFixed(2)) || "-"}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div
                className="coresel c4"
                onMouseEnter={() => {
                  showToolTip(Descriptions.least_aligned_pillars);
                }}
                onMouseLeave={() => setToolTip(false)}
              >
                <div className="wave w4">
                  Your 3 Least <br />
                  Aligned Pillars
                </div>
                <div className="metrics">
                  <div>
                    {Least.map((data) => {
                      return (
                        <div className="metrics-grid">
                          <div className="pname">
                            {Most.find((o) => o.average === data.average)
                              ? "-"
                              : data.name}
                          </div>
                          <div className="pvalue">
                            {(Most.find((o) => o.average === data.average)
                              ? "-"
                              : data.average && data.average.toFixed(2)) || "-"}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="coresel c7">
                <div className="wave w7">
                  Sentiment <br />
                  (This initiative will be successful)
                </div>
                <div>
                  {/* <div className="header">You vs Group Average</div> */}
                  <div className="respond-grid">
                    <div className="item1">
                      <div
                        className="score"
                        onMouseEnter={() => {
                          showToolTip(Descriptions.sentiment.you);
                        }}
                        onMouseLeave={() => setToolTip(false)}
                      >
                        <span style={{ color: "black" }}>You</span>
                        <br />
                        <span
                          className={
                            sentiment.you && minalign(sentiment.you.toFixed(2))
                          }
                        >
                          {(sentiment.you && sentiment.you.toFixed(2)) || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="item1">
                      <div
                        className="score"
                        onMouseEnter={() => {
                          showToolTip(Descriptions.sentiment.group_min);
                        }}
                        onMouseLeave={() => setToolTip(false)}
                      >
                        <span style={{ color: "black" }}>Group Min</span>
                        <br />
                        <span
                          className={
                            sentiment.min && minalign(sentiment.min.toFixed(2))
                          }
                        >
                          {(sentiment.min && sentiment.min.toFixed(2)) || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="item3">
                      <div
                        className="score"
                        onMouseEnter={() => {
                          showToolTip(Descriptions.sentiment.group_max);
                        }}
                        onMouseLeave={() => setToolTip(false)}
                      >
                        <span style={{ color: "black" }}>Group Max</span>
                        <br />
                        <span
                          className={
                            sentiment.max && minalign(sentiment.max.toFixed(2))
                          }
                        >
                          {(sentiment.max && sentiment.max.toFixed(2)) || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="item3">
                      <div
                        className="score"
                        onMouseEnter={() => {
                          showToolTip(Descriptions.sentiment.group_avg);
                        }}
                        onMouseLeave={() => setToolTip(false)}
                      >
                        <span style={{ color: "black" }}>Group Average</span>
                        <br />
                        <span
                          className={
                            sentiment.peer_avg &&
                            minalign(sentiment.peer_avg.toFixed(2))
                          }
                        >
                          {(sentiment.peer_avg &&
                            sentiment.peer_avg.toFixed(2)) ||
                            "-"}
                        </span>
                      </div>
                    </div>
                    {/* <div className="item3">
                      <div
                        className="score"
                        onMouseEnter={() => {
                          showToolTip(Descriptions.sentiment.your_std_dev);
                        }}
                        onMouseLeave={() => setToolTip(false)}
                      >
                        <span style={{ color: "black" }}>
                          {Number(localStorage.getItem("user_type")) === 1 &&
                            ""}
                          Group Std. Dev.
                        </span>
                        <br />
                        <span
                          className={
                            sentiment.std_dev &&
                            minalign(sentiment.std_dev.toFixed(2))
                          }
                        >
                          {Result?.GetSummaryByResp.length > 0 &&
                          Result?.GetSummaryByResp[4][0].completed === 1
                            ? sentiment.std_dev && sentiment.std_dev.toFixed(2)
                            : "-"}
                        </span>
                      </div>
                    </div> */}
                    {/* <div className="item4">
                      <div
                        className="score"
                        onMouseEnter={() => {
                          showToolTip(Descriptions.sentiment.your_coeff_var);
                        }}
                        onMouseLeave={() => setToolTip(false)}
                      >
                        <span style={{ color: "black" }}>
                          {Number(localStorage.getItem("user_type")) === 1 &&
                            ""}
                          Your Coeff. of Var.
                        </span>
                        <br />
                        <span
                          className={
                            sentiment.cv && minalign(sentiment.cv.toFixed(2))
                          }
                        >
                          {Result?.GetSummaryByResp.length > 0 &&
                          Result?.GetSummaryByResp[4][0].completed === 1
                            ? sentiment.cv &&
                              `${(sentiment.cv * 100).toFixed()}%`
                            : "-"}
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div>
              .{toolTip && <span className="desc-span">{currentDesc}</span>}{" "}
            </div>
            <br></br>
          </div>
          <div className="row">
            {isMostAgree === false && (
              <div className="col-sm-12">
                <div className="full-coresel c6">
                  <div className="wave w6">Statements You Ranked Highest</div>
                  {console.log(Result?.GetSummaryByResp)}
                  {Result?.GetSummaryByResp.length > 0 &&
                    Result?.GetSummaryByResp[3][0].most_Agreed.map((data) => {
                      return (
                        <>
                          {data.pillar?.length > 0 && (
                            <div className="summ_grid_max">
                              <div className="summary-grid">
                                {data.pillar.map((pData, index) => {
                                  return (
                                    <>
                                      {index === 0 ? (
                                        <div className="qno">
                                          {data.pillar[0]?.pillar_name || ""}
                                        </div>
                                      ) : (
                                        <div className="qno"> </div>
                                      )}
                                      <div className="questions">
                                        {pData.question || "-"}
                                      </div>
                                      <div className="ques_rate">
                                        {pData.qus_ans_id || "-"}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            )}
            {isDisAgree === false && (
              <div className="col-sm-12">
                <div className="full-coresel c8">
                  <div className="wave w8">Statements You Ranked Lowest</div>
                  {Result?.GetSummaryByResp.length > 0 &&
                    Result?.GetSummaryByResp[3][0].most_Dis_Agreed.map(
                      (data) => {
                        return (
                          <div>
                            {data.pillar?.length > 0 && (
                              <div className="summ_grid_max">
                                <div className="summary-grid">
                                  {data.pillar.map((pData, index) => {
                                    return (
                                      <>
                                        {index === 0 ? (
                                          <div className="qno">
                                            {data.pillar[0]?.pillar_name || ""}
                                          </div>
                                        ) : (
                                          <div className="qno"> </div>
                                        )}
                                        <div className="questions">
                                          {pData.question || "-"}
                                        </div>
                                        <div className="ques_rate">
                                          {pData.qus_ans_id || "-"}
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="waitmsg">
          This initiative is in progress. Group alignment and comparison data
          will be available once all respondents have completed the assessment
          or by the end date.
        </div>
      )}
    </div>
  );
};
export default ISummary;
