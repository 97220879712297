/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Labelbox from "../../helpers/labelbox/labelbox";
import CustomButton from "../../component/Buttons/button";
import ValidationLibrary from "../../helpers/ValidationFunction/validationfunction";
import {
  GetRange,
  GetCountry,
  PostDemo,
  GetRespDemo,
  getNationality,
} from "../../actions/UserAction";
import "./demo.scss";
import CountryList from "./countryList.json";

const Demo = () => {
  console.log("localStorage..." + localStorage);
  const status = JSON.parse(localStorage.getItem("status_respondent"));
  const history = useHistory();
  const dispatch = useDispatch();
  const [ageRange, setAgeRange] = useState([]);
  const [country, setCountry] = useState([]);
  const [tempCountry, setTempCountry] = useState({});
  const [DemoData, setDemoData] = useState({
    emailid: {
      value:
        JSON.parse(localStorage.getItem("token")).mail_id ||
        JSON.parse(localStorage.getItem("user_name")),
      validation: [{ name: "required" }, { name: "email" }],
      error: null,
      errmsg: null,
    },
    age: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    year_with_company: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    year_in_current_role: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    gender: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    country_in_live: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    country_born: {
      value: "",
      error: null,
      errmsg: null,
    },
    nationality: {
      value: "",
      error: null,
      errmsg: null,
    },
  });
  const AgeRange = [
    { id: 7, value: "19 and under" },
    { id: 1, value: "20 - 29" },
    { id: 2, value: "30 - 39" },
    { id: 3, value: "40 - 49" },
    { id: 4, value: "50 - 59" },
    { id: 5, value: "60 - 69" },
    { id: 6, value: "70 - 79" },
    { id: 8, value: "80+" },
  ];
  const Nationality = [
    { id: 2, value: "USA" },
    { id: 2, value: "Australia" },
  ];
  /** ****** API DISPATCH */
  useEffect(() => {
    dispatch(GetRange());
    dispatch(GetCountry());
    dispatch(getNationality());
    if (status === 2) {
      dispatch(GetRespDemo());
    }
  }, [dispatch]);

  /** ******* PAYLOAD */
  const UserAction = useSelector((state) => state.UserReducer);

  /** ********* RESPONSE */
  useEffect(() => {
    const age = [];
    const countryData = [
      { id: "USA", value: "USA" },
      { id: "Australia", value: "Australia" },
    ];
    UserAction.GetRange &&
      UserAction.GetRange.map((data) => {
        age.push({
          id: data.range_id,
          value: data.ranges,
        });
        return true;
      });
    UserAction.GetCountry &&
      // UserAction?.GetCountry.sort((a, b) =>
      //   a.value.localeCompare(b.value)
      UserAction?.GetCountry.map((data) => {
        if (data.value !== "USA" && data.value !== "Australia") {
          countryData.push({
            id: data.value,
            value: data.value,
          });
        }
        return true;
      });
    setAgeRange(age);
    setCountry(countryData);
    // if (UserAction?.getNationality) {
    //   setDemoData((prevState) => ({
    //     ...prevState,
    //     nationality: {
    //       value: UserAction?.getNationality[0]?.value || "",
    //       error: DemoData.nationality.error,
    //       errmsg: DemoData.nationality.errmsg,
    //       validation: DemoData.nationality.validation,
    //     },
    //   }));
    // }
  }, [UserAction]);

  useEffect(() => {
    console.log("status....." + status);
    console.log("GetRespDemo....." + GetRespDemo);
    if (status === 2) {
      DemoData.age.value = Number(UserAction?.GetRespDemo[0]?.age);
      DemoData.year_with_company.value =
        UserAction?.GetRespDemo[0]?.year_with_company;
      DemoData.year_in_current_role.value =
        UserAction?.GetRespDemo[0]?.year_in_current_role;
      DemoData.country_in_live.value =
        UserAction?.GetRespDemo[0]?.country_in_live;
      DemoData.gender.value = UserAction?.GetRespDemo[0]?.gender;
      DemoData.nationality.value = Number(
        UserAction?.GetRespDemo[0]?.nationality
      );
      DemoData.country_born.value = UserAction?.GetRespDemo[0]?.country_born;
      setDemoData((prevState) => ({
        ...prevState,
      }));
    }
  }, [UserAction.GetRespDemo]);

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   */
  function checkValidation(data, key) {
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      DemoData[key].validation
    );

    const dynObj = {
      value: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
      validation: DemoData[key].validation,
    };
    // if (key === "country_in_live") {
    //   dispatch(getNationality(data));
    // }
    setDemoData((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }

  /**
   *
   */
  async function onSubmit() {
    const targetkeys = Object.keys(DemoData);
    for (const i in targetkeys) {
      const errorcheck = ValidationLibrary.checkValidation(
        DemoData[targetkeys[i]].value,
        DemoData[targetkeys[i]].validation
      );
      DemoData[targetkeys[i]].error = !errorcheck.state;
      DemoData[targetkeys[i]].errmsg = errorcheck.msg;
    }
    const filtererr = targetkeys.filter((obj) => DemoData[obj].error === true);

    if (filtererr.length > 0) {
    } else {
      const ReturnResponse = await dispatch(PostDemo(DemoData, history));
      ReturnResponse && history.push("/User/pillar?id=1");
    }
    setDemoData((prevState) => ({
      ...prevState,
    }));
  }
  //console.log(DemoData.nationality.value, "DemoData.nationality.value");
  return (
    <div className="Content_main_width">
      <div className="row">
        <div className="demo-head">Demographics</div>
      </div>
      <div>
        <div className="demo-sub" style={{ fontSize: "18px" }}>
          Group demographics and diversity is an important factor in alignment
          and decision making. AlignedAround gathers minimal demographic and
          diversity data to reflect this.{" "}
          <span style={{ fontStyle: "italic" }}>Required</span> data is marked
          with an{" "}
          <span style={{ color: "red", fontWeight: "600", fontSize: "18px" }}>
            *
          </span>
        </div>
      </div>
      <div>
        <div className="row demo_inputs">
          <div className="col-sm-4">
            <div className="ques_bank_inputs">
              <div className="heading">Email Address</div>
              <Labelbox
                type="mailbox"
                changeData={(data) => checkValidation(data, "emailid")}
                value={DemoData.emailid.value}
                error={DemoData.emailid.error}
                errmsg={DemoData.emailid.errmsg}
                disabled
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="ques_bank_inputs">
              <div className="heading">
                Age Range{" "}
                <span
                  style={{ color: "red", fontWeight: "600", fontSize: "18px" }}
                >
                  *
                </span>
              </div>
              <Labelbox
                type="select"
                dropdown={AgeRange}
                changeData={(data) => checkValidation(data, "age")}
                value={DemoData.age.value}
                error={DemoData.age.error}
                errmsg={DemoData.age.errmsg}
                disabled={status !== 0}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="ques_bank_inputs">
              <div className="heading">
                Years with organisation{" "}
                <span
                  style={{ color: "red", fontWeight: "600", fontSize: "18px" }}
                >
                  *
                </span>
              </div>
              <Labelbox
                type="select"
                dropdown={ageRange}
                changeData={(data) =>
                  checkValidation(data, "year_with_company")
                }
                value={DemoData.year_with_company.value}
                error={DemoData.year_with_company.error}
                errmsg={DemoData.year_with_company.errmsg}
                disabled={status !== 0}
              />
            </div>
          </div>
        </div>
        <div className="row demo_inputs">
          <div className="col-sm-4">
            <div className="ques_bank_inputs">
              <div className="heading">
                Years in Current Role{" "}
                <span
                  style={{ color: "red", fontWeight: "600", fontSize: "18px" }}
                >
                  *
                </span>
              </div>
              <Labelbox
                type="select"
                dropdown={ageRange}
                changeData={(data) =>
                  checkValidation(data, "year_in_current_role")
                }
                value={DemoData.year_in_current_role.value}
                error={DemoData.year_in_current_role.error}
                errmsg={DemoData.year_in_current_role.errmsg}
                disabled={status !== 0}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="ques_bank_inputs">
              <div className="heading">
                Gender{" "}
                <span
                  style={{ color: "red", fontWeight: "600", fontSize: "18px" }}
                >
                  *
                </span>
              </div>
              <Labelbox
                type="select"
                dropdown={[
                  { id: "Male", value: "Male" },
                  { id: "Female", value: "Female" },
                  { id: "Nonbinary", value: "Nonbinary" },
                  { id: "PreferNotToSay", value: "Prefer not to say" },
                ]}
                changeData={(data) => checkValidation(data, "gender")}
                value={DemoData.gender.value}
                error={DemoData.gender.error}
                errmsg={DemoData.gender.errmsg}
                disabled={status !== 0}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="ques_bank_inputs">
              <div className="heading">
                Country in which You Live{" "}
                <span
                  style={{ color: "red", fontWeight: "600", fontSize: "18px" }}
                >
                  *
                </span>
              </div>
              <Labelbox
                type="select"
                dropdown={CountryList}
                changeData={(data) => {
                  checkValidation(1, "country_in_live");
                  setTempCountry({ ...tempCountry, country_in_live: data });
                }}
                value={tempCountry.country_in_live}
                // dropdown={country}
                // changeData={(data) => checkValidation(data, "country_in_live")}
                error={DemoData.country_in_live.error}
                errmsg={DemoData.country_in_live.errmsg}
                disabled={status !== 0}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="row demo_inputs"
        style={{ borderBottom: "2px dashed #E2E2E2" }}
      > */}
      {/* <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Nationality</div>
            <Labelbox
              type="select"
              dropdown={CountryList}
              changeData={(data) => {
                checkValidation(1, "nationality");
                setTempCountry({ ...tempCountry, nationality: data });
              }}
              value={tempCountry.nationality}
              error={DemoData.nationality.error}
              errmsg={DemoData.nationality.errmsg}
              disabled={status !== 0}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Country of Birth</div>
            <Labelbox
              type="select"
              // dropdown={country}
              dropdown={CountryList}
              changeData={(data) => {
                checkValidation(1, "country_born");
                setTempCountry({ ...tempCountry, country_born: data });
              }}
              // changeData={(data) => checkValidation(data, "country_born")}
              value={tempCountry.country_born}
              error={DemoData.country_born.error}
              errmsg={DemoData.country_born.errmsg}
              disabled={status !== 0}
            />
          </div>
        </div> */}
      {/* </div> */}

      {status === 0 && (
        <div className="btnSubmit">
          <div className="btn_position">
            <CustomButton
              btnName="SUBMIT"
              custombtnCSS="Primary"
              onBtnClick={() => onSubmit()}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Demo;
