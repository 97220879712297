/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-inner-declarations */
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import Login from "./pages/Login/login";
import AdminNavbar from "./component/AdminNavbar/AdminNavbar";
import UserNavbar from "./component/UserNavbar/UserNavbar";
import IntroPage from "./pages/UserIntroPage/IntroPage";
import LightIntroPage from "./pages/UserIntroPage/Light_Intro";
import LightIntroPage1 from "./pages/UserIntroPage/Light_Intro1";
import assesementleadIntro from "./pages/UserIntroPage/assesementleadIntro";
import "./App.css";
import { AuthContext } from "./context/auth";

const App = () => {
  const history = useHistory();
  const url = window.location.href.split("/");
  const pathCheck = url[url.length - 1];
  const existingTokens = JSON.parse(localStorage.getItem("token"));
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [loginUserId, setLoginUserId] = useState();

  const setTokens = (data) => {
    setLoginUserId(
      data.data.user_type === 1 ? data.data.respondent_id : data.data.id
    );
    localStorage.setItem("token", JSON.stringify(data.data));
    localStorage.setItem(
      "user_id",
      JSON.stringify(
        data.data.user_type === 1 ? data.data.respondent_id : data.data.id
      )
    );
    localStorage.setItem("accessToken", JSON.stringify(data.data.accessToken));
    localStorage.setItem("id", JSON.stringify(data.data.id));
    localStorage.setItem("client_id", JSON.stringify(data.data.client_id));
    localStorage.setItem(
      "user_name",
      JSON.stringify(data.data.name ? data.data.name : data.data.mail_id)
    );
    localStorage.setItem("user_type", JSON.stringify(data.data.user_type));
    localStorage.setItem(
      "initiative_id",
      JSON.stringify(data.data.initiative_id)
    );
    localStorage.setItem(
      "status_respondent",
      JSON.stringify(data.data.status_respondent)
    );
    localStorage.setItem("pillar_id", JSON.stringify(data.data.pillar_id) || 1);
    localStorage.setItem(
      "package_name",
      JSON.stringify(data.data.package_name)
    );
    localStorage.setItem("res_name", JSON.stringify(data.data.respondent_name));
    setAuthTokens(data);
  };

  const logout = () => {
    localStorage.clear();
    history.push("/Alignedaround/Userlogin");
    window.location.reload();
  };

  // if (
  //   localStorage.getItem('user_type') &&
  //   localStorage.getItem('user_type') !== '' &&
  //   Number(localStorage.getItem('user_type')) === 1
  // ) {
  //   document.addEventListener('visibilitychange', function () {
  //     let t;
  //     if (document.hidden) {
  //       // start inactivity timeout
  //       /**
  //        *
  //        */
  //       t = setTimeout(logout);
  //     } else {
  //       clearTimeout(t);
  //     }
  //   });
  // }
  return (
    // document.title = "AlignedAround",
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router basename="Alignedaround/">
        <Switch>
          <Route path="/" component={Login} exact />
          <Route path="/Userlogin" component={Login} exact />
          <Route path="/alLogin" component={Login} exact />
          <Route path="/Admin" component={AdminNavbar} />
          <Route path="/User" component={UserNavbar} />
          <Route path="/Intro" component={IntroPage} />
          <Route path="/Light_Intro" component={LightIntroPage} />
          <Route path="/Light_Intro1" component={LightIntroPage1} />
          <Route path="/assesementleadIntro" component={assesementleadIntro} />
        </Switch>
      </Router>
    </AuthContext.Provider>
    // <ViewInitiativeModal />
  );
};

export default App;
