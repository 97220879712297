/* eslint-disable object-shorthand */
/* eslint-disable radix */
/* eslint-disable no-empty */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Labelbox from "../../helpers/labelbox/labelbox";
import CustomButton from "../../component/Buttons/button";
import ValidationLibrary from "../../helpers/ValidationFunction/validationfunction";
import { GetQuestions, InsertAnswers } from "../../actions/UserAction";
import "./pillars.scss";

const PillarsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const url = window.location.href.split("/");
  const pathname = url[url.length - 1];
  const activekey = pathname.charAt(pathname.length - 1);
  const [activeStep, setActiveStep] = useState(activekey);
  const [questions, setQuestions] = useState([]);
  const pillars = [
    "Vision",
    "Strategy",
    "Success",
    "Leader/Follower",
    "Accountability",
    "Investment",
    "Advocacy",
    "Overall Sentiment",
  ];
  const desc = [
    "This pillar of the alignment assessment addresses the initiative vision. Typically, this is a documented statement(s) that briefly describes the initiative’s purpose or desired outcome(s) and invokes a vivid picture of the resulting future state. The vision can also be referred to as the “what”.",
    "This pillar of the alignment assessment addresses the initiative strategy. Typically, this is a direction, set of actions or plan to achieve a desired future-state. The strategy can also be referred to as the “how”.",
    'This pillar of the of the alignment assessment addresses the initiative definition of success or objectives. Typically, this is a number of objectives, metrics or criteria that answer the questions "what does success look like?", "what will be different?" and "how do we know when we are done?"',
    "This pillar of the of the alignment assessment addresses the Leader/Follower capability of the initiative. This includes the leadership team’s confidence in the leader and their individual willingness to lead or follow as required.",
    "This pillar of the alignment assessment addresses the initiative leadership team’s perceived level of accountability. Typically, this is addressed by their willingness to be accountable and/or responsible for the initiative’s success or failure.",
    "This pillar of the alignment assessment addresses both the capability and willingness of the initiative leadership team to invest organisational and/or individual functional area funds, time and resources to make the initiative successful.",
    "This pillar of the alignment assessment addresses the initiative leadership team’s depth of understanding and willingness to consistently advocate for the vision, strategy and outcomes/objectives/benefits of the initiative.",
    "This statement measures your overall sentiment as to the future success of the initiative based on your experience with this initiative and others.",
  ];
  const answer = [
    { label: "", value: "1" },
    { label: "", value: "2" },
    { label: "", value: "3" },
    { label: "", value: "4" },
    { label: "", value: "5" },
    { label: "", value: "6" },
    { label: "", value: "7" },
  ];
  const [Pillars, setPillars] = useState({});

  useEffect(() => {
    dispatch(GetQuestions({ pillar_id: activeStep }));
  }, [activeStep, dispatch]);

  /** ******* PAYLOAD */
  const UserAction = useSelector((state) => state.UserReducer);

  /** ******* RESPONSE */
  useEffect(() => {
    const elements = {
      comments: {
        value: "",
        validation: [],
        error: null,
        errmsg: null,
      },
    };
    setQuestions(UserAction.GetQuestion);
    for (let i = 0; i < UserAction.GetQuestion.length; i++) {
      const name = `ques${i + 1}`.toString();
      const dynObj = {
        value: "",
        error: null,
        errmsg: null,
        validation: [{ name: "required" }],
        statement_id: "",
      };

      elements[name] = dynObj;
    }
    setPillars(elements);
  }, [UserAction.GetQuestion]);

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   * @param {string} item input field name
   */
  function checkValidation(data, key, item) {
    let dynObj = {};
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      Pillars[key].validation
    );
    if (key === "comments") {
      dynObj = {
        value: data,
        error: !errorcheck.state,
        errmsg: errorcheck.msg,
        validation: Pillars[key].validation,
      };
    } else {
      dynObj = {
        value: data,
        error: !errorcheck.state,
        errmsg: errorcheck.msg,
        validation: Pillars[key].validation,
        statement_id: item.qus_bank_id,
      };
    }

    setPillars((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }
  const handleCancel = () => {
    const key = Object.keys(Pillars);
    key.map((data) => {
      Pillars[data].value = "";
      return true;
    });
  };

  /**
   *
   */
  function onNext() {
    if (activeStep <= 7) {
      const key = parseInt(activeStep) + 1;
      setActiveStep(key);
      history.push(`/User/pillar?id=${key}`);
    } else {
      const params = {
        type: "user",
        id: JSON.parse(localStorage.getItem("user_id")),
      };
      history.push({
        pathname: "/User/feedback",
        state: {
          pathname: "user",
          params: params,
        },
      });
    }
  }
  /**
   * @function onSubmit used for validation purpose of input fields
   */
  async function onSubmit() {
    const targetkeys = Object.keys(Pillars);
    for (const i in targetkeys) {
      const errorcheck = ValidationLibrary.checkValidation(
        Pillars[targetkeys[i]].value,
        Pillars[targetkeys[i]].validation
      );
      Pillars[targetkeys[i]].error = !errorcheck.state;
      Pillars[targetkeys[i]].errmsg = errorcheck.msg;
    }
    const filtererr = targetkeys.filter((obj) => Pillars[obj].error === true);

    if (filtererr.length > 0) {
    } else {
      const details = [];
      targetkeys.map((val, index) => {
        if (val !== "comments") {
          details.push({
            qus_no: index,
            initiative_statement_id: Pillars[val].statement_id,
            qus_ans_id: Pillars[val].value,
          });
        }
        return true;
      });
      const ReturnResponse = await dispatch(
        InsertAnswers(
          {
            pillar_id: activeStep,
            qus_ans: details,
            comments: Pillars.comments.value,
          },
          history
        )
      );
      if (ReturnResponse) {
        handleCancel();
        onNext();
      }
    }

    setPillars((prevState) => ({
      ...prevState,
    }));
  }

  return (
    <div className="Content_main_width">
      <div className="row">
        <div className="col-sm-4">
          <div className="pillar_name">{pillars[activeStep - 1]}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="description" style={{ fontSize: "18px" }}>
            {desc[activeStep - 1]}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="grid-pillar">
            {/* <div className="headrow">
                             <div></div> */}
            <div className="grid-header">
              <div>Strongly Disagree</div>
              <div>Disagree</div>
              <div>Somewhat Disagree</div>
              <div>Neutral</div>
              <div>Somewhat Agree</div>
              <div>Agree</div>
              <div>Strongly Agree</div>
            </div>
            {/* </div> */}
            <div className="contentrow">
              {questions &&
                questions.map((data, index) => {
                  return (
                    <div
                      className={`answer ${index % 2 === 0 ? "even" : "odd"}`}
                    >
                      <div className="lblans">{index + 1}</div>
                      <div className="question">{data.question}</div>
                      <div className="radio_div">
                        <Labelbox
                          type="radio"
                          options={answer}
                          changeData={(item) =>
                            checkValidation(item, `ques${index + 1}`, data)
                          }
                          value={Pillars[`ques${index + 1}`].value}
                          error={Pillars[`ques${index + 1}`].error}
                          errmsg={Pillars[`ques${index + 1}`].errmsg}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {activeStep <= 8 ? (
        <div className="row">
          <div className="col-sm-10">
            <Labelbox
              type="textarea"
              placeholder="Add Comments..."
              changeData={(data) => checkValidation(data, "comments")}
              value={Pillars?.comments?.value}
              error={Pillars?.comments?.error}
              errmsg={Pillars?.comments?.errmsg}
            />
          </div>
          <div className="col-sm-2 d-flex justify-content-end">
            <div className="next-pillar">
              <CustomButton
                btnName="NEXT"
                custombtnCSS="Success"
                onBtnClick={() => onSubmit()}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row d-flex justify-content-center">
          <div className="col-sm-2">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "15px",
              }}
            >
              <CustomButton
                btnName="SUBMIT"
                custombtnCSS="Primary"
                onBtnClick={() => onSubmit()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PillarsPage;
