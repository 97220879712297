/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { useLocation, useHistory } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import ScoreCard from "./scorecard";
import ExportIcon from "../../images/export.svg";
import Metrics from "./metrics";
import Alignment from "./alignment";
import Weighted from "./weighted";
import IResult from "./Individuals/result";
import ISummary from "./Individuals/summary";
import IChart from "./Individuals/chart";
import Labelbox from "../../helpers/labelbox/labelbox";
import "./dash.scss";
import {
  GetRespondentByInitiative,
  GetMatrixInitiativeOverall,
} from "../../actions/ScoreCardAction";
import { apiurl } from "../../utils/baseUrl";
import axios from "axios";

const ScoreCardTab = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const componentRef = useRef();
  const state = location.state || "";
  const [value, setValue] = useState("1");
  const [RespData, setRespData] = useState([{}]);
  const [RespondentList, setRespondentsList] = useState([]);
  const [Individuals, setIndividuals] = useState(0);
  const [RespSelect, setRespSelect] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      nav: "Dashboard",
      active: false,
      path: "/Admin/dashboard",
      state: { admin: true },
    },
  ]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    // getInitiativeCompleted/:clientId
    const clientId = localStorage.getItem("client_id");
    axios({
      method: "get",
      url: `${apiurl}getInitiativeCompleted/${clientId.replace(/['"]+/g, "")}`,
    }).then((response) => {
      console.log("res", response);
      if (response.data.msg === "Initiative is completed") {
        setValue("3");
      }
    });
  }, []);

  useEffect(() => {
    if (state.pathname === "admin" && state.params.type === "initiative") {
      dispatch(GetMatrixInitiativeOverall(state?.params?.id));
      setIndividuals(0);
      dispatch(GetRespondentByInitiative(state?.params?.id));
      setBreadcrumbs([
        ...breadcrumbs,
        {
          name: "initiative",
          nav: state.params.data.initiative_name,
          active: false,
          path: "/Admin/dashboard",
          value: state.params.data.initiative_id,
          state: { initiative: state.params.data },
        },
        {
          name: "scorecard",
          nav: "Score Card",
          active: true,
          path: "/Admin/ScoreCardDash",
          state,
        },
      ]);
    } else if (state.pathname === "admin" && state.params.type === "resp") {
      setIndividuals(1);
      setBreadcrumbs([
        ...breadcrumbs,
        {
          name: "initiative",
          nav: state.params.data.initiative_name,
          active: false,
          path: "/Admin/dashboard",
          value: state.params.data.initiative_id,
          state: { initiative: state.params.data },
        },
        {
          name: "resp",
          nav: state.params.data.resp_mail,
          active: false,
          path: "/Admin/dashboard",
          value: state.params.data.respondent_id,
          state: { initiative: state.params.data },
        },
        {
          name: "scorecard",
          nav: "Score Card",
          active: true,
          path: "/Admin/ScoreCardDash",
          state,
        },
      ]);
    }
  }, [state]);

  const GetRespondents = useSelector(
    (state) => state.ScoreCardReducer.GetRespondentByInitiative
  );

  const overAllResult = useSelector(
    (state) => state.ScoreCardReducer?.GetMatrixInitiativeOverall
  );
  // console.log(
  //   overAllResult[0][0].pillar_data[0]?.r1_ans,
  //   "GetMatrixInitiativeOverall"
  // );
  useEffect(() => {
    const getClientsList = [{ id: "0", value: "All" }];
    GetRespondents.map((data) => {
      getClientsList.push({
        id: data.respondent_id,
        value: data?.respondent_name,
      });
      return true;
    });
    setRespondentsList(getClientsList);
  }, [GetRespondents]);

  const onIndividual = (data) => {
    if (data !== "0") {
      setRespSelect(true);
      setIndividuals(1);
    } else {
      dispatch(GetMatrixInitiativeOverall(state?.params?.id));
      setRespSelect(false);
      setIndividuals(0);
    }
    const resData = RespondentList.find((val) => {
      return data === val.id;
    });
    setRespData([{ id: data, name: resData.value }]);
  };

  /**
   * Function to click breadcrumbs
   *
   * @function handleClick
   * @param {number} index input index value
   * @param {string} path input path value
   * @param {string} state input state value
   */
  function handleClick(index, path, state) {
    breadcrumbs.map((item, i) => {
      if (i === index) {
        item.active = true;
      } else {
        item.active = false;
      }
      return true;
    });
    setBreadcrumbs((preState) => [...preState]);

    history.push({
      pathname: path,
      state,
    });
  }
  console.log(overAllResult, "overAllResult");
  return (
    <div
      className={`Content_main_width ${
        (state.pathname === "admin" && "admintab") ||
        (state.pathname === "user" && "usertab")
      }`}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label={`${state.pathname === "user" ? "Your" : ""} Responses `}
                value="1"
              />
              <Tab
                label={`${
                  state.pathname === "user" ? "Group" : ""
                } Overall Alignment `}
                value="3"
              />
              <Tab
                label={`${state.pathname === "user" ? "Group" : ""} Summary `}
                value="2"
              />
              {state.pathname === "admin" &&
              state.params.type === "initiative" ? (
                <Tab label=" Weighted Overall Alignment" value="4" />
              ) : (
                <></>
              )}
              <div
                className={
                  state.pathname === "admin" ? "scorebreadcrumbs" : "none"
                }
              >
                <Stack spacing={2}>
                  <Breadcrumbs separator="|" aria-label="breadcrumb">
                    {breadcrumbs.map((items, index) => {
                      return (
                        <div
                          key={index}
                          className={`items-crumbs ${
                            items.active ? "active" : "non-active"
                          }`}
                          onClick={() =>
                            handleClick(index, items.path, items.state)
                          }
                        >
                          {items.nav}
                        </div>
                      );
                    })}
                  </Breadcrumbs>
                </Stack>
              </div>
            </TabList>
            {state.pathname === "admin" && (
              <div className="list" style={{ backgroundColor: "#F5F5F5" }}>
                <div className="row">
                  <div className="col-sm-6 list-res">
                    {state?.params?.data?.initiative_name || ""}
                  </div>
                  {state?.pathname === "admin" &&
                    state.params.type === "initiative" && (
                      <>
                        <div
                          className="col-sm-3 d-flex justify-content-end"
                          style={{ alignItems: "center" }}
                        >
                          {value === "1" && (
                            <ReactToPrint
                              trigger={() => (
                                <div className="printBtn">
                                  <img src={ExportIcon} alt="" />
                                </div>
                              )}
                              content={() => componentRef.current}
                              // onAfterPrint={()=>setProductDetails([])}
                            />
                          )}
                        </div>
                        <div className="col-sm-3 client-ddl">
                          {Number(value) !== 4 && (
                            <Labelbox
                              type="select"
                              dropdown={RespondentList}
                              placeholder="All"
                              changeData={(data) => onIndividual(data)}
                              value={RespData[0].id}
                            />
                          )}
                        </div>
                      </>
                    )}
                </div>
              </div>
            )}
          </Box>
          <TabPanel value="1" ref={componentRef}>
            {(Number(localStorage.getItem("user_type")) === 0 &&
              overAllResult &&
              overAllResult.length === 0 && (
                <div className="waitmsg">Loading...</div>
              )) ||
              (Number(localStorage.getItem("user_type")) === 0 &&
                !(
                  overAllResult &&
                  overAllResult.length > 0 &&
                  overAllResult[0][0].pillar_data[0]?.r1_ans
                ) && (
                  <div className="waitmsg">The ​Initiative has expired.</div>
                )) ||
              (Individuals === 1 && (
                <IResult
                  showBtn
                  resp_id={RespSelect ? RespData[0].id : state?.params?.id}
                  state="admin"
                  name={
                    RespSelect
                      ? RespData[0].name
                      : state?.params?.data?.resp_mail
                  }
                />
              )) ||
              (state.pathname === "admin" &&
                state.params.type === "initiative" && (
                  <ScoreCard state="admin" />
                )) ||
              (state.pathname === "user" && (
                <IResult
                  showBtn={false}
                  state="user"
                  resp_id={state?.params?.id}
                />
              ))}
          </TabPanel>
          <TabPanel value="2">
            {(Number(localStorage.getItem("user_type")) === 0 &&
              overAllResult &&
              overAllResult.length === 0 && (
                <div className="waitmsg">Loading...</div>
              )) ||
              (Number(localStorage.getItem("user_type")) === 0 &&
                !(
                  overAllResult &&
                  overAllResult.length > 0 &&
                  overAllResult[0][0].pillar_data[0]?.r1_ans
                ) && (
                  <div className="waitmsg">The ​Initiative has expired.</div>
                )) ||
              (Individuals === 1 && (
                <ISummary
                  showBtn
                  resp_id={RespSelect ? RespData[0].id : state?.params?.id}
                  state="admin"
                  name={
                    RespSelect
                      ? RespData[0].name
                      : state?.params?.data?.resp_mail
                  }
                />
              )) ||
              (state.pathname === "admin" &&
                state.params.type === "initiative" && (
                  <Metrics state="admin" />
                )) ||
              (state.pathname === "user" && (
                <ISummary
                  showBtn={false}
                  state="user"
                  resp_id={state?.params?.id}
                />
              ))}
          </TabPanel>
          <TabPanel value="3">
            {(Number(localStorage.getItem("user_type")) === 0 &&
              overAllResult &&
              overAllResult.length === 0 && (
                <div className="waitmsg">Loading...</div>
              )) ||
              (Number(localStorage.getItem("user_type")) === 0 &&
                !(
                  overAllResult &&
                  overAllResult.length > 0 &&
                  overAllResult[0][0].pillar_data[0]?.r1_ans
                ) && (
                  <div className="waitmsg">The ​Initiative has expired.</div>
                )) ||
              (Individuals === 1 && (
                <IChart
                  showBtn
                  resp_id={RespSelect ? RespData[0].id : state?.params?.id}
                  state="admin"
                  name={
                    RespSelect
                      ? RespData[0].name
                      : state?.params?.data?.resp_mail
                  }
                />
              )) ||
              (state.pathname === "admin" &&
                state.params.type === "initiative" && (
                  <Alignment state="admin" />
                )) ||
              (state.pathname === "user" && (
                <IChart
                  showBtn={false}
                  state="user"
                  resp_id={state?.params?.id}
                />
              ))}
          </TabPanel>
          <TabPanel value="4">
            {(Number(localStorage.getItem("user_type")) === 0 &&
              overAllResult &&
              overAllResult.length === 0 && (
                <div className="waitmsg">Loading...</div>
              )) ||
              (Number(localStorage.getItem("user_type")) === 0 &&
                !(
                  overAllResult &&
                  overAllResult.length > 0 &&
                  overAllResult[0][0].pillar_data[0]?.r1_ans
                ) && (
                  <div className="waitmsg">The ​Initiative has expired.</div>
                )) ||
              (state.pathname === "admin" &&
                state.params.type === "initiative" && (
                  <Weighted state="admin" />
                ))}
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};
export default ScoreCardTab;
