/* eslint-disable func-names */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { Collapse, notification } from "antd";
import EnhancedTable from "../../component/DynTable/table";
import Labelbox from "../../helpers/labelbox/labelbox";
import CustomButton from "../../component/Buttons/button";
import Slider from "@mui/material/Slider";
import { apiurl } from "../../utils/baseUrl";
import axios from "axios";
import "./package.scss";
import {
  InsertManageInitiative,
  GetManagePackage,
} from "../../actions/ManagePackageAction";
import {
  GetPackages,
  GetClients,
  GetPillars,
} from "../../actions/MasterAction";
import ValidationLibrary from "../../helpers/ValidationFunction/validationfunction";
import moment from "moment";

const SingleManageInitiative = () => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const [MasterDropdows, setMasterDropdows] = useState([]);
  // const [Reset, setReset] = useState(1);
  const [SaveButton, setSaveButton] = useState(true);
  const [StatementTable, setStatementTable] = useState([]);
  const [StatementTableDB, setStatementTableDB] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasOneINitiative, setHasOneINitiative] = useState(false);
  // const [PWeight, setPWeight] = useState([]);
  // const [P_Weight_Total, setP_Weight_Total] = useState(100);
  // const [R_Weight_Total, setR_Weight_Total] = useState(0);
  // const [PWeightCalTrigger, setPWeightCalTrigger] = useState(false);
  // const [RWeightCalTrigger, setRWeightCalTrigger] = useState(false);

  let clientId = "";
  if (typeof window !== "undefined") {
    clientId = JSON.parse(localStorage.getItem("token")).client_id;
  }
  useEffect(() => {
    if (clientId) {
      try {
        axios({
          method: "post",
          url: `${apiurl}get_dashboard_client_details_counts`,
          data: {
            client_id: clientId,
            status: 4,
          },
        }).then((response) => {
          if (response.data.data[0]?.initiative_id) {
            // Validate him not to create a new initiative
            setHasOneINitiative(true);
            setLoading(false);
          } else {
            setLoading(false);
            // If No Initiative is found
            setHasOneINitiative(false);
          }
        });
      } catch (err) {
        setLoading(false);
        notification.success({
          message: `Error loading Initiatives`,
        });
        console.error("ERR", err);
      }
    }
  }, [clientId]);

  const [descriptionError, setDescriptionError] = useState("");
  const [RespMaxCount, setRespMaxCount] = useState(0);
  const [ManageInitiative, setManageInitiative] = useState({
    client_id: {
      value: JSON.parse(localStorage.getItem("token")).client_id,
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    package_id: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    start_date: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    end_date: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    no_of_respondents: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    initiative_name: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    initiative_description: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    initiative_stage: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    initiative_scope: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    // p_weight_1: {
    //   value: "",
    //   validation: [
    //     { name: "required" },
    //     { name: "custommaxValue", params: "100" },
    //   ],
    //   error: null,
    //   errmsg: null,
    // },
    package_qtn_count: {
      value: "0",
      // validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    salutation: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    date_of_mail: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    reminder_mail_date: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    invite_content: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    reminder_content: {
      value: "",
      validation: [],
      error: null,
      errmsg: null,
    },
  });

  const LightHeader = [{ id: "statement", label: "Statement" }];
  const ComprehensiveHeader = [
    { id: "id", label: "S.No" },
    { id: "statement", label: "Statement" },
  ];

  /// /////**** API DATA PROCESS******////////////

  useEffect(() => {
    dispatch(GetClients());
    dispatch(GetPackages(1));
    dispatch(GetPillars());
  }, [dispatch]);

  const MasterData = useSelector((state) => state.MasterReducer);
  const GetManagePackageData = useSelector(
    (state) => state.ManagePackageReducer
  );

  useEffect(() => {
    const getClientsList = [];
    MasterData.GetClients.map((data) => {
      getClientsList.push({ id: data.client_id, value: data.client_name });
      return 0;
    });
    const getPackagesList = [];
    MasterData?.GetPackages?.map((data) => {
      getPackagesList.push({ id: data.package_id, value: data.package_name });
      return 0;
    });
    setMasterDropdows({ getClientsList, getPackagesList });
  }, [MasterData]);

  // useEffect(() => {
  //   const arrTot = [];
  //   Object.keys(ManageInitiative).map((data) => {
  //     if (data.slice(0, 8) === "p_weight") {
  //       arrTot.push(data);
  //     }
  //     return 0;
  //   });
  //   const totalPweight = arrTot.map((val) => {
  //     return ManageInitiative[val].value;
  //   });
  //   const PW = totalPweight.reduce(function (a, b) {
  //     return Number(a) + Number(b);
  //   });

  //   // if (Math.round(PW) > 100) {
  //   //   notification.warning({
  //   //     message: `P-Weight total should must be 100 percent. `,
  //   //   });
  //   // }
  //   setP_Weight_Total(Math.round(PW));
  // }, [PWeightCalTrigger]);

  // useEffect(() => {
  // if (MasterData.GetPillars.length > 0) {
  // const pillarList = [];
  // MasterData.GetPillars.map((data, index) => {
  // if (index !== MasterData.GetPillars.length - 1) {
  // pillarList.push({ name: data.pillar_name });
  //   const p_weight = `p_weight_${index + 1}`;
  //   const p_weight_dynObj = {
  //     value: "14.3",
  //     error: null,
  //     errmsg: null,
  //     validation: [
  //       { name: "required" },
  //       { name: "allowNumaricOnly1" },
  //       { name: "custommaxValue", params: "100" },
  //     ],
  //     disabled: false,
  //   };
  //   setManageInitiative((prevState) => ({
  //     ...prevState,
  //     [p_weight]: p_weight_dynObj,
  //   }));
  // }
  // return 0;
  // });
  // setP_Weight_Total(100);
  // setPWeight(pillarList);
  // setReset(2);
  // setManageInitiative((prevState) => ({
  //   ...prevState,
  // }));
  // }
  // }, [MasterData.GetPillars]);

  // /**
  //  * Function to edit pillar weight
  //  *
  //  * @function OnEditPillarWeight
  //  * @param {number} pweight input pweight value
  //  */
  // function OnEditPillarWeight(pweight) {
  //   Array.from({ length: MasterData.GetPillars.length - 1 }, (x, i) => {
  //     i += 1;
  //     if (i !== pweight) {
  //       const p_weight = `p_weight_${i}`;
  //       const p_weight_dynObj = {
  //         value: 0,
  //         error: null,
  //         errmsg: null,
  //         validation: [
  //           { name: "required" },
  //           { name: "allowNumaricOnly1" },
  //           { name: "custommaxValue", params: "100" },
  //         ],
  //         disabled: false,
  //       };
  //       setManageInitiative((prevState) => ({
  //         ...prevState,
  //         [p_weight]: p_weight_dynObj,
  //       }));
  //     }
  //     return true;
  //   });
  // }
  const OnRespondentDelete = (id) => {
    Array.from({ length: ManageInitiative.no_of_respondents.value }, (x, i) => {
      i += 1;
      if (id <= i) {
        if (i === Number(ManageInitiative.no_of_respondents.value)) {
          ManageInitiative[`resp_name_${i}`].value = "";
          ManageInitiative[`resp_role_${i}`].value = "";
          // ManageInitiative[`resp_weight_${i}`].value = "";
          ManageInitiative[`resp_email_${i}`].value = "";
        } else {
          ManageInitiative[`resp_name_${i}`].value =
            ManageInitiative[`resp_name_${i + 1}`]?.value;
          ManageInitiative[`resp_role_${i}`].value =
            ManageInitiative[`resp_role_${i + 1}`]?.value;
          // ManageInitiative[`resp_weight_${i}`].value =
          //   ManageInitiative[`resp_weight_${i + 1}`]?.value;
          ManageInitiative[`resp_email_${i}`].value =
            ManageInitiative[`resp_email_${i + 1}`]?.value;
        }
      }
      return true;
    });
    setManageInitiative((prevState) => ({
      ...prevState,
    }));
    // setRWeightCalTrigger(!RWeightCalTrigger);
  };

  /**
   * Function to set convert local date to UTC date
   *
   * @function convertUTCDateToLocalDate
   * @param {Date} date input date value
   * @returns {Date} string UTC date according to the date passed.
   */
  function convertUTCDateToLocalDate(date) {
    const newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  useEffect(() => {
    setRespMaxCount(
      Number(ManageInitiative.no_of_respondents.value) < RespMaxCount
        ? RespMaxCount
        : ManageInitiative.no_of_respondents.value
    );
    const no_of_respondent =
      Number(ManageInitiative.no_of_respondents.value) < RespMaxCount
        ? RespMaxCount
        : ManageInitiative.no_of_respondents.value;

    Array.from({ length: no_of_respondent }, (x, i) => {
      i += 1;
      const resp_name = `resp_name_${i}`;
      const resp_role = `resp_role_${i}`;
      // const resp_weight = `resp_weight_${i}`;
      const resp_email = `resp_email_${i}`;
      if (i <= ManageInitiative.no_of_respondents.value) {
        const resp_name_dynObj = {
          value: ManageInitiative[resp_name]?.value || "",
          error: null,
          errmsg: null,
          validation: [{ name: "required" }],
          disabled: false,
        };
        const resp_role_dynObj = {
          value: ManageInitiative[resp_role]?.value || "",
          error: null,
          errmsg: null,
          validation: [{ name: "required" }],
          disabled: false,
        };
        const resp_email_dynObj = {
          value: ManageInitiative[resp_email]?.value || "",
          error: null,
          errmsg: null,
          validation: [{ name: "required" }, { name: "email" }],
          disabled: false,
        };

        setManageInitiative((prevState) => ({
          ...prevState,
          [resp_name]: resp_name_dynObj,
          [resp_role]: resp_role_dynObj,
          // [resp_weight]: resp_weight_dynObj,
          [resp_email]: resp_email_dynObj,
        }));
      } else {
        ManageInitiative[resp_name] && delete ManageInitiative[resp_name];
        ManageInitiative[resp_role] && delete ManageInitiative[resp_role];
        // ManageInitiative[resp_weight] && delete ManageInitiative[resp_weight];
        ManageInitiative[resp_email] && delete ManageInitiative[resp_email];
        setManageInitiative((prevState) => ({
          ...prevState,
        }));
      }
      return 0;
    });
  }, [ManageInitiative.no_of_respondents.value]);

  /**
   * @param {string} data input data
   * @param {string} key input field name
   * @param {number} pweight pweight field
   * @param {number} rweight rweight field
   */
  function checkValidation(data, key, pweight, rweight) {
    console.log("ManageInitiative", ManageInitiative);
    if (key === "start_date" || key === "end_date" || key === "date_of_mail") {
      const date = convertUTCDateToLocalDate(new Date(data));
      data = date;
    }
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      ManageInitiative[key].validation
    );
    const dynObj = {
      value: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
      validation: ManageInitiative[key].validation,
    };
    // ManageInitiative.package_qtn_count.value = 2;
    // dispatch(GetManagePackage(data));
    if (key === "package_id") {
      const package_data = MasterData?.GetPackages?.find((val) => {
        return data === val.package_id;
      });
      console.log("fi", data);
      ManageInitiative.package_qtn_count.value = 2;
      dispatch(GetManagePackage(data));
    }

    // if (key === "reminder_mail_date") {
    //   if (data.length > 0) {
    //     ManageInitiative.reminder_content.validation = [{ name: "required" }];
    //     setRefresh(!refresh);
    //   } else {
    //     ManageInitiative.reminder_content.validation = [];
    //     setRefresh(!refresh);
    //   }
    // }
    setManageInitiative((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }
  useEffect(() => {
    checkValidation(4, "no_of_respondents");
    setTimeout(() => {
      checkValidation(3, "package_id");
    }, 1000);
  }, []);

  /**
   *
   */
  const save = () => {
    const clientId = localStorage.getItem("client_id");
    console.log(ManageInitiative);
    console.log();
    localStorage.setItem(
      "initiativeData",
      JSON.stringify({ [clientId]: ManageInitiative })
    );
  };
  useEffect(() => {
    const previousInitiativeData = JSON.parse(
      localStorage.getItem("initiativeData")
    );
    const clientId = localStorage.getItem("client_id");
    if (previousInitiativeData)
      if (clientId in previousInitiativeData) {
        console.log("retrived", previousInitiativeData);
        setManageInitiative(previousInitiativeData[clientId]);
      }
  }, []);
  async function onSubmit() {
    const mainvalue = {};
    const targetkeys = Object.keys(ManageInitiative);
    for (const i in targetkeys) {
      const errorcheck = ValidationLibrary.checkValidation(
        ManageInitiative[targetkeys[i]].value,
        ManageInitiative[targetkeys[i]].validation
      );
      ManageInitiative[targetkeys[i]].error = !errorcheck.state;
      ManageInitiative[targetkeys[i]].errmsg = errorcheck.msg;
      mainvalue[targetkeys[i]] = ManageInitiative[targetkeys[i]].value;
    }
    const filtererr = targetkeys.filter(
      (obj) => ManageInitiative[obj].error === true
    );

    const arrTot = [];
    Object.keys(ManageInitiative).map((data1) => {
      if (data1.slice(0, 11) === "resp_email_") {
        arrTot.push(ManageInitiative[data1]?.value?.trim());
      }
      return 0;
    });
    const hasDuplicates = (arr) => arr.length !== new Set(arr).size;
    const checkIfAllAreCompanyEmails = (arr) => {
      const companyDomain = JSON.parse(
        localStorage.getItem("token")
      ).mail_id.split("@")[1];
      return arr.some((val) => val.split("@")[1] != companyDomain);
    };
    console.log("Filter ERR", filtererr);

    if (filtererr.length > 0) {
    } else if (hasDuplicates(arrTot)) {
      notification.warning({
        message: `This email is already in use, please use a different email. `,
      });
    } else if (checkIfAllAreCompanyEmails(arrTot)) {
      notification.warning({
        message: `Please use all email addresses from your company domain`,
      });
    }
    // else if (Number(P_Weight_Total) !== 100) {
    //   notification.warning({
    //     message: `P-Weight total should must be 100 percent. `,
    //   });
    // } else if (Number(R_Weight_Total) !== 100) {
    //   notification.warning({
    //     message: `R-Weight total should must be 100 percent. `,
    //   });
    // }
    else {
      setSaveButton(false);
      await dispatch(
        InsertManageInitiative(ManageInitiative, StatementTableDB)
      );
      setSaveButton(true);
      localStorage.removeItem("initiativeData");
      if (Number(localStorage.getItem("user_type")) === 4) {
        history.push("/Admin/dashboard/assesment-lead");
      } else {
        history.push("/Admin/dashboard");
      }
    }
    setManageInitiative((prevState) => ({
      ...prevState,
    }));
  }

  // useEffect(() => {
  //   const arrTot = [];
  //   Object.keys(ManageInitiative).map((data) => {
  //     if (data.slice(0, 12) === "resp_weight_") {
  //       arrTot.push(data);
  //     }
  //     return 0;
  //   });
  //   const totalRweight = arrTot.map((val) => {
  //     return ManageInitiative[val].value;
  //   });

  //   const RW = (totalRweight.length === 0 ? [0] : totalRweight).reduce(
  //     function (a, b) {
  //       return Number(a) + Number(b);
  //     }
  //   );

  //   Math.round(RW);
  //   // if (RW > 100) {
  //   //   notification.warning({
  //   //     message: `R-Weight total should must be 100 percent. `,
  //   //   });

  //   // }
  //   setR_Weight_Total(RW);
  // }, [RWeightCalTrigger]);

  useEffect(() => {
    if (
      ManageInitiative.package_id.value !== "" &&
      GetManagePackageData.GetManagePackage
    ) {
      const Table = [];
      const TableDB = [];
      GetManagePackageData.GetManagePackage.map((data) => {
        const rows = [];
        const rowsDB = [];
        data?.manage_package.map((data1, index1) => {
          rows.push({ id: index1 + 1, question: data1.question });
          rowsDB.push({
            pillars_id: data1.pillar_id,
            qus_bank_id: data1.qus_bank_id,
          });
          return 0;
        });
        Table.push({ name: data.manage_package[0].pillar_name, rows });
        TableDB.push({ rows: rowsDB });
        return 0;
      });
      setStatementTable(Table);
      setStatementTableDB(TableDB);
    }
  }, [GetManagePackageData.GetManagePackage]);

  const getNewDate = (date, newDays = 2) => {
    const new_date = moment(date, "YYYY-MM-DD").add(newDays, "days");

    const day = new_date.format("DD");
    const month = new_date.format("MM");
    const year = new_date.format("YYYY");
    return `${year}-${month}-${day}`;
  };

  const userName = localStorage.getItem("user_name");
  if (Number(localStorage.getItem("user_type")) === 4 && loading)
    return (
      <div className="Content_main_width">
        <div className="row">
          <h2>Loading...</h2>
        </div>
      </div>
    );

  return (
    <div className="Content_main_width">
      <div className="row">
        <div className="col-sm-4">
          <div className="TitlePane">Create/Manage Initiative</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ marginLeft: "-12px" }}>
          <div className="ques_bank_inputs">
            <div>
              <div className="heading">Client</div>
              <p
                style={{
                  marginBottom: "0",
                  fontSize: "24px",
                }}
              >
                {JSON.parse(localStorage.getItem("token")).client_name ||
                  JSON.parse(localStorage.getItem("token")).name ||
                  JSON.parse(localStorage.getItem("token")).mail_id.substring(
                    0,
                    JSON.parse(localStorage.getItem("token")).mail_id.indexOf(
                      "@"
                    )
                  )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-5" style={{ marginLeft: "15px" }}>
          <div className="ques_bank_inputs" style={{ marginLeft: "10px" }}>
            <div className="heading">Assessment Type</div>
            <p
              style={{
                marginBottom: "0",
                fontSize: "24px",
              }}
            >
              Lite
            </p>
            {/* <Labelbox
              type="select"
              dropdown={[
                {
                  id: 3,
                  value: "Limited Edition",
                },
                {
                  id: 1,
                  value: "Lightweight (Premium)",
                  disable: true,
                },
                {
                  id: 2,
                  value: "Comprehensive (Premium)",
                  disable: true,
                },
              ]}
              changeData={(data) => {
                checkValidation(data, "package_id");
              }}
              value={ManageInitiative.package_id.value}
              error={ManageInitiative.package_id.error}
              errmsg={ManageInitiative.package_id.errmsg}
              disabled={ManageInitiative.package_id.disabled}
            /> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 mt-3">
          <div className="" style={{ marginTop: "15px" }}>
            <div className="heading">Initiative Name</div>
            <div style={{ width: "90%" }}>
              <Labelbox
                type="text"
                changeData={(data) => checkValidation(data, "initiative_name")}
                value={ManageInitiative.initiative_name.value}
                error={ManageInitiative.initiative_name.error}
                errmsg={ManageInitiative.initiative_name.errmsg}
                disabled={ManageInitiative.initiative_name.disabled}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 mt-3">
          <div className="" style={{ marginTop: "15px" }}>
            <div className="heading">Initiative Description</div>
            <div style={{ width: "90%" }}>
              <Labelbox
                type="textarea"
                changeData={(data) =>
                  checkValidation(data, "initiative_description")
                }
                value={ManageInitiative.initiative_description.value}
                error={ManageInitiative.initiative_description.error}
                errmsg={ManageInitiative.initiative_description.errmsg}
                disabled={ManageInitiative.initiative_description.disabled}
                style={{ marginTop: "0px" }}
              />
              {/* <textarea
                cols="90"
                rows="5"
                type="text"
                className={`brdrcls inputID`}
                onChange={(e) => {
                  console.log(e.target.value.length);
                  if (e.target.value.length <= 500) {
                    setDescriptionError("");
                    checkValidation(e.target.value, "initiative_description");
                  } else setDescriptionError("Only 500 characters are allowed");
                }}
                value={ManageInitiative.initiative_description.value}
                error={
                  ManageInitiative.initiative_description.error ||
                  descriptionError
                }
                errmsg={ManageInitiative.initiative_description.errmsg}
                disabled={ManageInitiative.initiative_description.disabled}
              /> */}
            </div>
            <p
              style={{
                color: "red",
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
              }}
            >
              {descriptionError}
            </p>
          </div>
        </div>
        {/* <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Initiative Stage</div>
            {console.log(ManageInitiative)}
            <Labelbox
              type="select"
              dropdown={[
                {
                  id: 1,
                  value: "Design/Business Case Stage",
                },
                {
                  id: 2,
                  value: "Initiative Just Started",
                },
                {
                  id: 3,
                  value: "Initiative approaching key milestone",
                },
              ]}
              changeData={(data) => checkValidation(data, "initiative_stage")}
              value={ManageInitiative.initiative_stage.value}
              error={ManageInitiative.initiative_stage.error}
              errmsg={ManageInitiative.initiative_stage.errmsg}
              disabled={ManageInitiative.initiative_stage.disabled}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Initiative Scope</div>
            <Labelbox
              type="select"
              dropdown={[
                {
                  id: 1,
                  value: "Workgroup",
                },
                {
                  id: 2,
                  value: "Function",
                },
                {
                  id: 3,
                  value: "Organisation",
                },
                {
                  id: 4,
                  value: "Customers",
                },
                {
                  id: 5,
                  value: "Suppliers",
                },
                {
                  id: 6,
                  value: "Other",
                },
              ]}
              changeData={(data) => checkValidation(data, "initiative_scope")}
              value={ManageInitiative.initiative_scope.value}
              error={ManageInitiative.initiative_scope.error}
              errmsg={ManageInitiative.initiative_scope.errmsg}
              disabled={ManageInitiative.initiative_scope.disabled}
            />
          </div>
        </div> */}
        {/* <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Start Date</div>
            <div>
              <Labelbox
                type="datepicker"
                disablePast
                changeData={(data) => checkValidation(data, "start_date")}
                value={ManageInitiative.start_date.value}
                error={ManageInitiative.start_date.error}
                errmsg={ManageInitiative.start_date.errmsg}
                disabled={ManageInitiative.start_date.disabled}
              />
            </div>
          </div>
        </div> */}
        {/* <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">End Date</div>
            <div>
              <Labelbox
                type="datepicker"
                disablePast
                minDate={ManageInitiative.start_date.value}
                changeData={(data) => {
                  console.log(getNewDate(data, 7));
                  checkValidation(data, "end_date");
                }}
                minDateMessage="Date must be after initiative start date"
                value={ManageInitiative.end_date.value}
                error={ManageInitiative.end_date.error}
                errmsg={ManageInitiative.end_date.errmsg}
                disabled={ManageInitiative.end_date.disabled}
              />
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div
            className="ques_bank_inputs"
            style={{ marginLeft: "10px", marginTop: "15px" }}
          >
            <div className="heading">Number of Participants</div>
            {Number(localStorage.getItem("user_type")) === 4 ? (
              <Slider
                aria-label="participants"
                defaultValue={4}
                valueLabelDisplay="auto"
                step={1}
                onChange={(e) => {
                  checkValidation(e.target.value, "no_of_respondents");
                }}
                value={ManageInitiative.no_of_respondents.value}
                marks={[
                  {
                    value: 4,
                    label: "4",
                  },
                  {
                    value: 7,
                    label: "7",
                  },
                ]}
                min={4}
                max={7}
              />
            ) : (
              <div style={{ width: "80%" }}>
                <Labelbox
                  type="text"
                  changeData={(data) =>
                    checkValidation(data, "no_of_respondents")
                  }
                  value={ManageInitiative.no_of_respondents.value}
                  error={ManageInitiative.no_of_respondents.error}
                  errmsg={ManageInitiative.no_of_respondents.errmsg}
                  disabled={ManageInitiative.no_of_respondents.disabled}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="row">
        {StatementTable && StatementTable.length > 0 && (
          <Collapse expandIconPosition="right">
            <Panel header="Statement details" key="1">
              {StatementTable &&
                StatementTable.map((data) => {
                  return (
                    <div className="col-sm-12">
                      <div className="show_pillar">{data.name}</div>
                      <EnhancedTable
                        aligncss="newinitiative_table"
                        enableID={ManageInitiative.package_id.value !== 1}
                        tableMainStatus="score_tab"
                        rows={data.rows}
                        headCells={
                          ManageInitiative.package_id.value === 1
                            ? LightHeader
                            : ComprehensiveHeader
                        }
                        scorecard={7}
                      />
                    </div>
                  );
                })}
            </Panel>
          </Collapse>
        )}
      </div> */}
      <br />
      <div className="row">
        <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
          <Panel header="Respondent details" key="1">
            <div className="col-sm-12">
              <div className="row">
                <div className="new_grid_head">
                  <div>Respondent Name</div>
                  <div>Role</div>
                  <div>Email Address</div>
                  <div>Action</div>
                </div>
                <div className="body_div">
                  {/* <div className="pillar_det">
                    {PWeight.map((data, i) => {
                      return (
                        <div
                          className={`${
                            i % 2 === 0 && "grey_odd"
                          } set_width flex_div`}
                        >
                          <div
                            className={`${
                              i % 2 === 0 && "pillar_odd"
                            } body_pillar`}
                          >
                            <div className="pillar_name_initiative">{`  ${data.name}`}</div>
                          </div>
                          <div>
                            <Labelbox
                              type='text'
                              changeData={(data) => checkValidation(data, `p_weight_${i + 1}`, i + 1)}
                              value={ManageInitiative[`p_weight_${i + 1}`]?.value}
                              error={ManageInitiative[`p_weight_${i + 1}`]?.error}
                              errmsg={ManageInitiative[`p_weight_${i + 1}`]?.errmsg}
                              disabled
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div> */}
                  <div className="resp_det">
                    {ManageInitiative.no_of_respondents.value > 0 &&
                      Array.from(
                        { length: ManageInitiative.no_of_respondents.value },
                        (x, i) => {
                          return (
                            <div
                              className={`${
                                i % 2 === 0 && "grey_odd"
                              } set_width flex_div`}
                            >
                              <Labelbox
                                type="text"
                                changeData={(data) =>
                                  checkValidation(
                                    data,
                                    `resp_name_${i + 1}`,
                                    false,
                                    false
                                  )
                                }
                                value={
                                  ManageInitiative[`resp_name_${i + 1}`]?.value
                                }
                                error={
                                  ManageInitiative[`resp_name_${i + 1}`]?.error
                                }
                                errmsg={
                                  ManageInitiative[`resp_name_${i + 1}`]?.errmsg
                                }
                                disabled={
                                  ManageInitiative[`resp_name_${i + 1}`]
                                    ?.disabled
                                }
                              />
                              <Labelbox
                                type="text"
                                changeData={(data) =>
                                  checkValidation(
                                    data,
                                    `resp_role_${i + 1}`,
                                    false,
                                    false
                                  )
                                }
                                value={
                                  ManageInitiative[`resp_role_${i + 1}`]?.value
                                }
                                error={
                                  ManageInitiative[`resp_role_${i + 1}`]?.error
                                }
                                errmsg={
                                  ManageInitiative[`resp_role_${i + 1}`]?.errmsg
                                }
                                disabled={
                                  ManageInitiative[`resp_role_${i + 1}`]
                                    ?.disabled
                                }
                              />
                              {/* <Labelbox
                              type='text'
                              changeData={(data) => checkValidation(data, `resp_weight_${i + 1}`, false, true)}
                              value={ManageInitiative[`resp_weight_${i + 1}`]?.value}
                              error={ManageInitiative[`resp_weight_${i + 1}`]?.error}
                              errmsg={ManageInitiative[`resp_weight_${i + 1}`]?.errmsg}
                              disabled={ManageInitiative[`resp_weight_${i + 1}`]?.disabled}
                            /> */}
                              <Labelbox
                                type="text"
                                changeData={(data) =>
                                  checkValidation(
                                    data,
                                    `resp_email_${i + 1}`,
                                    false,
                                    false
                                  )
                                }
                                value={
                                  ManageInitiative[`resp_email_${i + 1}`]?.value
                                }
                                error={
                                  ManageInitiative[`resp_email_${i + 1}`]?.error
                                }
                                errmsg={
                                  ManageInitiative[`resp_email_${i + 1}`]
                                    ?.errmsg
                                }
                                disabled={
                                  ManageInitiative[`resp_email_${i + 1}`]
                                    ?.disabled
                                }
                              />
                              <div className="del_icon">
                                <DeleteOutlineOutlinedIcon
                                  onClick={() => OnRespondentDelete(i + 1)}
                                  style={{ cursor: "pointer" }}
                                  className="deleteoutline"
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                    {(ManageInitiative.no_of_respondents.value === "" ||
                      ManageInitiative.no_of_respondents.value === 0) &&
                      Array.from({ length: 7 }, (x, i) => {
                        return (
                          <div
                            className={`${
                              i % 2 === 0 && "grey_odd"
                            } set_width flex_div`}
                          >
                            <Labelbox type="text" disabled />
                            <Labelbox type="text" disabled />
                            {/* <Labelbox type="text" disabled /> */}
                            <div className="del_icon">
                              <DeleteOutlineOutlinedIcon className="deleteoutline" />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                {/* <div className="foot_div">
                  <div style={{ cursor: 'pointer', borderRadius: 19 }} onClick={() => setReset(1)}>
                    Reset
                  </div>
                  <div> </div>
                  <div>{`${Number(P_Weight_Total)}/${"100"}`}</div>
                  <div> </div>
                  <div>{`${Number(R_Weight_Total)}/${"100"}`}</div>
                  <div> </div>
                  <div> </div>
                </div> */}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="row">
        <div className="col-sm-12 setup_title">Setup email invite</div>
        {/* <div className="col-sm-4">
          <div className="mailcontent_div">
            <div className="heading">Email Invite Header</div>
            <Labelbox
              type="text"
              changeData={(data) => checkValidation(data, "salutation")}
              value={ManageInitiative.salutation.value}
              error={ManageInitiative.salutation.error}
              errmsg={ManageInitiative.salutation.errmsg}
              disabled={ManageInitiative.salutation.disabled}
            />
          </div>
        </div> */}
        <div className="col-sm-4">
          <div className="mailcontent_div">
            <div className="heading">Start Date / Email send date</div>
            <Labelbox
              type="datepicker"
              disablePast
              changeData={(data) => {
                console.log(getNewDate(data, 7));
                checkValidation(data, "date_of_mail");
                checkValidation(data, "start_date");
                checkValidation(1, "initiative_stage");
                checkValidation(1, "initiative_scope");
                checkValidation("Email", "salutation");
                checkValidation(getNewDate(data, 7), "end_date");
                checkValidation(`${getNewDate(data, 1)}`, "reminder_mail_date");
                checkValidation("Test", "invite_content");
                checkValidation("Test", "reminder_content");
              }}
              minDate={ManageInitiative?.start_date?.value}
              maxDate={ManageInitiative?.end_date?.value}
              minDateMessage="Date must be after initiative start date"
              maxDateMessage="Date must be before initiative end date"
              value={ManageInitiative.date_of_mail.value}
              error={ManageInitiative.date_of_mail.error}
              errmsg={ManageInitiative.date_of_mail.errmsg}
              disabled={ManageInitiative.date_of_mail.disabled}
            />
          </div>
        </div>
        {/* <div className="col-sm-4">
          <div className="mailcontent_div">
            <div className="heading">Reminder email send date</div>
            <Labelbox
              type="multicalendar"
              multiple
              onChangeData={(data) => {
                console.log(data);
                checkValidation(data, "reminder_mail_date");
              }}
              format="MMM DD YYYY"
              value={ManageInitiative?.reminder_mail_date?.value}
              minDate={ManageInitiative?.start_date?.value}
              maxDate={ManageInitiative?.end_date?.value}
              error={ManageInitiative.reminder_mail_date.error}
              errmsg={ManageInitiative.reminder_mail_date.errmsg}
              disabled={ManageInitiative.start_date.disabled}
            />
          </div>
        </div> */}
        {/* <div className="col-sm-6 remove_padding">
          <div className="textarea_div">
            <div className="heading">Invite Content</div>
            <Labelbox
              type="textarea"
              changeData={(data) => checkValidation(data, "invite_content")}
              value={ManageInitiative.invite_content.value}
              error={ManageInitiative.invite_content.error}
              errmsg={ManageInitiative.invite_content.errmsg}
              disabled={ManageInitiative.invite_content.disabled}
            />
          </div>
        </div> */}
        {/* <div className="col-sm-6 remove_padding">
          <div className="textarea_div">
            <div className="heading">Reminder Content</div>
            <Labelbox
              type="textarea"
              changeData={(data) => checkValidation(data, "reminder_content")}
              value={ManageInitiative.reminder_content.value}
              error={ManageInitiative.reminder_content.error}
              errmsg={ManageInitiative.reminder_content.errmsg}
              disabled={ManageInitiative.reminder_content.disabled}
            />
          </div>
        </div> */}
      </div>
      <div className="row btnsubmit">
        <CustomButton
          btnName="Save"
          custombtnCSS="warning"
          btnDisable={!SaveButton}
          onBtnClick={() => save()}
        />
        <CustomButton
          btnName="SUBMIT"
          custombtnCSS="Success"
          btnDisable={!SaveButton}
          onBtnClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};
export default SingleManageInitiative;
