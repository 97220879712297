import { useState, useEffect } from "react";
import { apiurl } from "../../utils/baseUrl";
import { notification } from "antd";
import axios from "axios";
import EnhancedTable from "../../component/DynTable/table";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Checkbox from "@mui/material/Checkbox";
import { Modal } from "antd";
import Labelbox from "../../helpers/labelbox/labelbox";
import CustomButton from "../../component/Buttons/button";

const AssesmentLead = () => {
  let clientId = "";
  const [serverDate, setServerDate] = useState("");
  const [terms, setTerms] = useState(false);
  if (typeof window !== "undefined") {
    clientId = JSON.parse(localStorage.getItem("token")).client_id;
  }
  const [loading, setLoading] = useState(true);
  const [clientDetails, setClientDetails] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [dashboardStatus, setDashboardStatus] = useState("");
  const [dashboardKPIData, setDashboardKPIData] = useState([]);
  const [CardStatus, setCardStatus] = useState(6);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentEditDate, setCurrentEditDate] = useState({});
  const [editEmailId, setEditEmailId] = useState(false);
  const [emailError, setEmailError] = useState(false);
  //   const [Header, setHeader] = useState(initiativeHeader);
  const client = clientDetails[0];

  //   const getTablesData = () =>

  const dashboardFields = [
    {
      name: "Total Respondents",
      key: "Total_Invite",
      status: 4,
      Icon: "/static/media/Invite.b1445998.svg",
      count: "0",
      className: "card-0",
    },
    {
      name: "Completed",
      key: "Completed",
      status: 2,
      Icon: "/static/media/CompleteIcon.849a47c2.svg",
      count: "0",
      className: "card-1",
    },
    {
      name: "Ongoing",
      key: "On_Going",
      status: 1,
      Icon: "/static/media/OnGoingIcon.a0aa07bb.svg",
      count: "0",
      className: "card-2",
    },
    {
      name: "Yet To Start",
      key: "Yet_To_Start",
      status: "0",
      Icon: "/static/media/calendar.958c0ba2.svg",
      count: "0",
      className: "card-3",
    },
  ];
  const initiativeHeader = [
    // { id: 'serialno', label: 'Serial No' },
    { id: "client_name", label: "Client Name" },
    { id: "initiative_name", label: "Initiative Name" },
    { id: "no_of_respondents", label: "No of Respondents" },
    { id: "start_date", label: "Start Date" },
    { id: "end_date", label: "End Date" },
    { id: "remaining_days", label: "Remaining Days" },
    { id: "date_of_mail", label: "Invite Mail" },
    { id: "reminder_mail_date", label: "Reminder Dates" },
    { id: "status", label: "Status" },
    // { id: "action", label: "Action" },
  ];
  const respondentHeader = [
    { id: "resp_name", label: "Respondent Name" },
    { id: "resp_mail", label: "Email Address" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];
  const respondentHeaderWithoutAction = [
    { id: "resp_name", label: "Respondent Name" },
    { id: "resp_mail", label: "Email Address" },
    { id: "status", label: "Status" },
  ];
  const getCurrentHeader = () => {
    if (CardStatus == "0") return respondentHeader;
    if (CardStatus != 6) return respondentHeaderWithoutAction;
    return initiativeHeader;
  };
  const getTableRows = (header, rows) => {
    console.log("rows", rows);
    // resendLogin
    if (CardStatus == 6) {
      const finalObj = {};
      if (rows.length > 0 && serverDate) {
        rows.forEach((value) => {
          console.log(getDateRange(serverDate, client?.end_date));
          const tempValue = {
            remaining_days: getDateRange(serverDate, client?.end_date),
            ...value,
          };
          header.forEach((val) => {
            const id = val.id;
            if (id != "action") {
              if (tempValue[id]) {
                finalObj[id] = tempValue[id];
              } else {
                console.error(`${id} does not exist on the object`);
              }
            }
          });
        });
      }
      return [finalObj];
    } else {
      if (rows.length > 0) {
        const finalVal = [];
        console.log(dashboardKPIData);
        dashboardKPIData.forEach((user) => {
          const tempObj = {};
          header.forEach((val) => {
            const item = val.id;
            if (item == "action") {
              tempObj.action = (
                <div className="action-div">
                  <div
                    className="cursor ml-4"
                    onClick={() => {
                      console.log("loop", user);
                      setIsEditOpen(user.resp_mail);
                      setCurrentEditDate(user);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className="cursor ml-4"
                    onClick={() => {
                      try {
                        axios({
                          method: "post",
                          url: `${apiurl}respondent_mail_resend`,
                          data: {
                            respondent_id: user.respondent_id,
                            client_id: user.client_id,
                          },
                        }).then((response) => {
                          if (response.data.status === 1) {
                            notification.success({
                              message: `Respondent email resent.`,
                            });
                          } else if (response.data.status === 0) {
                            notification.success({
                              message: response.data.msg,
                            });
                          }
                        });
                      } catch (err) {
                        notification.success({
                          message: `An application error occurred while resending the respondent email. Please contact the AlignedAround support team.`,
                        });
                      }
                    }}
                  >
                    Resend
                  </div>
                </div>
              );
            } else tempObj[item] = user[item];
          });
          finalVal.push(tempObj);
        });

        return finalVal;
      }
    }
  };
  const getDateRange = (firstDate, lastDate) => {
    if (moment(firstDate).isSame(moment(lastDate, "YYYY-MM-DD"), "day"))
      return 1;
    let date = moment(firstDate);
    // const dates = [date];
    let final = 0;
    const lastBeforeDate = moment(lastDate, "YYYY-MM-DD").subtract(1, "days");
    do {
      date = moment(date).add(1, "day");
      //   dates.push(date.format("YYYY-MM-DD"));
      final += 1;
    } while (moment(date).isBefore(lastBeforeDate));
    return final;
  };
  useEffect(() => {
    if (clientId !== "") {
      try {
        axios({
          method: "post",
          url: `${apiurl}get_dashboard_client_details_counts`,
          data: {
            client_id: clientId,
            status: 4,
          },
        }).then((response) => {
          setClientDetails(response.data.data);
          if (response.data.data[0]?.initiative_id) {
            if (typeof window !== "undefined") {
              localStorage.setItem("dashboardStatus", "1Initiative");
            }
            setDashboardStatus("1Initiative");
            // If we got initiative
            axios({
              method: "post",
              url: `${apiurl}get_dashboard_count_by_initiative_id`,
              data: {
                initiative_id: response.data.data[0].initiative_id,
              },
            }).then((res) => {
              localStorage.setItem("has_one_initiative", true);
              setDashboardData(res.data.data[0]);
              setLoading(false);
              // console.log(getTableRows(initiativeHeader, response.data.data[0]));
            });
            // Validate him not to create a new initiative
          } else {
            // If No Initiative is found
            setLoading(false);
            if (typeof window !== "undefined") {
              localStorage.setItem("dashboardStatus", "noInititative");
            }
            setDashboardStatus("noInititative");
          }
        });
      } catch (err) {
        notification.success({
          message: `Error loading dashboard`,
        });
        console.error("ERR", err);
      }
    }
  }, [clientId, CardStatus]);
  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${apiurl}getCurrentDate`,
      }).then((response) => {
        const date = response.data.data.serverDate.slice(0, 10);
        console.log("SERVER DATE", date);
        setServerDate(date);
      });
    } catch (err) {
      console.error("ERR Gettimng Date", err);
    }
    if (typeof window !== "undefined") {
      setDashboardStatus(localStorage.getItem("dashboardStatus") || "");
    }
  }, []);
  const onDashboardButtonClick = (data) => {
    console.log("onDashboardButtonClick", data);
    const payload = {
      initiative_id: client.initiative_id,
      status: data.status,
      initiative: 1,
      client_id: 0,
    };
    setCardStatus(data.status);
    axios({
      method: "post",
      url: `${apiurl}get_dashboard_status_by_initiative_id`,
      data: payload,
    }).then((response) => {
      setDashboardKPIData(response.data.data);
    });
  };
  if (loading) return "Loading...";
  return (
    <div>
      {dashboardStatus === "noInititative" ? (
        <div style={{ textAlign: "left", paddingLeft: "30px" }}>
          <h3>Please create a Initiative</h3>
        </div>
      ) : (
        <div className="Content_main_width">
          {/* <Breadcrumbs FunctionBreadcrumbsChange={(data) => OnBreadcrumbsClick(data)} breadcrumbs={BreadcrumbsData} /> */}
          <div className="row">
            <div className="col-sm-12">
              <div className="greetings">
                <div>
                  <span>Client - </span>
                  {client?.client_name}
                </div>
                <div>
                  <u>
                    <span
                      className="name_font cursor"
                      onClick={() => {
                        setCardStatus(6);
                      }}
                    >
                      {" "}
                      Initiative -{" "}
                    </span>
                    <span
                      className="name_font cursor"
                      onClick={() => {
                        setCardStatus(6);
                      }}
                    >
                      {client?.initiative_name}
                    </span>
                  </u>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard_div">
            <div className="row">
              {dashboardFields.map((data) => {
                return (
                  <div
                    className={"dash1"}
                    onClick={() => onDashboardButtonClick(data)}
                  >
                    <div className={`dashcard ${data.className}`}>
                      <div className="circle-ripple" />

                      <div className="Dashboard_card_title">
                        <div className="card-title">{data.name}</div>
                        {/* <div className="card-subtitle">{data.key}</div> */}
                      </div>
                      <div className="card_content">
                        <div className="align_card_text">
                          <div className="icon_main_div">
                            <div className="icon_image_size">
                              <img src={data.Icon} alt="" />
                            </div>
                          </div>

                          <div className="number_count">
                            <div className="count">
                              {dashboardData[data.key] || data.count}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="table_div">
            <div className="row">
              <EnhancedTable
                header
                tableMainStatus={
                  CardStatus === 5
                    ? "TotalClient"
                    : CardStatus === 6
                    ? "TotalInitiative"
                    : CardStatus === 4
                    ? "TotalRespondents"
                    : CardStatus === 1
                    ? "Completed"
                    : CardStatus === 2
                    ? "Ongoing"
                    : "Upcoming"
                }
                tabletitle="Initiatives"
                headCells={getCurrentHeader()}
                rows={getTableRows(getCurrentHeader(), clientDetails)}
                //   rows={
                //     DashboardTable.length === 0
                //       ? DashboardTable
                //       : DashboardTable.getDashboardList
                //   }
              />
            </div>
          </div>
        </div>
      )}
      <Modal
        title={<div> Edit Email - {isEditOpen}</div>}
        centered
        visible={isEditOpen}
        width={1200}
        zIndex={1201}
        closable
        onCancel={() => setIsEditOpen(false)}
        footer={[
          <div className="">
            {/* <CustomButton
                btnName="OK"
                custombtnCSS="PrimaryBtn"
                onBtnClick={() => setInitiativeModelView(false)}
              /> */}
            <Labelbox
              type="mailbox"
              changeData={(data) => setEditEmailId(data)}
              value={editEmailId ? editEmailId : ""}
              error={!!emailError}
              errmsg={"Invalid Email"}
              // disabled={ManageInitiative.initiative_name.disabled}
            />
            <CustomButton
              btnName="Save"
              custombtnCSS="Warning"
              onBtnClick={() => {
                console.log(editEmailId);
                const re =
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (re.test(editEmailId)) {
                  setEmailError(false);
                  try {
                    axios({
                      method: "post",
                      url: `${apiurl}editEmail`,
                      data: {
                        email: isEditOpen,
                        client_id: clientId,
                        editEmail: editEmailId,
                        respondentName: currentEditDate.resp_name,
                        client_name: currentEditDate.client_name,
                        initiative_name: currentEditDate.initiative_name,
                      },
                    }).then((response) => {
                      console.log(response);
                      notification.success({
                        message: `Email Edited successfully`,
                      });
                      onDashboardButtonClick({
                        name: "Yet To Start",
                        key: "Yet_To_Start",
                        status: "0",
                        Icon: "/static/media/calendar.958c0ba2.svg",
                        count: "0",
                        className: "card-3",
                      });
                      setIsEditOpen(false);
                    });
                  } catch (err) {
                    console.error(err);
                    setIsEditOpen(false);
                    notification.success({
                      message: `Error Editing Email`,
                    });
                  }
                } else {
                  setEmailError(true);
                }
              }}
            />
          </div>,
        ]}
      >
        {/* <ViewInitiativeModal initiative_id={InitiativeId} /> */}
      </Modal>
    </div>
  );
};
export default AssesmentLead;
