/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-inner-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable no-empty */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import dateFormat from "dateformat";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Select, Radio } from "antd";
import PublishIcon from "@material-ui/icons/Publish";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import Mail from "../../images/TxtMailIcon.svg";
import search from "../../images/search.svg";
import Delete from "../../images/Delete_icon.png";
import TimerIcon from "../../images/Timer_icon.png";
import SelectionIcon from "../../images/Select_icon.png";

export default class Labelbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upload_model: false,
      gender: "M",
      open: false,
      value: null,
      selectedtime: props.value,
      selecteddate: props.value ? props.value : null,
      showCalendar: false,
    };
  }

  // changeGender(data) {
  //   this.setState({ gender: data });
  //   this.props.changeGender && this.props.changeGender(data);
  // }

  datepickerChange(date) {
    if (date === "Invalid Date") {
      this.props.invalidate && this.props.invalidate(date);
    } else {
      const datefmt = dateFormat(date, "yyyy-mm-dd");
      this.props.changeData && this.props.changeData(datefmt);
    }
  }

  timepickerChange(time) {
    // var timeformat = dateFormat(time, "hh:MM:ss");
    this.setState({ selectedtime: time });
    this.props.changeData && this.props.changeData(time);
  }

  // openCalendar() {
  //   if (this.state.showCalendar === true) {
  //     this.setState({ showCalendar: false });
  //   } else {
  //     this.setState({ showCalendar: true });
  //   }
  // }

  componentWillReceiveProps(props) {
    if (props.type === "datepicker") {
      if (Number.isNaN(new Date(props.value).getTime())) {
      } else {
        // var datefmt = dateFormat(props.value && props.value, 'yyyy-mm-dd');
        // this.setState({ selecteddate: datefmt })
      }
    }
    if (props.gendervalue) {
      this.setState({ gender: props.gendervalue });
    }
  }

  // onChange(time) {
  //   this.setState({ value: time });
  //   this.props.changeData && this.props.changeData(time);
  // }

  // handleSearch(value) {
  //   if (value) {
  //     fetch(value, (data) => this.setState({ data }));
  //   } else {
  //     this.setState({ data: [] });
  //   }
  // }

  renderinput(data) {
    if (data.type === "text") {
      return (
        <div className="formdiv inputlabel">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <input
              className={`${data.error && "brdred"} brdrcls inputID`}
              value={this.props.value}
              maxLength={this.props.maxlength}
              type="text"
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              onBlur={(e) =>
                this.props.SubmitData && this.props.SubmitData(e.target.value)
              }
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              hidden={this.props.hidden}
              id={this.props.id && this.props.id}
              style={this.props.style ? this.props.style : {}}
            />
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    }
    if (data.type === "number") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <input
              className={`${data.error && "brdred"} brdrcls inputID`}
              min="0"
              value={this.props.value}
              type="number"
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              onKeyDown={(e) =>
                (e.key === "e" || e.key === "+" || e.key === "-") &&
                e.preventDefault()
              }
              disabled={this.props.disabled}
            />
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    }
    if (data.type === "textarea") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <textarea
              className={`${data.error && "brdred"} txtarea m-0`}
              rows={this.props.rows}
              cols="50"
              value={this.props.value}
              placeholder={this.props.placeholder}
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              disabled={this.props.disabled}
              style={this.props.style ? this.props.style : {}}
            />
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    }
    if (data.type === "checkbox") {
      return (
        <div className="check">
          <FormControlLabel
            label={this.props.label && this.props.label}
            control={
              <Checkbox
                checked={this.props.checked}
                onChange={(e) =>
                  this.props.changeData && this.props.changeData(e)
                }
                // inputProps={{ 'aria-label': 'primary checkbox' }}
                name={this.props.name && this.props.name}
                classes={this.props.classes && this.props.classes}
                disabled={!!this.props.disabled}
                id={this.props.id && this.props.id}
                required={!!this.props.required}
                size={this.props.size && this.props.size}
              />
            }
          />
          {/* <label>{this.props.label && this.props.label}</label> */}
        </div>
      );
    }
    if (data.type === "multicalendar") {
      return (
        <div className={`formdiv ${data.error && "brdrcalender"}`}>
          <div>
            <DatePicker
              autoComplete="off"
              sort
              format={this.props.format && this.props.format}
              multiple={this.props.multiple && this.props.multiple}
              onChange={(date) => this.props?.onChangeData(date)}
              value={this.props.value}
              minDate={this.props.minDate && this.props.minDate}
              maxDate={this.props.maxDate && this.props.maxDate}
              render={<InputIcon />}
            />
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    }
    if (data.type === "radio") {
      return (
        <div className="formdiv">
          <div>
            <Radio.Group
              className={`${data.error && "brdred"}`}
              options={this.props.options && this.props.options}
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              value={this.props.value && this.props.value}
              optionType="default"
              buttonStyle="solid"
              size={this.props.size && this.props.size}
              disabled={this.props.disabled && this.props.disabled}
            />
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    }
    if (data.type === "datepicker") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div
            className={`${data.error && "datePickerbrdred"} ${
              this.props.className
            }`}
            style={{ color: "black" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                placeholder={this.props.placeholder}
                disableToolbar={
                  this.props.disableToolbar && this.props.disableToolbar
                }
                autoOk
                disabled={this.props.disabled}
                views={this.props.view && this.props.view}
                clearable={false}
                disableUnderline
                disableFuture={
                  this.props.disableFuture ? this.props.disableFuture : false
                }
                disablePast={this.props.disablePast && this.props.disablePast}
                minDate={this.props.minDate && this.props.minDate}
                maxDate={this.props.maxDate && this.props.maxDate}
                minDateMessage={
                  this.props.minDateMessage && this.props.minDateMessage
                }
                maxDateMessage={
                  this.props.maxDateMessage && this.props.maxDateMessage
                }
                inputVariant="outlined"
                format={
                  this.props.format === "MMM-yyyy" ? "MMM-yyyy" : "MMM dd yyyy"
                }
                margin="normal"
                id="date-picker-inline"
                // value={this.state.selecteddate}
                InputProps={{ readOnly: true }}
                value={this.props.value === "" ? null : this.props.value}
                onChange={(date) => this.datepickerChange(date)}
              />
            </MuiPickersUtilsProvider>

            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    }
    if (data.type === "timepicker") {
      // function onChange(date, dateString) {

      // }

      // const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            {/* <TimePicker value={this.props.value} onChange={(time)=>this.onChange(time)} /> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                margin="normal"
                inputVariant="outlined"
                id="time-picker"
                value={this.props.value || new Date()}
                onChange={(time) => this.timepickerChange(time)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                minTime={this.props.minTime && this.props.minTime}
                maxTime={this.props.maxTime && this.props.maxTime}
                InputProps={{ readOnly: true }}
                keyboardIcon={
                  <img
                    src={TimerIcon}
                    alt=""
                    className="labelboxTimePicker"
                    minTime={this.props.minTime && this.props.minTime}
                    maxTime={this.props.maxTime && this.props.maxTime}
                  />
                }
              />
            </MuiPickersUtilsProvider>
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    }
    if (data.type === "select") {
      // function onChange(value) {
      // }
      const { Option } = Select;
      // function onBlur() {
      // 	console.log('blur');
      // }

      // function onFocus() {
      // 	console.log('focus');
      // }

      // function onSearch(val) {
      // 	console.log('search:', val);
      // }

      let optionValue = null;

      data.dropdown &&
        data.dropdown.map((value) => {
          if (value.id === data.value) {
            optionValue = value.value;
          }
          return true;
        });

      let selectValue = [];

      if (data.value && this.props.mode === "multiple") {
        selectValue = data.value;
      } else if (this.props.mode === "multiple" && data.value === "") {
        selectValue = [];
      } else {
        selectValue = optionValue;
      }

      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>

          <Select
            disabled={this.props.disabled && true}
            className={`${data.error && "selectbrdred brdnone"} ${
              this.props.mode !== "multiple" && "selectAdjustHeight"
            } selectbox`}
            showSearch
            mode={this.props.mode ? this.props.mode : false}
            value={selectValue || this.props.placeholder}
            suffixIcon={
              <img src={SelectionIcon} className="SelectInput_svg" alt="" />
            }
            // showArrow={true}
            placeholder={this.props.placeholder}
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) =>
              this.props.changeData && this.props.changeData(value)
            }
            onSearch={(value) =>
              this.props.searchData && this.props.searchData(value)
            }
            onBlur={() => this.props.blurData && this.props.blurData()}
          >
            {data.dropdown && data.dropdown.length > 0
              ? data.dropdown.map((item, index) => {
                  if (item.value) {
                    if (this.props.mode === "multiple") {
                      return (
                        <Option
                          key={index}
                          disabled={item.disable}
                          value={item.value}
                        >
                          {item.value}
                        </Option>
                      );
                    }

                    return (
                      <Option
                        key={index}
                        disabled={item.disable}
                        value={item.id}
                      >
                        {item.value}
                      </Option>
                    );
                  }
                  return true;
                })
              : null}
          </Select>
          <div className="Errormsg">
            <div>{data.error && "Selection Required"}</div>
          </div>
        </div>
      );
    }
    if (data.type === "mailbox") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <TextField
            className={`${data.error && "mailbrdred"}`}
            required={this.props.required && this.props.required}
            variant="outlined"
            margin="normal"
            fullWidth
            // placeholder={"Email Address"}
            name="text"
            value={this.props.value && this.props.value}
            type="text"
            autoComplete={false}
            disabled={!!this.props.disabled}
            onChange={(e) =>
              this.props.changeData && this.props.changeData(e.target.value)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={Mail} alt="" />
                </InputAdornment>
              ),
            }}
          />
          <div className="Errormsg">
            <div>{data.error && data.errmsg}</div>
          </div>
        </div>
      );
    }

    if (data.type === "searchbox") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <TextField
            required={this.props.required && this.props.required}
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder="Search"
            name="text"
            value={this.props.value && this.props.value}
            type="text"
            autoComplete={false}
            onChange={(e) =>
              this.props.changeData && this.props.changeData(e.target.value)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={search} alt="" />
                </InputAdornment>
              ),
            }}
          />
          <div className="Errormsg">
            <div>{data.error && data.errmsg}</div>
          </div>
        </div>
      );
    }

    if (data.type === "upload") {
      /**
       * @function onFileView to view the value
       * @param {string} url passing the url
       */
      function onFileView(url) {
        window.open(
          `${url}`,
          "Popup",
          "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
        );
      }

      return (
        <div className="formdiv inputlabel">
          <label className="labeltxt">{data.labelname}</label>
          <div className={`${data.error && "brdred"} upload`}>
            <div style={{ width: "100%", display: "flex" }}>
              <input
                type="file"
                accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                style={{ fontSize: 12 }}
                value={
                  this.props.value &&
                  this.props.value.length == 0 &&
                  this.props.value
                    ? this.props.value
                    : ""
                }
                onChange={(e) =>
                  this.props.changeData &&
                  this.props.changeData(e.target.files[0])
                }
                disabled={this.props.disabled}
                hidden={this.props.hidden}
              />
              <PublishIcon />
            </div>
            {this.props.view_file &&
              this.props.view_file !== "null" &&
              this.props.view_file.length > 0 && (
                <>
                  <hr />
                  <div style={{ display: "flex", marginTop: -6 }}>
                    <div
                      style={{ width: "100%", cursor: "pointer" }}
                      onClick={() => onFileView(this.props.view_file)}
                    >
                      {this.props.view_file && this.props.view_file.length > 0
                        ? this.props.view_file.substr(35, 16).length > 15
                          ? `${this.props.view_file.substr(35, 16)}..`
                          : this.props.view_file.substr(35, 16)
                        : ""}
                    </div>
                    <img
                      src={Delete}
                      alt=""
                      onClick={() => this.setState({ upload_model: true })}
                      style={{
                        width: "20px",
                        cursor: "pointer",
                        padding: "3px",
                      }}
                    />
                  </div>
                </>
              )}
          </div>
          <div className="Errormsg">
            <div>{data.error && data.errmsg}</div>
          </div>
        </div>
      );
    }
  }

  render() {
    const labelcss = require("./labelbox.css");
    return (
      <div className="custom_labelbox">{this.renderinput(this.props)}</div>
    );
  }
}
