/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import axios from 'axios';
import moment from 'moment';
import { notification } from 'antd';
import { apiurl } from '../utils/baseUrl';
import { GetClients } from './MasterAction';

export const InsertUpdateDeleteClients = (data, client_id) => async (dispatch) => {
  try {
    let API_Name = 'insert_client';

    if (
      data.client_id.value &&
      data.client_id.value !== '' &&
      data.client_id.value !== 0 &&
      data.client_id.Edit === true
    ) {
      API_Name = 'update_client';
    } else if (client_id) {
      API_Name = 'delete_client';
    }
    await axios({
      method: 'post',
      url: apiurl + API_Name,
      data: {
        client_id: data.client_id.value || client_id,
        client_name: data.client_name.value,
        email: data.email.value,
        contact_person: data.contact_person.value,
        industry: data.industry.value,
        hq_location: data.hq_location.value,
        status: data.status.value,
        created_on: moment().format('YYYY-MM-DD'),
        created_by: localStorage.getItem('user_id'),
        updated_on: moment().format('YYYY-MM-DD'),
        updated_by: localStorage.getItem('user_id'),
      },
    }).then((response) => {
      if (response.data.status === 1) {
        notification.success({
          message: `Client ${client_id ? 'deleted.' : data.client_id.Edit ? 'updated.' : 'created.'} `,
        });
        dispatch(GetClients());
      } else if (response.data.status === 0) {
        notification.warning({
          message: response.data.msg,
        });
        dispatch(GetClients());
      }
      return Promise.resolve();
    });
  } catch (err) {
    notification.success({
      message: `An application error occured while ${
        client_id ? 'deleting' : data.client_id.Edit ? 'updating' : 'creating'
      } the client. Please contact the AlignedAround support team.`,
    });
  }
};
