/* eslint-disable default-param-last */
import { GET_MATRIX_RESPONDENT, GET_RESPONDENT_BY_INITIATIVE, GET_MATRIX_INITIATIVE_OVERALL } from '../utils/Constants';

const initalState = {
  GetMatrixByRespondent: [],
  GetRespondentByInitiative: [],
  GetMatrixInitiativeOverall: [],
};

const Scorecard = (state = initalState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MATRIX_RESPONDENT:
      return { ...state, GetMatrixByRespondent: payload };
    case GET_RESPONDENT_BY_INITIATIVE:
      return { ...state, GetRespondentByInitiative: payload };
    case GET_MATRIX_INITIATIVE_OVERALL:
      return { ...state, GetMatrixInitiativeOverall: payload };
    default:
      return state;
  }
};
export default Scorecard;
