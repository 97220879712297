import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Labelbox from "../../helpers/labelbox/labelbox";
import CustomButton from "../../component/Buttons/button";
import ValidationLibrary from "../../helpers/ValidationFunction/validationfunction";
import "../Pillars/pillars.scss";
import { insertFeedBack } from "../../actions/UserAction";

const PillarsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [feedBack, setFeedBack] = useState({ value: "" });
  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   * @param {string} item input field name
   */
  function checkValidation(data, key, item) {
    let dynObj = {};
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      feedBack.validation
    );
    dynObj = {
      value: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
      validation: feedBack.validation,
    };

    setFeedBack(dynObj);
  }

  /**
   * @function onSubmit used for validation purpose of input fields
   */
  async function onSubmit() {
    const errorcheck = ValidationLibrary.checkValidation(
      feedBack.value,
      feedBack.validation
    );
    feedBack.error = !errorcheck.state;
    feedBack.errmsg = errorcheck.msg;

    const filtererr = feedBack.error === true;

    if (filtererr.length > 0) {
    } else {
      const ReturnResponse = await dispatch(insertFeedBack(feedBack, history));
      if (ReturnResponse) {
        const params = {
          type: "user",
          id: JSON.parse(localStorage.getItem("user_id")),
        };
        history.push({
          pathname: "/User/score",
          state: {
            pathname: "user",
            params: params,
          },
        });
      }
    }

    setFeedBack((prevState) => ({
      ...prevState,
    }));
  }

  useEffect(() => {
    onSubmit();
  }, []);

  /**
   * @function onCancel used for cancel process
   */
  function onCancel() {
    const params = {
      type: "user",
      id: JSON.parse(localStorage.getItem("user_id")),
    };
    history.push({
      pathname: "/User/score",
      state: {
        pathname: "user",
        params: params,
      },
    });
  }
  return (
    <div className="Content_main_width">
      <div className="row">
        <div className="col-sm-4">
          <div className="pillar_name">AlignedAround Feedback</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="description">
            AlignedAround welcomes your feedback. Please provide any feedback
            you have regarding the AlignedAround experience, process,
            application or any other feedback you think may improve our service
            to you.
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-10">
          <Labelbox
            type="textarea"
            placeholder="Feedback"
            changeData={(data) => checkValidation(data, "comments")}
            value={feedBack?.comments?.value}
            error={feedBack?.comments?.error}
            errmsg={feedBack?.comments?.errmsg}
          />
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div className="col-sm-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "15px",
            }}
          >
            <CustomButton
              btnName="CANCEL"
              custombtnCSS="CancelBtn"
              onBtnClick={() => onCancel()}
            />
            <CustomButton
              btnName="SUBMIT"
              custombtnCSS="Success"
              onBtnClick={() => onSubmit()}
            />
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
export default PillarsPage;
