/* eslint-disable no-empty */
import axios from "axios";
import { notification } from "antd";
import moment from "moment";
import { apiurl } from "../utils/baseUrl";
import {
  GET_RANGE,
  GET_COUNTRY,
  GET_ETHNICITY,
  GET_QUESTION_PILLARWISE,
  INSERT_ANSWERS,
  INSERT_DEMO,
  GET_SUMMARY_RES,
  GET_RESP_DEMO,
  GET_ANCESTRY,
  GET_NATIONALITY,
} from "../utils/Constants";

//* ******************Demo*******************/
export const GetRange = () => async (dispatch) => {
  try {
    axios({
      method: "get",
      url: `${apiurl}get_range`,
    }).then((response) => {
      dispatch({ type: GET_RANGE, payload: response.data.data });
    });
  } catch (err) { }
};

export const GetCountry = () => async (dispatch) => {
  try {
    axios({
      method: "get",
      // url: "https://restcountries.com/v3.1/all",
      url: `${apiurl}getCountry`,
    }).then((response) => {
      dispatch({ type: GET_COUNTRY, payload: response.data.data });
    });
  } catch (err) { }
};
export const GetEthnicity = (id) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_ethnicites_by_countryId`,
      data: {
        country_id: id,
      },
    }).then((response) => {
      dispatch({ type: GET_ETHNICITY, payload: response.data.data });
    });
  } catch (err) { }
};
export const getNationality = (value) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}getCountryNationality`,
      data: {
        country: value || "",
      },
    }).then((response) => {
      dispatch({ type: GET_NATIONALITY, payload: response.data.data });
    });
  } catch (err) { }
};
export const GetAncestry = (id) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_born_country_hispanic_latino`,
      data: {
        country_id: id,
      },
    }).then((response) => {
      dispatch({ type: GET_ANCESTRY, payload: response.data.data });
    });
  } catch (err) { }
};

export const PostDemo = (Demo, history) => async (dispatch) => {
  try {
    let ReturnResponse = false;
    await axios({
      method: "post",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("accessToken")),
      },
      url: `${apiurl}insert_demograpic_details`,
      data: {
        id: JSON.parse(localStorage.getItem("id")),
        client_id: JSON.parse(localStorage.getItem("client_id")),
        respondent_id: JSON.parse(localStorage.getItem("user_id")),
        resp_mail: Demo.emailid.value,
        age: Demo.age.value,
        year_with_company: Demo.year_with_company.value,
        year_in_current_role: Demo.year_in_current_role.value,
        country_in_live: Demo.country_in_live.value,
        nationality: Demo.nationality.value,
        country_born: Demo.country_born.value,
        gender: Demo.gender.value,
        created_on: moment().format("YYYY-MM-DD"),
        created_by: JSON.parse(localStorage.getItem("user_id")),
      },
    }).then((response) => {
      if (response.data.status === 1) {
        // notification.success({
        //   message: 'Demography Created',
        // });
        dispatch({ type: INSERT_DEMO, payload: response.data.data });
        ReturnResponse = true;
      } else if (response.data.status === 0) {
        notification.success({
          message: response.data.msg,
        });
        localStorage.clear();
        history.push("/Userlogin");
        ReturnResponse = false;
        window.location.reload();
      }
    });
    return ReturnResponse;
  } catch (err) {
    notification.success({
      message: "An application error occurred while entering demographic data. Please contact the AlignedAround support team.",
    });
    return false;
  }
};

export const GetRespDemo = () => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_respondent_demographic_details`,
      data: {
        respondent_id: JSON.parse(localStorage.getItem("user_id")),
        client_id: JSON.parse(localStorage.getItem("client_id")),
      },
    }).then((response) => {
      dispatch({ type: GET_RESP_DEMO, payload: response.data.data });
    });
  } catch (err) { }
};

//* *****************Pillars**********************/
export const GetQuestions = (data) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_question_by_pillar_id`,
      data: {
        pillars_id: data.pillar_id,
        client_id: JSON.parse(localStorage.getItem("client_id")),
        respondent_id: JSON.parse(localStorage.getItem("user_id")),
      },
    }).then((response) => {
      dispatch({ type: GET_QUESTION_PILLARWISE, payload: response.data.data });
    });
  } catch (err) { }
};

export const InsertAnswers = (data, history) => async (dispatch) => {
  try {
    let ReturnResponse = false;
    await axios({
      method: "post",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("accessToken")),
      },
      url: `${apiurl}insert_response_of_question`,
      data: {
        id: JSON.parse(localStorage.getItem("id")),
        client_id: JSON.parse(localStorage.getItem("client_id")),
        initiative_id: JSON.parse(localStorage.getItem("initiative_id")),
        piller_id: data.pillar_id,
        respondent_id: JSON.parse(localStorage.getItem("user_id")),
        qus_ans: data.qus_ans,
        comment: data.comments,
        created_on: moment().format("YYYY-MM-DD 00:00:00"),
        created_by: JSON.parse(localStorage.getItem("user_id")),
      },
    }).then((response) => {
      console.log("response.data.status === "+response.data.status);
      if (response.data.status === 1) {
        dispatch({ type: INSERT_ANSWERS, payload: response.data.data });
        if (Number(data.pillar_id) === 8) {
          localStorage.setItem("status_respondent", 2);
        }
        ReturnResponse = true;
      } else if (response.data.status === 0) {
        notification.success({
          message: response.data.msg,
        });
        localStorage.clear();
        history.push("/Userlogin");
        ReturnResponse = false;
      }
    });
    return ReturnResponse;
  } catch (err) {
    notification.success({
      message: "An unknown application error has occurred. Please contact the AlignedAround support team.",
    });
    return false;
  }
};

export const GetSummaryByResp = (data) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_summery_by_respondent`,
      data: {
        respondent_id: data.resp_id,
        client_id: JSON.parse(localStorage.getItem("client_id")),
      },
    }).then((response) => {
      dispatch({ type: GET_SUMMARY_RES, payload: response.data.data });
    });
  } catch (err) { }
};

export const insertFeedBack = (data) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}insertFeedBack`,
      data: {
        respondent_id: JSON.parse(localStorage.getItem("user_id")),
        client_id: JSON.parse(localStorage.getItem("client_id")),
        initiative_id: JSON.parse(localStorage.getItem("initiative_id")),
        feedback: data.value,
      },
    }).then(() => { });
    return true;
  } catch (err) {
    notification.success({
      message: "An unknown application error has occurred. Please contact the AlignedAround support team.",
    });
    return false;
  }
};
//* ***********************************************/
