import React, { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
// import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
/* import CustomButton from "../../component/Buttons/button";
import vision from "../../images/chart_vision.svg";
import strategy from "../../images/chart_strategy.svg";
import success from "../../images/chart_success.svg";
import leader from "../../images/chart_leader.svg";
import account from "../../images/chart_account.svg";
import invest from "../../images/chart_invest.svg";
import advocacy from "../../images/chart_advocacy.svg";*/

const Alignment = () => {
  // const history = useHistory();
  const [group, setGroup] = useState([]);
  const [radar, setRadar] = useState([]);
  // const BackFunc = () => {
  //   history.push("/Admin/dashboard");
  // };

  /** ******* PAYLOAD */
  const Result = useSelector((state) => state.ScoreCardReducer);

  useEffect(() => {
    const grp = [];
    const lbl = [];
    Result.GetMatrixInitiativeOverall.map((data, index) => {
      if (index === 2) {
        setGroup(data);
        data.map((item, i) => {
          if (i !== 7 && i !== 8) {
            grp.push(item.group_value);
            lbl.push(item.pillar_name);
          }
          return true;
        });
        const state = {
          dataRadar: {
            labels: lbl,
            datasets: [
              {
                label: "",
                fill: "#FFFFFF",
                borderWidth: 0,
                backgroundColor: "rgba(255,255,255, 0.1)",
                data: [1, 7, 7, 7, 7, 7, 7],
              },
              {
                label: "Min. Alignment",
                // borderWidth: 0.8,
                backgroundColor: "rgba(217,71,58,0)",
                borderColor: "#DC473A",
                data: [5.5, 5.5, 5.5, 5.5, 5.5, 5.5, 5.5],
              },
              {
                label: "Group",
                backgroundColor: "rgba(255, 255, 0, 0)",
                borderColor: "#FFFF00",
                data: grp,
              },
            ],
          },
        };
        setRadar(state);
      }
      return true;
    });
  }, [Result.GetMatrixInitiativeOverall]);

  const scales = {
    scales: {
      r: {
        pointLabels: {
          color: "#204768",
          fontWeight: 500,
          font: {
            size: 16,
          },
        },
      },
    },
    tick: {
      // beginAtZero: true,
      min: 0,
      max: 7,
      stepSize: 1,
    },
    plugins: {
      legend: {
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 14,
          },
          boxWidth: 13,
        },
        position: "bottom",
        align: "center",
      },
    },
  };

  return (
    <div>
      <div className="row d-flex justify-content-around">
        <div className="chart-col-1">
          <div className="chart">
            {/* <img src={vision} alt="" id="vision1" className="chart-img" />
            <img src={strategy} alt="" id="strategy1" className="chart-img" />
            <img src={success} alt="" id="success1" className="chart-img" />
            <img src={leader} alt="" id="leader1" className="chart-img" />
            <img src={account} alt="" id="account1" className="chart-img" />
            <img src={invest} alt="" id="invest1" className="chart-img" />
            <img src={advocacy} alt="" id="advocacy1" className="chart-img" /> */}
            <Radar
              options={scales}
              data={radar.dataRadar}
              // width={400}
              // height={400}
            />
          </div>
        </div>
        <div className="chart-col-3">
          <div>
            <div className="alignscore c1">
              <div className="alignwave w1">Alignment</div>
              <div>
                <div className="horizontal-grid">
                  <div className="group">Alignment Pillars</div>
                  {/* <div className='group' id='sec-col'>
                    P Weight %
                  </div> */}
                  <div className="group" id="sec-col">
                    Group
                  </div>
                  <div className="group" id="sec-col">
                    Sentiment
                  </div>
                  <div>
                    {group.map((item, index) => {
                      return (
                        <div>
                          {index !== 8 && index !== 7 && (
                            <div
                              className={`col-align ${
                                index === 7 ? "chart_border" : ""
                              }`}
                            >
                              {item.pillar_name}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <div className="" id="initiative-group">
                    {group.map((item, index) => {
                      return (
                        <div>
                          {index !== 8 && index !== 7 && (
                            <div
                              className={`col-pillars ${
                                index === 7 ? "chart_border" : ""
                              }`}
                            >
                              {item.group_value || "-"}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="" id="initiative-group">
                    {group.map((item, index) => {
                      return (
                        <div className="alinedContent">
                          {index !== 8 && index !== 7 && (
                            <div
                              className={`col-pillars ${
                                index === 7 ? "chart_border" : ""
                              }`}
                            >
                              {item.likert_value || "-"}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="horizontal-grid sentiment">
              <div className="col-align">
                {group ? group[7]?.pillar_name : ""}
              </div>
              <div className="col-pillars">
                {group ? group[7]?.group_value : ""}
              </div>
              <div className="col-pillars">
                {group ? group[7]?.likert_value : ""}
              </div>
              <div className="col-align">
                {group ? group[8]?.pillar_name : ""}
              </div>
              <div className="col-pillars">
                {group ? group[8]?.group_value : ""}
              </div>
              <div className="col-pillars">
                {group ? group[8]?.likert_value : ""}
              </div>
            </div>
          </div>

          <div className="chart-row overallInterface">
            <div style={{ width: "100%" }}>
              <div className="I-coresel c1">
                <div className="wave w1">Overall Alignment Inferences</div>
                <div style={{ padding: "10px" }}>
                  <div style={{ display: "flex" }}>
                     
                    <div>
                      {group && group[7] && group[7].group_value > 5.5
                        ?   
                        <div>
                          <div style={{ display: "flex" }} key="1">
                          <div className="dot" />                    
                          The group is aligned with this initiative. 
                          </div>   
                        </div>
                        :  
                        <div>
                          <div style={{ display: "flex" }} key="1">
                          <div className="dot" />                    
                          The group is <b> &nbsp;not&nbsp;</b> aligned with this initiative. 
                          </div>   
                        </div>
                        } 
                      {group && group[8] && group[8].group_value > 5.5
                        ?   
                        <div> 
                          <div style={{ display: "flex" }} key="1">                     
                          <div className="dot" />
                          The group believes this initiative will be successful.
                          </div>  
                        </div>
                        :  
                        <div> 
                          <div style={{ display: "flex" }} key="1">                     
                          <div className="dot" />
                          The group believes this initiative will <b> &nbsp;not&nbsp;</b> be successful.
                          </div>  
                        </div>
                        }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="legendContainerAlignment">
              <div>EAS: Executive Alignment Score.</div>
            </div>
            <div className="legendContainerAlignment">
              <div>OSS: Overall Sentiment Score.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Alignment;
