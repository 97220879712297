/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTable from "../../../component/DynTable/table";
import "./viewModal.scss";
import {
  GetViewInitiative,
  Respondent_mail_resend,
} from "../../../actions/ManagePackageAction";

const ViewInitiativeModal = (props) => {
  const dispatch = useDispatch();
  const [initiative, setInitiative] = useState({});
  const [invite, setInvite] = useState({});
  const [statement, setStatement] = useState([]);
  // const [rows, setRows] = useState([]);
  const pillar_name = [
    "Vision",
    "Strategy",
    "Success",
    "Leader/Follower",
    "Accountability",
    "Investment",
    "Advocacy",
    "Sentiment",
  ];
  const head = [
    { id: "id", label: "S.No" },
    { id: "statement", label: "Statement" },
  ];
  const onResendMail = async (respondent_id) => {
    dispatch(Respondent_mail_resend(respondent_id));
  };

  /// /////**** API DATA PROCESS******////////////
  useEffect(() => {
    dispatch(GetViewInitiative(props.initiative_id.initiative_id));
  }, [props.initiative_id]);

  const GetInitiative = useSelector((state) => state.ManagePackageReducer);

  useEffect(() => {
    const pillars = [];
    GetInitiative?.GetViewInitiative.map((data, index) => {
      if (index === 0) {
        setInitiative(data[0]);
      }
      if (index === 1) {
        data.map((item, index) => {
          const pillar_row = [];
          item.pillar.map((row, i) => {
            pillar_row.push({ sno: i + 1, statement: row.question });
            return true;
          });
          pillars.push({ name: pillar_name[index], rows: pillar_row });
          return true;
        });
        setStatement(pillars);
      }
      if (index === 3) {
        setInvite(data[0]);
      }
      // if (index === 4) {
      //   const pillarList = [];
      //   data &&
      //     data.map((data, index) => {
      //       if (index !== data.length - 1) {
      //         pillarList.push({
      //           pillar_name: data.pillar_name,
      //           p_weight: data.p_weight,
      //         });
      //       }
      //       return 0;
      //     });
      //   setRows(pillarList);
      // }
      return true;
    });
  }, [GetInitiative.GetViewInitiative]);

  const DateConvert = (dates) => {
    const remDate = [];
    dates &&
      dates.split(",").map((date) => {
        remDate.push(moment(date).format("MMM DD yyyy"));
        return true;
      });
    return remDate.toString();
  };
  return (
    <>
      <div className="row add-padding">
        <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Client</div>
            <label className="show_initiative">
              {initiative?.client_name || "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Assessment Type</div>
            <label className="show_initiative">
              {initiative?.package_name || "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Initiative Name</div>
            <label className="show_initiative">
              {initiative?.initiative_name || "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">Start Date</div>
            <label className="show_initiative">
              {moment(initiative?.start_date).format("MMM DD yyyy") || "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">End Date</div>
            <label className="show_initiative">
              {moment(initiative?.end_date).format("MMM DD yyyy") || "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="ques_bank_inputs">
            <div className="heading">No. of Participants</div>
            <label className="show_initiative">
              {initiative?.no_of_respondents || "-"}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className={
            props.initiative_id?.status_id !== "3" &&
            props.initiative_id?.status !== "Completed"
              ? "action_grid content-style"
              : "grid_head content-style"
          }
        >
          <div>Respondent Name</div>

          <div>Respondent Email</div>
          {props.initiative_id?.status_id !== "3" &&
            props.initiative_id?.status !== "Completed" && <div>Action</div>}
        </div>
        <div className="body_div">
          {/* <div
            className={
              props.initiative_id?.status_id !== "3" &&
              props.initiative_id?.status !== "Completed"
                ? "short_width"
                : "pillar_div"
            }
          >
            {rows.map((data, i) => {
              return (
                <div
                  className={`${
                    i % 2 === 0 ? "grey_odd" : "grey_even"
                  } pillar_align cell_div`}
                >
                  <div className={`${i % 2 === 0 && "pillar_odd"} body_pillar`}>
                    <div
                      style={{ paddingLeft: "10px", justifyContent: "left" }}
                      className="left_align"
                    >
                      {`  ${data.pillar_name}`}
                    </div>
                  </div>
                  <div className='grid_data'>
                    <div>{data.p_weight}</div>
                  </div>
                </div>
              );
            })}
          </div> */}
          <div
            className={
              props.initiative_id?.status_id !== "3" &&
              props.initiative_id?.status !== "Completed"
                ? "action_resp_div"
                : "resp_div"
            }
          >
            {GetInitiative?.GetViewInitiative.length > 0 &&
              GetInitiative?.GetViewInitiative[2].map((data, index) => {
                return (
                  <div
                    className={`${index % 2 === 0 ? "grey_odd" : "grey_even"} ${
                      props.initiative_id?.status_id !== "3" &&
                      props.initiative_id?.status !== "Completed"
                        ? "left_action_grid"
                        : "left_grid"
                    } width_align`}
                  >
                    <div className="grid_data left_align">
                      {data.respondent_name}
                    </div>
                    {/* <div className='grid_data'>{data.r_weight}</div> */}
                    <div className="grid_data left_align">{data.resp_mail}</div>
                    {props.initiative_id?.status_id !== "3" &&
                      props.initiative_id?.status !== "Completed" && (
                        <div
                          className="resend"
                          style={{ cursor: "pointer" }}
                          onClick={() => onResendMail(data.respondent_id)}
                        >
                          Resend Mail
                        </div>
                      )}
                  </div>
                );
              })}
          </div>
        </div>
        {/* <div className="grid_foot">
          <div />
          <div>100%</div>
          <div> </div>
          <div>100%</div>
          <div> </div>
          <div> </div>
        </div> */}
      </div>

      <div className="row add-padding">
        <div className="col-sm-12 show_pillar">Invite Details</div>
        <div className="col-sm-4">
          <div>
            <div className="heading">Email Invite Header</div>
            <label className="show_initiative">
              {invite?.welcome_message || "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <div>
            <div className="heading">Date email to be sent</div>
            <label className="show_initiative">
              {(invite?.date_of_mail &&
                moment(invite?.date_of_mail).format("MMM DD yyyy")) ||
                "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <div>
            <div className="heading">Reminder email to be sent on</div>
            <label className="show_initiative">
              {(invite?.reminder_mail_date &&
                DateConvert(invite.reminder_mail_date)) ||
                "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div>
            <div className="heading">Invite Content</div>
            <label className="show_initiative">
              {invite?.invite_content || "-"}
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div>
            <div className="heading">Reminder Content</div>
            <label className="show_initiative">
              {invite?.reminder_content || "-"}
            </label>
          </div>
        </div>
      </div>

      <div className="row add-padding">
        {statement &&
          statement.map((data) => {
            return (
              <div className="col-sm-12">
                <div className="show_pillar">{data.name}</div>
                <EnhancedTable
                  aligncss="statement_table"
                  enableID
                  tableMainStatus="score_tab"
                  rows={data.rows}
                  headCells={head}
                  scorecard={7}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};
export default ViewInitiativeModal;
