/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import EnhancedTable from "../../component/DynTable/table";
import "./dash.scss";

const ScoreCard = () => {
  // const history = useHistory();
  //* ********/
  const [allResp, setAllResp] = useState([]);
  const [DynTable, setDynTable] = useState([]);
  const [noOfResp, setNoOfResp] = useState();
  /** */

  // const BackFunc = () => {
  //   history.push("/Admin/dashboard");
  // };

  /** ******* PAYLOAD */
  const Result = useSelector((state) => state.ScoreCardReducer);

  useEffect(() => {
    const table = [];
    let noResp = "";
    Result?.GetMatrixInitiativeOverall?.map((data, index) => {
      if (index === 0) {
        data.map((item) => {
          const row = [];
          item.pillar_data.map((rows, index) => {
            if (
              (Result?.GetMatrixInitiativeOverall[1][0].package_type === 1 &&
                index === 0) ||
              (Result?.GetMatrixInitiativeOverall[1][0].package_type === 2 &&
                (rows.pillar_name === "Sentiment"
                  ? index === 0
                  : index !== item.pillar_data.length - 2))
            ) {
              const scoreObj = {};
              noResp = rows.no_of_respondents;
              scoreObj.statement = rows.qus_no;
              for (let q = 1; q <= rows.no_of_respondents; q++) {
                scoreObj[`r${q}`] = rows[`r${q}_ans`] ? rows[`r${q}_ans`] : "-";
              }
              scoreObj.all = rows.qus_mean;
              row.push(scoreObj);
            }
            return true;
          });
          const headerView = [];
          const headCount = Object.keys(row[0]).length;
          for (let i = 1; i <= headCount; i++) {
            headerView.push({
              id: i === headCount ? "all" : i === 1 ? "statement" : `r${i - 1}`,
              label:
                i === headCount ? "All" : i === 1 ? "Statement" : `R${i - 1}`,
            });
          }
          table.push({
            label: item.pillar_data[0].pillar_name,
            dynrows: row,
            dynhead: headerView,
            p_weight: item.pillar_data[0].pillar_weight,
          });
          return true;
        });
      }
      return true;
    });
    setAllResp(table);
    setNoOfResp(noResp);
  }, [Result.GetMatrixInitiativeOverall]);

  useEffect(() => {
    const table = [];
    allResp.map((data) => {
      const row = [];
      const headerView = [];
      let startPos = "";
      let endPos = "";
      data.dynrows.map((rowitems) => {
        const respCount = Object.keys(rowitems).length - 2;
        if (respCount > 10) {
          const scoreObj = {};
          scoreObj.statement = rowitems.statement;
          for (let i = 1; i <= 10; i++) {
            scoreObj[`r${i}`] = rowitems[`r${i}`];
          }
          scoreObj.all = rowitems.all;
          row.push(scoreObj);
          startPos = 1;
          endPos = 10;
        } else {
          const scoreObj = {};
          scoreObj.statement = rowitems.statement;
          for (let i = 1; i <= respCount; i++) {
            scoreObj[`r${i}`] = rowitems[`r${i}`];
          }
          scoreObj.all = rowitems.all;
          row.push(scoreObj);
          startPos = 1;
          endPos = respCount;
        }

        return true;
      });
      const headCount = Object.keys(row[0]).length;
      for (let i = 1; i <= headCount; i++) {
        headerView.push({
          id: i === headCount ? "all" : i === 1 ? "statement" : `r${i - 1}`,
          label: i === headCount ? "All" : i === 1 ? "Statement" : `R${i - 1}`,
        });
      }
      table.push({
        label: data.label,
        dynrows: row,
        dynhead: headerView,
        startPos,
        endPos,
        p_weight: data.p_weight,
      });

      return true;
    });
    setDynTable(table);
  }, [allResp]);

  const moveFrwd = (index) => {
    const dynheadLen = DynTable[index].dynhead.length;
    const lastResp = DynTable[index].dynhead[dynheadLen - 2];
    const endResp = Number(lastResp.label.substring(1, 3));
    const isAbove = noOfResp - endResp;

    if (isAbove > 10) {
      allResp.map((data, pos) => {
        if (pos === index) {
          const row = [];
          const headerView = [];
          data.dynrows.map((rowitems) => {
            const scoreObj = {};
            scoreObj.statement = rowitems.statement;
            for (let i = endResp + 1; i <= endResp + 10; i++) {
              scoreObj[`r${i}`] = rowitems[`r${i}`];
            }
            scoreObj.all = rowitems.all;
            row.push(scoreObj);
            return true;
          });
          for (let i = endResp + 1; i <= endResp + 12; i++) {
            headerView.push({
              id:
                i === endResp + 12
                  ? "all"
                  : i === endResp + 1
                  ? "statement"
                  : `r${i - 1}`,
              label:
                i === endResp + 12
                  ? "All"
                  : i === endResp + 1
                  ? "Statement"
                  : `R${i - 1}`,
            });
          }
          DynTable[index] = {
            label: data.label,
            dynrows: row,
            dynhead: headerView,
            startPos: endResp + 1,
            endPos: endResp + 10,
            p_weight: data.p_weight,
          };
        }
        return true;
      });
      setDynTable((prevState) => [...prevState]);
    } else {
      allResp.map((data, pos) => {
        if (pos === index) {
          const row = [];
          const headerView = [];
          data.dynrows.map((rowitems) => {
            const scoreObj = {};
            scoreObj.statement = rowitems.statement;
            for (let i = endResp + 1; i <= endResp + isAbove; i++) {
              scoreObj[`r${i}`] = rowitems[`r${i}`];
            }
            scoreObj.all = rowitems.all;
            row.push(scoreObj);
            return true;
          });
          for (let i = endResp + 1; i <= endResp + isAbove + 2; i++) {
            headerView.push({
              id:
                i === endResp + isAbove + 2
                  ? "all"
                  : i === endResp + 1
                  ? "statement"
                  : `r${i - 1}`,
              label:
                i === endResp + isAbove + 2
                  ? "All"
                  : i === endResp + 1
                  ? "Statement"
                  : `R${i - 1}`,
            });
          }
          DynTable[index] = {
            label: data.label,
            dynrows: row,
            dynhead: headerView,
            startPos: endResp + 1,
            endPos: endResp + isAbove,
            p_weight: data.p_weight,
          };
        }
        return true;
      });
      setDynTable((prevState) => [...prevState]);
    }
  };

  const moveBwd = (index) => {
    const firstResp = DynTable[index].dynhead[1];
    const startResp = Number(firstResp.label.substring(1, 3));
    allResp.map((data, pos) => {
      if (pos === index) {
        const row = [];
        const headerView = [];
        data.dynrows.map((rowitems) => {
          const scoreObj = {};
          scoreObj.statement = rowitems.statement;
          for (let i = startResp - 10; i <= startResp - 1; i++) {
            scoreObj[`r${i}`] = rowitems[`r${i}`];
          }
          scoreObj.all = rowitems.all;
          row.push(scoreObj);
          return true;
        });
        for (let i = startResp - 10; i <= startResp + 1; i++) {
          headerView.push({
            id:
              i === startResp + 1
                ? "all"
                : i === startResp - 10
                ? "statement"
                : `r${i - 1}`,
            label:
              i === startResp + 1
                ? "All"
                : i === startResp - 10
                ? "Statement"
                : `R${i - 1}`,
          });
        }
        DynTable[index] = {
          label: data.label,
          dynrows: row,
          dynhead: headerView,
          startPos: startResp - 10,
          endPos: startResp - 1,
          p_weight: data.p_weight,
        };
      }
      return true;
    });
    setDynTable((prevState) => [...prevState]);
  };

  return (
    <div>
      <div className="row">
        {DynTable.map((data, index) => {
          return (
            <div className="col-sm-12" key={index}>
              <label className="pillars">{data.label}</label>

              {noOfResp > 10 && (
                <div className="arrowsDirection">
                  <div className="arrows">
                    <button
                      className="arrowDirect"
                      disabled={data.startPos === 1}
                      onClick={() => moveBwd(index)}
                    >
                      {"<"}
                    </button>
                    <button
                      className="arrowDirect"
                      disabled={data.endPos === noOfResp}
                      onClick={() => moveFrwd(index)}
                    >
                      {">"}
                    </button>
                  </div>
                </div>
              )}
              <EnhancedTable
                headCells={data.dynhead}
                aligncss="score_table"
                rows={data.dynrows}
                tableMainStatus="score_tab"
                scorecard={9}
                enableID
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ScoreCard;
