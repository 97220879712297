/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal } from "antd";
import Complete from "../../images/CompleteIcon.svg";
import Ongoing from "../../images/OnGoingIcon.svg";
import Upcoming from "../../images/calendar.svg";
import EnhancedTable from "../../component/DynTable/table";
import Invite from "../../images/Invite.svg";
import "./dashboard.scss";
import { GetDashboardCount } from "../../actions/MasterAction";
import {
  GetClientsDashboard,
  GetInitiativeDashboardCount,
  GetClientDashboardCount,
  GetInitiativeDashboard,
  StopRespondent,
} from "../../actions/DashboardAction";
import ClientIcon from "../../images/client_icon.svg";
import InitiativeIcon from "../../images/initiative_icon.svg";
import ViewInitiativeModal from "../ManagePackage/ViewInitiative/ViewInitiativeModal";
import CustomButton from "../../component/Buttons/button";

const Dashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = location.state || "";
  const [InitiativeId, setInitiativeId] = useState(0);
  const [ClientId, setClientId] = useState(0);
  const [InitiativeModelView, setInitiativeModelView] = useState(false);
  // const [BreadcrumbsChange, setBreadcrumbsChange] = useState(false);

  /**
   * API RESPONSE STATUS
   * 4=>Total Initiatives
   * 2=>completed
   * 1=>ongoing
   * 0=>upcoming
   */

  /**
   * @function setDashBoardStatus used for control and change the dashboard table
   * 1 => Client Table
   * 2 => Initiative Table
   * 3 => Respondent Table
   */
  const [DashBoardStatus, setDashBoardStatus] = useState(1);

  /**
   * @function setDashBoardCountStatus used for control and change the dashboard count table
   * 1 => Client Count Table
   * 2 => Initiative Count Table
   * 3 => Respondent Count Table
   */
  const [DashBoardCountStatus, setDashBoardCountStatus] = useState(1);

  /**
   * @function setCardStatus used for control and change the dashboard card view
   * 1 => Completed
   * 2 => Ongoing
   * 3 => Upcoming or yet to start
   */
  const [CardStatus, setCardStatus] = useState(5);

  const [DashboardTable, setDashboardTable] = useState([]);
  const [clientinfo, setclientinfo] = useState([]);

  const [DashBoard, setDashBoard] = useState({
    client_id: {
      value: "",
      error: null,
      errmsg: null,
      name: "client_id",
      type: "select",
      placeholder: "Client Name",
      dropdown: [],
    },
    status: {
      name: "status",
      type: "select",
      placeholder: "Status",
      value: "2",
      dropdown: [
        { value: "Ongoing", id: "1" },
        { value: "Completed", id: "2" },
        { value: "Upcoming", id: "0" },
      ],
    },
    initiative_id: {
      name: "",
      value: "0",
    },
  });

  const ClientCard = [
    {
      name: "Total Clients",
      key: "",
      Icon: ClientIcon,
      count: "0",
      status: 5,
      cursor: "none",
      className: "card-4",
    },
    {
      name: "Total",
      key: "Client Initiatives",
      Icon: InitiativeIcon,
      count: "0",
      status: 6,
      cursor: "none",
      className: "card-0",
    },
    {
      name: "Completed",
      key: "Client Initiatives",
      Icon: Complete,
      count: "0",
      status: 1,
      cursor: "none",
      className: "card-1",
    },
    {
      name: "Ongoing",
      key: "Client Initiatives",
      Icon: Ongoing,
      count: "0",
      status: 2,
      cursor: "none",
      className: "card-2",
    },
    {
      name: "Upcoming",
      key: "Client Initiatives",
      Icon: Upcoming,
      count: "0",
      status: 3,
      cursor: "none",
      className: "card-3",
    },
  ];

  const InitiativeCard = [
    {
      name: "Total Initiatives",
      key: "",
      Icon: InitiativeIcon,
      count: "0",
      status: 6,
      className: "card-0",
    },
    {
      name: "Completed",
      key: "Initiatives",
      Icon: Complete,
      count: "0",
      status: 1,
      className: "card-1",
    },
    {
      name: "Ongoing",
      key: "Initiatives",
      Icon: Ongoing,
      count: "0",
      status: 2,
      className: "card-2",
    },
    {
      name: "Upcoming",
      key: "Initiatives",
      Icon: Upcoming,
      count: "0",
      status: 3,
      className: "card-3",
    },
  ];

  const RespondentCard = [
    {
      name: "Total Respondents",
      key: "",
      status: 4,
      Icon: Invite,
      count: "0",
      className: "card-0",
    },
    {
      name: "Completed",
      key: "Respondents",
      status: 1,
      Icon: Complete,
      count: "0",
      className: "card-1",
    },
    {
      name: "Ongoing",
      key: "Respondents",
      status: 2,
      Icon: Ongoing,
      count: "0",
      className: "card-2",
    },
    {
      name: "Yet To Start",
      key: "Respondents",
      status: 3,
      Icon: Upcoming,
      count: "0",
      className: "card-3",
    },
  ];

  const [DashboardCard, setDashboardCard] = useState(ClientCard);

  const ClientsHeader = [
    { id: "client_name", label: "Client Name" },
    { id: "initiative", label: "No. of initiatives" },
    { id: "action", label: "Action" },
  ];
  const RespondentHeader = [
    { id: "respondent_name", label: "Respondent Name" },
    { id: "respondent_email", label: "Email Address" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];
  const InitiativeHeader = [
    // { id: 'serialno', label: 'Serial No' },
    { id: "name", label: "Client Name" },
    { id: "initiative", label: "Initiative Name" },
    { id: "respondents", label: "No of Respondents" },
    { id: "sd", label: "Start Date" },
    { id: "ed", label: "End Date" },
    { id: "invite_mail", label: "Invite Mail" },
    { id: "reminder", label: "Reminder Dates" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];

  const [Header, setHeader] = useState(InitiativeHeader);
  console.log("Header", Header);
  // setHeader(InitiativeHeader);
  const [recursive, setRecursive] = useState(0);
  // useEffect(() => {
  //   if (Number(localStorage.getItem("user_type")) === 4) {
  //     handleView(
  //       "c3d9e86386b11",
  //       JSON.parse(localStorage.getItem("token")).client_name
  //     );
  //     setDashBoardStatus(2);
  //     ClientDashboard({
  //       initiative_id: JSON.parse(localStorage.getItem("token")).initiative_id,
  //       initiative_name: JSON.parse(localStorage.getItem("token"))
  //         .initiative_id,
  //       package_id: 1,
  //       package_name: "LimitedEdition",
  //       client_id: JSON.parse(localStorage.getItem("token")).client_id,
  //       client_name: JSON.parse(localStorage.getItem("token")).client_name,
  //       start_date: "2023-12-20",
  //       end_date: "2023-12-22",
  //       no_of_respondents: 2,
  //       reminder_mail_date: "2023-12-21",
  //       date_of_mail: "2023-12-20",
  //       status: "UpComing",
  //       status_id: "0",
  //     });
  //   }
  // }, [DashBoardStatus, DashBoardCountStatus, ClientId, CardStatus]);
  /// /////************ API DATA PROCESS************////////////

  const MasterData = useSelector((ReducerState) => ReducerState.MasterReducer);
  const DashboardReducer = useSelector(
    (ReducerState) => ReducerState.DashboardReducer
  );

  /** */
  useEffect(() => {
    // dispatch(GetClients())
    dispatch(GetDashboardCount());
    dispatch(GetClientsDashboard());
  }, [dispatch]);

  /// /////************ set counts on dashoboard card************////////////
  useEffect(() => {
    MasterData.GetDashboardCount &&
      MasterData.GetDashboardCount.length > 0 &&
      DashboardCard.map((data) => {
        data.count =
          data.status === 1
            ? MasterData.GetDashboardCount[0].Completed
            : data.status === 2
            ? MasterData.GetDashboardCount[0].On_Going
            : data.status === 3
            ? MasterData.GetDashboardCount[0].Upcomming
            : data.status === 5
            ? MasterData.GetDashboardCount[0].Total_client
            : MasterData.GetDashboardCount[0].Total_inititative ||
              MasterData.GetDashboardCount[0].total_initiative ||
              0;
        return true;
      });
    setDashboardCard((prevState) => [...prevState]);
  }, [MasterData.GetDashboardCount]);

  useEffect(() => {
    DashboardReducer.GetInitiativeCount &&
      DashboardReducer.GetInitiativeCount.length > 0 &&
      DashboardReducer.GetInitiativeCount.map(() => {
        DashboardReducer.GetInitiativeCount &&
          DashboardReducer.GetInitiativeCount.length > 0 &&
          DashboardCard.map((data) => {
            data.count =
              data.status === 1
                ? DashboardReducer.GetInitiativeCount[0].Completed
                : data.status === 2
                ? DashboardReducer.GetInitiativeCount[0].On_Going
                : data.status === 3
                ? DashboardReducer.GetInitiativeCount[0].Upcomming
                : data.status === 5
                ? DashboardReducer.GetInitiativeCount[0].Total_client
                : DashboardReducer.GetInitiativeCount[0].Total_inititative ||
                  DashboardReducer.GetInitiativeCount[0].total_initiative ||
                  0;
            return true;
          });
        setDashboardCard((prevState) => [...prevState]);
        return true;
      });
  }, [DashboardReducer.GetInitiativeCount]);

  useEffect(() => {
    DashboardReducer.GetInitiativeDashboardCount &&
      DashboardReducer.GetInitiativeDashboardCount.length > 0 &&
      DashboardCard.map((data, index) => {
        DashboardReducer.GetInitiativeDashboardCount.length !== 0 &&
          setclientinfo([DashboardReducer.GetInitiativeDashboardCount[0]]);
        data.count =
          index === 0
            ? DashboardReducer.GetInitiativeDashboardCount[0].Total_Invite
            : index === 1
            ? DashboardReducer.GetInitiativeDashboardCount[0].Completed
            : index === 2
            ? DashboardReducer.GetInitiativeDashboardCount[0].On_Going
            : DashboardReducer.GetInitiativeDashboardCount[0].Yet_To_Start;
        return true;
      });
    setDashboardCard((prevState) => [...prevState]);
  }, [DashboardReducer.GetInitiativeDashboardCount]);

  /** **************** Respondent Table */
  useEffect(() => {
    if (DashBoardStatus === 3 && DashboardReducer?.GetInitiativeDashboard) {
      const getDashboardList = [];
      DashboardReducer?.GetInitiativeDashboard.map((data, index) => {
        getDashboardList.push({
          id: index + 1,
          resp_name: data.resp_name,
          resp_mail: data.resp_mail,
          status: (
            <div
              className={
                (data.status === "Completed" && "completed") ||
                (data.status === "Ongoing" && "ongoing") ||
                (data.status === "Upcoming" && "upcoming") ||
                (data.status === "Yet to Start" && "yettostart")
              }
            >
              {data.status}
            </div>
          ),
          action: (
            <div>
              {data.stop_status === 0 ? (
                "Stopped"
              ) : data.status_id === 2 || data.status === "Completed" ? (
                <div className="action-div">
                  <div
                    className="cursor"
                    onClick={() => ScoreCard("resp", data.respondent_id, data)}
                  >
                    Score Card
                  </div>
                </div>
              ) : (
                <div className="cursor" onClick={() => OnStopResp(data)}>
                  Stop
                </div>
              )}
            </div>
          ),
        });
        return true;
      });

      setDashboardTable({ getDashboardList });
    }
  }, [DashboardReducer.GetInitiativeDashboard]);

  /** **************** Initiative Table and clients table */
  useEffect(() => {
    const getDashboardList = [];
    let remDate = [];
    /** **************** clinets table */
    if (DashBoardStatus === 1 && DashboardReducer.GetClientsDashboard) {
      setHeader(ClientsHeader);
      DashboardReducer.GetClientsDashboard &&
        DashboardReducer.GetClientsDashboard.length > 0 &&
        DashboardReducer.GetClientsDashboard.map((data, index) => {
          getDashboardList.push({
            id: index + 1,
            client_name: (
              <div
                className="cursor"
                onClick={() => handleView(data.client_id, data.client_name)}
              >
                {data.client_name}
              </div>
            ),
            initiative_count: <div>{data.initiative_count}</div>,
            action: (
              <div
                className="action-div cursor"
                onClick={() => handleView(data.client_id, data.client_name)}
              >
                View
              </div>
            ),
          });
          return true;
        });
      setDashboardTable({ getDashboardList });
    }
    /** **************** Initiative Table */
    if (DashBoardStatus === 2 && DashboardReducer.GetInitiativeDashboard) {
      setHeader(InitiativeHeader);
      DashboardReducer.GetInitiativeDashboard.map((data, index) => {
        console.log("irukkan", data);
        remDate = [];
        data.reminder_mail_date &&
          data.reminder_mail_date.split(",").map((date) => {
            remDate.push(moment(date).format("MMM DD yyyy"));
            return 0;
          });
        getDashboardList.push({
          id: index + 1,
          client_name: (
            <div className="cursor" onClick={() => ClientDashboard(data)}>
              {data.client_name}
            </div>
          ),
          initiative_name: (
            <div className="cursor" onClick={() => ClientDashboard(data)}>
              {data.initiative_name}
            </div>
          ),
          respondents: data.no_of_respondents,
          start_date: moment(data.start_date).format("MMM DD yyyy"),
          end_date: moment(data.end_date).format("MMM DD yyyy"),
          date_of_mail: moment(data.date_of_mail).format("MMM DD yyyy"),
          reminder_mail_date: remDate.toString(),
          status: (
            <div
              className={
                (data.status === "Completed" && "completed") ||
                (data.status === "Ongoing" && "ongoing") ||
                (data.status === "Upcoming" && "upcoming") ||
                (data.status === "Yet to Start" && "yettostart")
              }
            >
              {data.status}
            </div>
          ),
          action: (
            <div className="action-div">
              <div className="cursor" onClick={() => OnInitiativeView(data)}>
                View
              </div>
              {Number(data?.status_id) === 2 ? (
                <>
                  &nbsp;&nbsp;
                  <div
                    className="cursor"
                    onClick={() =>
                      ScoreCard("initiative", data.initiative_id, data)
                    }
                  >
                    Score Card
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          ),
        });
        return true;
      });
      setDashboardTable({ getDashboardList });
    }
  }, [
    DashboardReducer.GetClientsDashboard,
    DashboardReducer.GetInitiativeDashboard,
  ]);

  /**
   * @function ScoreCard used for ScoreCard action
   * @param {string} type define the type
   * @param {number} id define the id
   * @param {string} data define the data
   */
  function ScoreCard(type, id, data) {
    const params = { type, id, data };

    history.push({
      pathname: "/Admin/ScoreCardDash",
      state: {
        pathname: "admin",
        params,
      },
    });
  }

  const handleColor = (data) => {
    if (DashBoardStatus === 1) {
    } else if (DashBoardStatus === 2 && DashBoardCountStatus === 2) {
      dispatch(
        GetClientDashboardCount({
          status: data === 1 ? 3 : data === 2 ? 2 : data === 3 ? 1 : 4,
          client_id: ClientId,
        })
      );
      setCardStatus(data);
    } else {
      DashBoard.status.value =
        data === 1 ? 3 : data === 2 ? 2 : data === 3 ? 1 : 4;
      dispatch(
        GetInitiativeDashboard({
          // client_id: ClientId,
          status: data === 1 ? 2 : data === 2 ? 1 : data === 3 ? "0" : data,
          initiative_id: DashBoard.initiative_id.value || state?.admin,
          initiative:
            DashBoardCountStatus === 3 || DashBoardStatus === 3 ? 1 : "0",
        })
      );
      setCardStatus(data);
      if (DashBoardCountStatus === 2) {
        setHeader(RespondentHeader);
        setDashBoardStatus(3);
      }

      if (DashBoardCountStatus === 3) {
        setHeader(RespondentHeader);
        setDashBoardStatus(3);
      }
      setDashBoard((prevState) => ({
        ...prevState,
      }));
    }
  };

  const OnStopResp = (data) => {
    dispatch(StopRespondent(data));
  };

  const OnInitiativeView = (data) => {
    setInitiativeId(data);
    setInitiativeModelView(true);
  };

  const handleView = (clientId, clientName) => {
    // console.log(clientId, clientName);
    localStorage.setItem("client_id", `"${clientId}"`);
    dispatch(GetClientDashboardCount({ status: 0, client_id: clientId }));
    dispatch(GetClientDashboardCount({ status: 4, client_id: clientId }));
    setDashBoardStatus(2);
    setDashBoardCountStatus(2);
    setClientId(clientId);
    setCardStatus(6);
    setclientinfo([{ client_name: clientName }]);
    setDashboardCard(InitiativeCard);
    // setBreadcrumbsData((prevState) => ([
    //     ...prevState, test]));
    // settest({ path: '/Admin/dashboard', PageName: `Client - ${client_name}`, client_id: client_id, DashBoardStatus: 2, DashBoardCountStatus: 2, active: true });
  };

  const ClientDashboard = async (data) => {
    // console.log("obj", data);
    setClientId(data.client_id);
    setclientinfo([{ client_name: data.client_name }]);

    DashBoard.initiative_id.name = data.initiative_name;
    DashBoard.initiative_id.value = data.initiative_id;
    setDashBoardCountStatus(3);

    setDashboardCard(RespondentCard);
    setDashBoard((prevState) => ({
      ...prevState,
    }));
    await dispatch(GetInitiativeDashboardCount(data.initiative_id));
  };

  useEffect(() => {
    if (state.admin) {
      dispatch(GetDashboardCount());
      dispatch(GetClientsDashboard());
      setCardStatus(5);
      setDashBoardStatus(1);
      setDashBoardCountStatus(1);
      setHeader(ClientsHeader);
      setDashboardCard(ClientCard);

      setDashBoard({
        client_id: {
          value: "",
          error: null,
          errmsg: null,
          name: "client_id",
          type: "select",
          placeholder: "Client Name",
          dropdown: [],
        },
        status: {
          name: "status",
          type: "select",
          placeholder: "Status",
          value: "2",
          dropdown: [
            { value: "Ongoing", id: "1" },
            { value: "Completed", id: "2" },
            { value: "Upcoming", id: "0" },
          ],
        },
        initiative_id: {
          name: "",
          value: "0",
        },
      });
    }
  }, [state]);

  return (
    <div>
      <div className="Content_main_width">
        {/* <Breadcrumbs FunctionBreadcrumbsChange={(data) => OnBreadcrumbsClick(data)} breadcrumbs={BreadcrumbsData} /> */}
        <div className="row">
          <div className="col-sm-12">
            <div className="greetings">
              {DashBoardCountStatus === 2 ? (
                <>
                  <span>Client - </span> {clientinfo[0]?.client_name}
                </>
              ) : (
                ""
              )}
              {DashBoardCountStatus === 3 &&
              DashBoard.initiative_id.name !== "" &&
              clientinfo[0] ? (
                <>
                  <div>
                    <span>Client - </span>
                    {clientinfo[0].client_name}
                  </div>
                  <div>
                    <span className="name_font"> Initiative - </span>
                    <span className="name_font">
                      {DashBoard.initiative_id.name || ``}
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="dashboard_div">
          <div className="row">
            {DashboardCard &&
              DashboardCard.map((data) => {
                return (
                  <div className={DashBoardStatus === 1 ? "dash0" : "dash1"}>
                    <div
                      className={`dashcard ${data.className} ${
                        data.cursor && data.cursor === "none"
                          ? "cursor_default"
                          : ""
                      }`}
                      onClick={() => handleColor(data.status)}
                    >
                      <div className="circle-ripple" />

                      <div className="Dashboard_card_title">
                        <div className="card-title">{data.name}</div>
                        <div className="card-subtitle">{data.key}</div>
                      </div>
                      <div className="card_content">
                        <div className="align_card_text">
                          <div className="icon_main_div">
                            <div className="icon_image_size">
                              <img src={data.Icon} alt="" />
                            </div>
                          </div>

                          <div className="number_count">
                            <div className="count">{data.count}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="table_div">
          <div className="row">
            {/* Upcoming || Ongoing || Completed */}
            {DashBoardStatus === 3 ? (
              <EnhancedTable
                Infoheader
                tableMainStatus={
                  CardStatus === 5
                    ? "TotalClient"
                    : CardStatus === 6
                    ? "TotalInitiative"
                    : CardStatus === 4
                    ? "TotalRespondents"
                    : CardStatus === 1
                    ? "Completed"
                    : CardStatus === 2
                    ? "Ongoing"
                    : "Upcoming"
                }
                tabletitle={
                  CardStatus === 5
                    ? "Total Clients"
                    : CardStatus === 6
                    ? "Total Initiatives"
                    : CardStatus === 4
                    ? "Total Respondents"
                    : CardStatus === 1
                    ? "Completed"
                    : CardStatus === 2
                    ? "Ongoing"
                    : "Upcoming"
                }
                headCells={Header}
                headerData={clientinfo[0]}
                rows={
                  DashboardTable.length === 0
                    ? DashboardTable
                    : DashboardTable.getDashboardList
                }
              />
            ) : (
              <EnhancedTable
                header
                search
                // tableSearchItems={[DashBoard.client_id, DashBoard.status]}
                // tableSearchItemsValue={(data) => setTableSearchItemsValue([data])}
                tableMainStatus={
                  CardStatus === 5
                    ? "TotalClient"
                    : CardStatus === 6
                    ? "TotalInitiative"
                    : CardStatus === 4
                    ? "TotalRespondents"
                    : CardStatus === 1
                    ? "Completed"
                    : CardStatus === 2
                    ? "Ongoing"
                    : "Upcoming"
                }
                tabletitle={
                  CardStatus === 5
                    ? "Total Clients"
                    : CardStatus === 6
                    ? "Total Initiatives"
                    : CardStatus === 4
                    ? "Total Respondents"
                    : CardStatus === 1
                    ? "Completed"
                    : CardStatus === 2
                    ? "Ongoing"
                    : "Upcoming"
                }
                headCells={Header}
                rows={
                  DashboardTable.length === 0
                    ? DashboardTable
                    : DashboardTable.getDashboardList
                }
              />
            )}
          </div>
        </div>
        <div className="table_div">
          <div className="row">
            <div
              className="cursor"
              onClick={() => {
                history.push({
                  pathname: "/Admin/all-clients",
                });
              }}
            >
              View All Assesment Leads
            </div>
          </div>
        </div>
        <Modal
          title={<div> Initiative View</div>}
          centered
          visible={InitiativeModelView}
          width={1200}
          zIndex={1201}
          closable
          onCancel={() => setInitiativeModelView(false)}
          footer={[
            <div className="okbtn">
              <CustomButton
                btnName="OK"
                custombtnCSS="PrimaryBtn"
                onBtnClick={() => setInitiativeModelView(false)}
              />
            </div>,
          ]}
        >
          <ViewInitiativeModal initiative_id={InitiativeId} />
        </Modal>
      </div>
    </div>
  );
};
export default Dashboard;
