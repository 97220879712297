import { combineReducers } from 'redux';
import MasterReducer from './MasterReducer';
import QuestionBankReducer from './QuestionBankReducer';
import DashboardReducer from './DashboardReducer';
import ScoreCardReducer from './ScoreCardReducer';
import UserReducer from './UserReducer';
import ManagePackageReducer from './ManagePackageReducer';

export default combineReducers({
  QuestionBankReducer,
  MasterReducer,
  DashboardReducer,
  ScoreCardReducer,
  UserReducer,
  ManagePackageReducer,
});
