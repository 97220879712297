/* eslint-disable default-param-last */
import {
  GET_CLIENT_DASHBOARD_MAIN,
  GET_DASHBOARD,
  GET_CLIENT_DASHBOARD_COUNT,
  GET_CLIENT_DASHBOARD,
  GET_INITIATIVE_DASHBOARD_COUNT,
} from '../utils/Constants';

const initalState = {
  GetInitiativeDashboard: [],
  GetInitiativeDashboardCount: [],
  GetClientDashboardCount: [],
  GetInitiativeCount: [],
  GetClientsDashboard: [],
};

const Dashboard = (state = initalState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DASHBOARD:
      return { ...state, GetInitiativeDashboard: payload };
    case GET_CLIENT_DASHBOARD_MAIN:
      return { ...state, GetClientsDashboard: payload };
    case GET_CLIENT_DASHBOARD_COUNT:
      return { ...state, GetInitiativeDashboardCount: payload };
    case GET_CLIENT_DASHBOARD:
      return { ...state, GetInitiativeDashboard: payload };
    case GET_INITIATIVE_DASHBOARD_COUNT:
      return { ...state, GetInitiativeCount: payload };
    default:
      return state;
  }
};
export default Dashboard;
