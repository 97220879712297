export const videoURL = `${process.env.REACT_APP_INTRO_VIDEO_URL}`;
//AZURE VM Prod
// export const apiurl = 'https://20.125.57.220:8081/api/v1/';

//AZURE VM prod
//export const apiurl = 'https://20.236.30.86:8081/api/v1/';

//AWS VM1
// export const apiurl = "https://e987-49-37-219-239.ngrok-free.app/api/v1/";

//Localhost
// export const apiurl = "http://localhost:8081/api/v1/";

//Server
// export const apiurl = `http://54.183.53.92:8081/api/v1/`;
export const apiurl = `https://portal.alignedaround.com:8081/api/v1/`;
//export const apiurl = 'https://aa-ser.azurewebsites.net/api/v1/';
