/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import completed from "../../images/CompleteIcon.svg";
import ongoing from "../../images/OnGoingIcon.svg";
import upcoming from "../../images/calendar.svg";
import clientIcon from "../../images/client_icon.svg";
import initiativeIcon from "../../images/initiative_icon.svg";
import Invite from "../../images/Invite.svg";
import "./table.scss";
import Labelbox from "../../helpers/labelbox/labelbox";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();

  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

/**
 * @function descendingComparator used for descendingComparator action
 * @param {string} a input value
 * @param {string} b input value
 * @param {string} orderBy input value
 * @returns {string} value
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * @function getComparator used for descendingComparator action
 * @param {string} order input value
 * @param {string} orderBy input value
 * @returns {string} value
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * @function stableSort used for descendingComparator action
 * @param {string} array input value
 * @param {string} comparator input value
 * @returns {string} value
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    EnableSno,
    tableMainStatus,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={`tableHead ${tableMainStatus}`}>
      <TableRow>
        {EnableSno && (
          <TableCell padding="checkbox" className="tableHeadsno">
            S.No
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            // hideSortIcon={headCell.id?true:false}
          >
            {headCell.id ? (
              <TableSortLabel
                active={props.sort && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className={props.sort ? null : classes.nopointer}
                hideSortIcon={orderBy === headCell.id}
                style={{ textAlign: "center" }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tableMainStatus: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  inactiveSortIcon: {
    display: "none",
  },
  CompletedTable: {
    color: "#7400c0",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  OngoingTable: {
    color: "#EA1680",
    alignItems: "right",
    fontSize: "20px",
    fontWeight: 600,
  },
  UpcomingTable: {
    color: "#2ED65D",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  default: {
    color: "#EE8E54",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  BlueTable: {
    color: "#568FE3",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  InitiativeTable: {
    color: "#FF5D55",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  RespondentTable: {
    color: "#FFBE0D",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  NormalTable: {
    color: "black",
  },
  nopointer: {
    cursor: "unset !important",
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const EnhancedTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(
    !props.scorecard ? 5 : props.scorecard
  );
  const [TableStatus, setTableStatus] = React.useState(undefined);
  const [TableDataChange, setTableDataChange] = useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * @function escapeRegExp used for escapeRegExp action
   * @param {string} value input field data
   * @returns {string} key input field name
   */
  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  useEffect(() => {
    if (props.rows) {
      setRows(props.rows);
      setPage(0);
    }
  }, [props.rows]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, index) => {
    const selectedIndex = selected.indexOf(name);
    setSelectedRow(index);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // eslint-disable-next-line radix
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, rows && rows.length - page * rowsPerPage);

  const [searchText, setSearchText] = React.useState("");

  const [TableData, setTableData] = useState({});

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   */
  function checkValidationTableData(data, key) {
    const dynObj = {
      value: data,
    };

    setTableDataChange(!TableDataChange);
    setTableData((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }

  useEffect(() => {
    props.tableSearchItemsValue && props.tableSearchItemsValue(TableData);
  }, [TableDataChange]);

  useEffect(() => {
    if (props.tableSearchItems && props.tableSearchItems.length > 0) {
      props.tableSearchItems.map((data) => {
        const key = data.name;
        const dynObj = {
          value: data.value,
        };

        setTableData((prevState) => ({
          ...prevState,
          [key]: dynObj,
        }));

        return 0;
      });
    } else {
      setTableData({});
    }
  }, [props.tableSearchItems]);

  useEffect(() => {
    if (
      props.tableMainStatus === "Completed" ||
      props.tableMainStatus === "Ongoing" ||
      props.tableMainStatus === "Upcoming" ||
      props.tableMainStatus === "TotalClient" ||
      props.tableMainStatus === "TotalInitiative" ||
      props.tableMainStatus === "TotalRespondents"
    )
      setTableStatus(props.tableMainStatus);
    else setTableStatus(undefined);
  }, [props.tableMainStatus]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows =
      props.rows &&
      props.rows.length > 0 &&
      props.rows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
    setRows(filteredRows);
  };

  return (
    <div className={classes.root}>
      {props.header && (
        <div className="tableIconDiv">
          <div className="container-fluid full_width_div">
            <div className="row">
              <div className="col-sm-4 align-self-center">
                <div className="Title_card">
                  {TableStatus && (
                    <div
                      className={`tableIcon ${
                        props.tableMainStatus === "Completed"
                          ? "tableCompleted"
                          : props.tableMainStatus === "Ongoing"
                          ? "tableOngoing"
                          : props.tableMainStatus === "Upcoming"
                          ? "tableUpcoming"
                          : props.tableMainStatus === "orange_tab"
                          ? "orange_tab"
                          : props.tableMainStatus === "score_tab"
                          ? "score_tab"
                          : props.tableMainStatus === "TotalClient"
                          ? "client_tab"
                          : props.tableMainStatus === "TotalInitiative"
                          ? "initiative_tab"
                          : props.tableMainStatus === "TotalRespondents"
                          ? "respondent_tab"
                          : ""
                      }`}
                    >
                      {" "}
                      <img
                        src={
                          props.tableMainStatus === "Completed"
                            ? completed
                            : props.tableMainStatus === "Ongoing"
                            ? ongoing
                            : props.tableMainStatus === "Upcoming"
                            ? upcoming
                            : props.tableMainStatus === "TotalClient"
                            ? clientIcon
                            : props.tableMainStatus === "TotalInitiative"
                            ? initiativeIcon
                            : props.tableMainStatus === "TotalRespondents"
                            ? Invite
                            : ""
                        }
                        alt=""
                      />
                      {/* className={props.tableMainStatus === "Completed" ? "tableCompleted" : props.tableMainStatus === "Ongoing" ? "tableOngoing" : props.tableMainStatus === "Upcoming" ? "tableUpcoming" : props.tableMainStatus === "orange_tab" ? "orange_tab" :props.tableMainStatus === "score_tab" ? "score_tab" : ''}                                    */}
                    </div>
                  )}
                  <div
                    className={`tableTitle ${
                      props.tableMainStatus === "Completed"
                        ? classes.CompletedTable
                        : props.tableMainStatus === "Ongoing"
                        ? classes.OngoingTable
                        : props.tableMainStatus === "Upcoming"
                        ? classes.UpcomingTable
                        : props.tableMainStatus === "orange_tab"
                        ? classes.default
                        : props.tableMainStatus === "TotalClient"
                        ? classes.BlueTable
                        : props.tableMainStatus === "TotalInitiative"
                        ? classes.InitiativeTable
                        : props.tableMainStatus === "TotalRespondents"
                        ? classes.RespondentTable
                        : classes.NormalTable
                    }`}
                  >
                    {props.tabletitle && props.tabletitle}
                  </div>
                </div>
              </div>
              {props.tableSearchItems ? (
                <div className="col-sm-4 d-flex justify-content-end align-self-center">
                  {props.search && (
                    <div className="tableTitle">
                      <Labelbox
                        type="searchbox"
                        required
                        changeData={(data) => requestSearch(data)}
                        value={searchText}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-sm-8 d-flex justify-content-end align-self-center">
                  {props.search && (
                    <div className="tableTitle">
                      <Labelbox
                        type="searchbox"
                        required
                        changeData={(data) => requestSearch(data)}
                        value={searchText}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="col-sm-4 align-self-center">
                <div className="tableTitle">
                  {props.tableSearchItems &&
                    props.tableSearchItems.length > 0 &&
                    props.tableSearchItems.length === 1 &&
                    props.tableSearchItems.map((propsData) => {
                      return (
                        <div className="col-sm-12">
                          <Labelbox
                            type={propsData.type}
                            dropdown={propsData.dropdown}
                            placeholder={propsData.placeholder}
                            value={propsData.value}
                            changeData={(data) =>
                              checkValidationTableData(data, propsData.name)
                            }
                          />
                        </div>
                      );
                    })}
                  {props.tableSearchItems &&
                    props.tableSearchItems.length > 0 &&
                    props.tableSearchItems.length > 1 &&
                    props.tableSearchItems.map((propsData) => {
                      return (
                        <div className="col-sm-6">
                          <Labelbox
                            type={propsData.type}
                            dropdown={propsData.dropdown}
                            placeholder={propsData.placeholder}
                            value={propsData.value}
                            changeData={(data) =>
                              checkValidationTableData(data, propsData.name)
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.Infoheader && (
        <div className="tableIconDiv">
          <div className="container-fluid full_width_div">
            <div className="row">
              <div className="col-sm-7 align-self-center">
                <div className="Title_card">
                  {TableStatus && (
                    <div
                      className={`tableIcon ${
                        props.tableMainStatus === "Completed"
                          ? "tableCompleted"
                          : props.tableMainStatus === "Ongoing"
                          ? "tableOngoing"
                          : props.tableMainStatus === "Upcoming"
                          ? "tableUpcoming"
                          : props.tableMainStatus === "orange_tab"
                          ? "orange_tab"
                          : props.tableMainStatus === "score_tab"
                          ? "score_tab"
                          : props.tableMainStatus === "TotalClient"
                          ? "client_tab"
                          : props.tableMainStatus === "TotalInitiative"
                          ? "initiative_tab"
                          : props.tableMainStatus === "TotalRespondents"
                          ? "respondent_tab"
                          : ""
                      }`}
                    >
                      {" "}
                      <img
                        src={
                          props.tableMainStatus === "Completed"
                            ? completed
                            : props.tableMainStatus === "Ongoing"
                            ? ongoing
                            : props.tableMainStatus === "Upcoming"
                            ? upcoming
                            : props.tableMainStatus === "TotalClient"
                            ? clientIcon
                            : props.tableMainStatus === "TotalInitiative"
                            ? initiativeIcon
                            : props.tableMainStatus === "TotalRespondents"
                            ? Invite
                            : ""
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div
                    className={`tableTitle ${
                      props.tableMainStatus === "Completed"
                        ? classes.CompletedTable
                        : props.tableMainStatus === "Ongoing"
                        ? classes.OngoingTable
                        : props.tableMainStatus === "Upcoming"
                        ? classes.UpcomingTable
                        : props.tableMainStatus === "orange_tab"
                        ? classes.default
                        : props.tableMainStatus === "TotalClient"
                        ? classes.BlueTable
                        : props.tableMainStatus === "TotalInitiative"
                        ? classes.InitiativeTable
                        : props.tableMainStatus === "TotalRespondents"
                        ? classes.RespondentTable
                        : classes.NormalTable
                    }`}
                  >
                    {props.tabletitle && props.tabletitle}
                  </div>
                </div>
              </div>
              <div className="col-sm-5 d-flex justify-content-end align-self-center">
                <div
                  className={`ClientTitle ${
                    props.tableMainStatus === "Completed"
                      ? "IsComplete"
                      : props.tableMainStatus === "Ongoing"
                      ? "IsOngoing"
                      : props.tableMainStatus === "Upcoming"
                      ? "IsUpcoming"
                      : props.tableMainStatus === "TotalClient"
                      ? "IsClient"
                      : props.tableMainStatus === "TotalInitiative"
                      ? "IsInitiative"
                      : props.tableMainStatus === "TotalRespondents"
                      ? "IsRespondent"
                      : ""
                  }`}
                >
                  {props.headerData && props.headerData.name}
                </div>
                <div
                  className={`ClientInfo ${
                    (props.tableMainStatus && props.tableMainStatus) || ""
                  }`}
                >
                  <div
                    style={{
                      borderRight: "1px solid lightgray",
                      paddingLeft: "40px",
                    }}
                  >
                    {props.headerData && props.headerData.start_date}
                  </div>
                  <div>{props.headerData && props.headerData.end_date}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <TableContainer>
        <Table
          className={`${classes.table} ${props.aligncss}`}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            EnableSno={props.EnableSno}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={props.headCells}
            className={props.aligncss}
            tableMainStatus={props.tableMainStatus}
          />
          <TableBody>
            {rows.length > 0 ? (
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.name);

                  const keys = Object.keys(row);
                  const arrval = [];
                  for (let m = 0; m < keys.length; m++) {
                    if (props.enableID) {
                      // Show SNO
                      console.log("hii", row[keys[m]]);
                      arrval.push(
                        <TableCell keys={`${index}${m}`} align="left">
                          {row[keys[m]] ? row[keys[m]] : "---"}
                        </TableCell>
                      );
                      // arrval.push(
                      //   <TableCell keys={`${index}${m}`} align="left">
                      //     {row[keys[m]] ? row[keys[m]] : "---"}
                      //   </TableCell>
                      // );
                    } else if (keys[m] !== "id") {
                      // Do Not Show SNO
                      arrval.push(
                        <TableCell
                          keys={`${index}${m}`}
                          align="center"
                          style={{
                            maxWidth: "50px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {row[keys[m]] ? row[keys[m]] : "---"}
                        </TableCell>
                      );
                    }
                  }

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.name, row.id)}
                      role="checkbox"
                      aria-checked={row.id === selectedRow}
                      tabIndex={-1}
                      key={index}
                      selected={row.id === selectedRow}
                    >
                      {props.EnableSno && (
                        <TableCell align="center">
                          {rowsPerPage * page + index + 1}
                        </TableCell>
                      )}
                      {arrval}
                    </StyledTableRow>
                  );
                })
            ) : (
              <TableCell colSpan={12} className="nodatafound"></TableCell>
            )}
            {!props.scorecard && rows.length > 0 && emptyRows > 0 && (
              <StyledTableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={12} />
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.scorecard && rows.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 15, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
      {/* </Paper> */}
    </div>
  );
};
export default React.memo(EnhancedTable);
