/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import Button from "@material-ui/core/Button";
import "./button.scss";
import "../../App.css";

const CustomButton = (props) => {
  return (
    <Button
      variant="contained"
      color={props.btnColor}
      disabled={props.btnDisable}
      className={`${
        (props.custombtnCSS === "Success" && "SuccessBtn") ||
        (props.custombtnCSS === "Primary" && "PrimaryBtn") ||
        (props.custombtnCSS === "Warning" && "WarningBtn") ||
        (props.custombtnCSS === "Cancel" && "CancelBtn") ||
        (props.custombtnCSS === "Update" && "UpdateBtn") ||
        (props.custombtnCSS === "Back" && "BackBtn") ||
        props.custombtnCSS
      } btnContainer`}
      onClick={props.onBtnClick}
    >
      {props.needsBolderText ? (
        <b style={{ fontSize: "20px" }}>{props.btnName}</b>
      ) : (
        <>{props.btnName}</>
      )}
    </Button>
  );
};
export default CustomButton;
