/* eslint-disable default-param-last */
import { GET_MANAGE_PACKAGE, GET_MANAGE_INITIATIVE, GET_VIEW_INITIATIVE } from '../utils/Constants';

const initalState = {
  GetManagePackage: [],
  GetManageInitiative: [],
  GetViewInitiative: [],
};

const ManagePackage = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MANAGE_PACKAGE:
      return { ...state, GetManagePackage: payload };
    case GET_MANAGE_INITIATIVE:
      return { ...state, GetManageInitiative: payload };
    case GET_VIEW_INITIATIVE:
      return { ...state, GetViewInitiative: payload };
    default:
      return state;
  }
};
export default ManagePackage;
