/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Labelbox from "../../helpers/labelbox/labelbox";
// import { GetMatrixInitiativeOverall } from "../../actions/ScoreCardAction";
import { updatePWeightRWeightEASoverAll } from "../../actions/DashboardAction";
import { notification } from "antd";

const Weighted = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [P_Weight_Total, setP_Weight_Total] = useState(0);
  const [R_Weight_Total, setR_Weight_Total] = useState(0);
  const [PWeightCalTrigger, setPWeightCalTrigger] = useState(false);
  const [RWeightCalTrigger, setRWeightCalTrigger] = useState(false);
  const [weightTotalTrigger, setWeightTotalTrigger] = useState(false);
  const [weightedData, setWeightedData] = useState([]);
  const [EASDetails, setEASDetails] = useState({
    EAS: 0,
    pEAS: 0,
    iEAS: 0,
    oISS: 0,
  });

  const [WeightedDetails, setWeightedDetails] = useState({
    pillarDetails: [],
    respondentDetails: [],
  });

  const pWeightValue = [
    { id: 0, value: "0" },
    { id: 5, value: "05" },
    { id: 10, value: "10" },
    // { id: 14.3, value: "14.3" },
    { id: 15, value: "15" },
    { id: 20, value: "20" },
    { id: 25, value: "25" },
    { id: 30, value: "30" },
    { id: 35, value: "35" },
    { id: 40, value: "40" },
    { id: 45, value: "45" },
    { id: 50, value: "50" },
    { id: 55, value: "55" },
    { id: 60, value: "60" },
    { id: 65, value: "65" },
    { id: 70, value: "70" },
    { id: 75, value: "75" },
    { id: 80, value: "80" },
    { id: 85, value: "85" },
    { id: 90, value: "90" },
    { id: 95, value: "95" },
    { id: 100, value: "100" },
  ];
  const [weighted, setWeighted] = useState({
    p_weight_1: "",
    p_weight_2: "",
    p_weight_3: "",
    p_weight_4: "",
    p_weight_5: "",
    p_weight_6: "",
    p_weight_7: "",
  });
  const state = location.state || "";

  const legend = [
    { title: "EAS", description: "Executive Alignment Score." },
    {
      title: "P-EAS",
      description: "Pillar Weighted Executive Alignment Score.",
    },
    {
      title: "I-EAS",
      description: "Impact (Respondent) Weighted Executive Alignment Score.",
    },
    {
      title: "OSS",
      description: "Overall Success Score.",
    },
  ];
  useEffect(() => {
    if (weightedData.length > 0) {
      setP_Weight_Total(pWeightCalculation());
      setR_Weight_Total(rWeightCalculation());
    }
  }, [weightTotalTrigger]);

  const onResetClick = () => {
    getWeightedData(weightedData);
  };

  const pWeightCalculation = () => {
    /** *************** p weight *****************/
    const pWeightTot = [];
    Object.keys(weighted).map((data) => {
      if (data.slice(0, 8) === "p_weight") {
        pWeightTot.push(data);
      }
      return 0;
    });
    const totalPweight = pWeightTot.map((val) => {
      return weighted[val];
    });
    const PW = totalPweight.reduce((a, b) => {
      return Number(a) + Number(b);
    });
    return PW;
  };

  const rWeightCalculation = () => {
    const rWeightTot = [];
    Object.keys(weighted).map((data) => {
      if (data.slice(0, 8) === "r_weight") {
        rWeightTot.push(data);
      }
      return 0;
    });
    const totalRweight = rWeightTot.map((val) => {
      return weighted[val];
    });
    console.log("RW Calculation ......." + totalRweight);
    try {
      const RW = totalRweight.reduce((a, b) => {
        return Number(a) + Number(b);
      });
      return RW;
    } catch (err) {
      console.log("RW Calculation err......." + err);
      return 0;
    }
  };

  const onUpdateClick = () => {
    ///** *************** p weight *****************///
    const PW = pWeightCalculation();
    if (Number(Math.round(PW)) !== 100) {
      notification.warning({
        message: `P-Weight must total to 100%.`,
      });
      return;
    }

    /** *************** r weight */

    const RW = rWeightCalculation();
    if (Number(Math.round(RW)) !== 100) {
      notification.warning({
        message: `I-Weight must total to 100%`,
      });
      return;
    }
    const respArr = [];
    WeightedDetails.respondentDetails.map(
      (respDetailsData, respDetailsIndex) => {
        respArr.push({
          respondent_id: respDetailsData.respondent_id,
          r_weight: weighted[`r_weight_${respDetailsIndex + 1}`],
        });
        return true;
      }
    );
    const pillarArr = [];
    WeightedDetails.pillarDetails.map(
      (pillarDetailsData, pillarDetailsIndex) => {
        pillarArr.push({
          pillar_id: pillarDetailsData.pillar_id,
          p_weight: weighted[`p_weight_${pillarDetailsIndex + 1}`],
        });
        return true;
      }
    );

    dispatch(
      updatePWeightRWeightEASoverAll({
        initiativeId: state?.params?.id,
        pillarArr,
        respArr,
        EASDetails,
      })
    );
  };

  useEffect(() => {
    const arrTot = [];
    Object.keys(weighted).map((data) => {
      if (data.slice(0, 9) === "r_weight_") {
        arrTot.push(data);
      }
      return 0;
    });
    const totalRweight = arrTot.map((val) => {
      return weighted[val];
    });

    const RW = (totalRweight.length === 0 ? [0] : totalRweight).reduce(
      function (a, b) {
        return Number(a) + Number(b);
      }
    );

    Math.round(RW);
    setR_Weight_Total(RW);
  }, [RWeightCalTrigger]);

  useEffect(() => {
    const arrTot = [];
    Object.keys(weighted).map((data) => {
      if (data.slice(0, 8) === "p_weight") {
        arrTot.push(data);
      }
      return 0;
    });
    const totalPweight = arrTot.map((val) => {
      return weighted[val];
    });
    const PW = totalPweight.reduce(function (a, b) {
      return Number(a) + Number(b);
    });
    setP_Weight_Total(Math.round(PW));
  }, [PWeightCalTrigger]);

  const PEASCalculation = () => {
    const pEAS = (
      (parseFloat(weighted?.pillar_mean_1 * weighted.p_weight_1) +
        parseFloat(weighted?.pillar_mean_2 * weighted.p_weight_2) +
        parseFloat(weighted?.pillar_mean_3 * weighted.p_weight_3) +
        parseFloat(weighted?.pillar_mean_4 * weighted.p_weight_4) +
        parseFloat(weighted?.pillar_mean_5 * weighted.p_weight_5) +
        parseFloat(weighted?.pillar_mean_6 * weighted.p_weight_6) +
        parseFloat(weighted?.pillar_mean_7 * weighted.p_weight_7)) /
      100
    ).toFixed(2);
    setEASDetails((prevState) => ({
      ...prevState,
      pEAS,
    }));
  };
  const IEASCalculation = () => {
    const respArr = [];
    WeightedDetails.respondentDetails.map((data, respDetailsIndex) => {
      respArr.push(
        weighted[`r_weight_${respDetailsIndex + 1}`] *
          weighted[`respondent_mean_${respDetailsIndex + 1}`]
      );
      return true;
    });

    const iEAS = (respArr.reduce((a, b) => a + Number(b), 0) / 100).toFixed(2);
    setEASDetails((prevState) => ({
      ...prevState,
      iEAS,
    }));
  };

  /** ******* PAYLOAD */
  const Result = useSelector((Selectorstate) => Selectorstate.ScoreCardReducer);

  const getWeightedData = (weightedData) => {
    weightedData.map((data, index) => {
      if (index === 3) {
        setWeightedDetails((prevState) => ({
          ...prevState,
          respondentDetails: data.respondent_details,
          pillarDetails: data.pillar_details,
        }));
        setEASDetails((prevState) => ({
          ...prevState,
          EAS: data.eas,
          oISS: data.o_iss,
        }));
        data.respondent_overall_mean.map((resData, resIndex) => {
          setWeighted((prevState) => ({
            ...prevState,
            [`respondent_mean_${resIndex + 1}`]: resData.overAllMean,
          }));

          return true;
        });
        data.respondent_details.map((respDeatailsData, respDetailsIndex) => {
          setWeighted((prevState) => ({
            ...prevState,
            [`r_weight_${respDetailsIndex + 1}`]: respDeatailsData.r_weight,
          }));

          return true;
        });

        data.respondent_overall_mean_pillar.map(
          (respMeanPillarData, respMeanPillarIndex) => {
            setWeighted((prevState) => ({
              ...prevState,
              [`pillar_mean_${respMeanPillarIndex + 1}`]: respMeanPillarData,
            }));

            return true;
          }
        );

        data.pillar_details.map((pillarDetailsData, pillarDetailsIndex) => {
          setWeighted((prevState) => ({
            ...prevState,
            [`p_weight_${pillarDetailsIndex + 1}`]:
              pillarDetailsData.pillar_weight,
          }));

          return true;
        });
      }
      return true;
    });
    setWeightTotalTrigger(!weightTotalTrigger);
  };
  useEffect(async () => {
    getWeightedData(Result.GetMatrixInitiativeOverall);
    setWeightedData(Result.GetMatrixInitiativeOverall);
  }, [Result.GetMatrixInitiativeOverall]);

  useEffect(() => {
    // if (props.showEdit) {
    PEASCalculation();
    IEASCalculation();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weighted]);

  const likertValue = (data) => {
    switch (data) {
      case 1:
        return "Strongly Disagree";
      case 2:
        return "Disagree";
      case 3:
        return "Somewhat Disagree";
      case 4:
        return "Neutral";
      case 5:
        return "Somewhat Agree";
      case 6:
        return "Agree";
      case 7:
        return "Strongly Agree";
      default:
        return false;
    }
  };
  /**
   * @param {string} data input data
   * @param {string} key input field name
   */
  function checkValidation(data, key) {
    setPWeightCalTrigger(!PWeightCalTrigger);
    setRWeightCalTrigger(!RWeightCalTrigger);
    setWeighted((prevState) => ({
      ...prevState,
      [key]: data,
    }));
  }

  return (
    <div className="row d-flex justify-content-around">
      <div className="chart-col-3">
        <div>
          <div className="alignscore c1">
            <div className="alignWave w1">Weighted Alignment</div>
            <div>
              <div className="horizontalGrid">
                <div className="group">Pillar</div>
                <div className="group" id="sec-col">
                  P-Weight
                </div>
                <div className="group" id="sec-col">
                  Respondent Name
                </div>
                <div className="group" id="sec-col">
                  I-Weight
                </div>
                <div>
                  {WeightedDetails?.pillarDetails.map((item, index) => {
                    return (
                      <div>
                        <div
                          className={`col1 ${
                            index === 10 ? "chart_border" : ""
                          }`}
                        >
                          {index !== 10 ? item.pillar_name : ""}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="" id="initiative-group">
                  {WeightedDetails?.pillarDetails.map((item, index) => {
                    return (
                      <div>
                        <div className={`col2 p-wt-row`}>
                          <Labelbox
                            type="select"
                            dropdown={pWeightValue}
                            value={weighted[`p_weight_${index + 1}`]}
                            changeData={(e) =>
                              checkValidation(e, `p_weight_${index + 1}`)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className={`col2 chart_border`}>{P_Weight_Total}</div>
                </div>
                <div className="" id="initiative-group">
                  {WeightedDetails?.respondentDetails.map((item, index) => {
                    return (
                      <div>
                        {index !== 10 && (
                          <div
                            className={`col2 ${index === 10 ? "" : ""}`}
                            style={{ textAlign: "left" }}
                          >
                            {item.respondent_name || "-"}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="" id="initiative-group">
                  {WeightedDetails?.respondentDetails.map((item, index) => {
                    return (
                      <div>
                        <div className={`col2 p-wt-row`}>
                          <Labelbox
                            type="select"
                            dropdown={pWeightValue}
                            value={weighted[`r_weight_${index + 1}`]}
                            changeData={(e) =>
                              checkValidation(e, `r_weight_${index + 1}`)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className={`col2 chart_border`}>{R_Weight_Total}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-col-9 rightDescription">
        <div>
          <div className="wt-footer">
            <div />
            <div>
              <div className="wt-footer-head">
                <div>
                  EAS <br /> (Unweighted)
                </div>
                <div>
                  P-EAS <br /> (Weighted)
                </div>
                <div>
                  I-EAS <br /> (Weighted)
                </div>
                <div>
                  OSS <br /> (Unweighted)
                </div>
              </div>
              <div className="wt-footer-content">
                <div>{EASDetails.EAS}</div>
                <div>{EASDetails.pEAS}</div>
                <div>{EASDetails.iEAS}</div>
                <div>{EASDetails.oISS}</div>
              </div>
              <div className="wt-footer-foot">
                <div>
                  {likertValue(Number(Number(EASDetails.EAS).toFixed()))}
                </div>
                <div>
                  {likertValue(Number(Number(EASDetails.pEAS).toFixed()))}
                </div>
                <div>
                  {likertValue(Number(Number(EASDetails.iEAS).toFixed()))}
                </div>
                <div>
                  {likertValue(Number(Number(EASDetails.oISS).toFixed()))}
                </div>
              </div>
            </div>
            <div className="wt-foot-btn">
              <div>
                <button className="wt-foot-editbtn" onClick={onUpdateClick}>
                  Update
                </button>
                <button className="wt-foot-resetbtn" onClick={onResetClick}>
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div>
            {legend.map((data) => {
              return (
                <div className="legendContainerAlignment">
                  <div>{data.title + ": " + data.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Weighted);
