/* eslint-disable default-param-last */
import { GET_PILLARS, GET_CLIENTS, GET_PACKAGES, GET_DASHBOARD_COUNT } from '../utils/Constants';

const initalState = {
  GetPillars: [],
  GetClients: [],
  GetPackages: [],
  GetDashboardCount: [],
};

const Masterdropdown = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PILLARS:
      return { ...state, GetPillars: payload };
    case GET_CLIENTS:
      return { ...state, GetClients: payload };
    case GET_PACKAGES:
      return { ...state, GetPackages: payload };
    case GET_DASHBOARD_COUNT:
      return { ...state, GetDashboardCount: payload };
    default:
      return state;
  }
};
export default Masterdropdown;
