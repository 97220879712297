/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { useDispatch, useSelector } from "react-redux";
import { notification, Modal } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import EnhancedTable from "../../component/DynTable/table";
import Labelbox from "../../helpers/labelbox/labelbox";
import CustomButton from "../../component/Buttons/button";
import Vision from "../../images/vision.svg";
import Strategy from "../../images/strategy.svg";
import Success from "../../images/success.svg";
import Leader from "../../images/leader.svg";
import Accountability from "../../images/account.svg";
import Investment from "../../images/invest.svg";
import Advocacy from "../../images/advocacy.svg";
import Sentiment from "../../images/emotion.svg";
import "./package.scss";
import { GetQuestionBank } from "../../actions/QuestionBankAction";
import ValidationLibrary from "../../helpers/ValidationFunction/validationfunction";
import {
  GetPackages,
  GetClients,
  GetPillars,
} from "../../actions/MasterAction";
import { InsertManagePackage } from "../../actions/ManagePackageAction";
import { apiurl } from "../../utils/baseUrl";
import { GET_MANAGE_PACKAGE, GET_QUESTION_BANK } from "../../utils/Constants";

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: "#EE8E54 !important",
    width: "110px",
    padding: "1%",
    borderRadius: "4px",
  },
  active_content: {
    backgroundColor: "#26D65F !important",
    width: "110px",
    padding: "1%",
    borderRadius: "4px",
  },
  blue_content: {
    backgroundColor: "#204768 !important",
    width: "110px",
    padding: "1%",
    borderRadius: "4px",
  },
}));

const ManagePackage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [PackageChange, setPackageChange] = useState(false);
  const [MasterDropdows, setMasterDropdows] = useState([]);
  const [QuestionBankTable, setQuestionBankTable] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const count = 0;
  const [Load, setLoad] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [TableItems, setTableItems] = useState([
    [[], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], []],
  ]);
  const [SaveButton, setSaveButton] = useState(true);
  const Icon = [
    Vision,
    Strategy,
    Success,
    Leader,
    Accountability,
    Investment,
    Advocacy,
    Sentiment,
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [PrevPackageId, setPrevPackageId] = React.useState(0);
  const completed = {};
  const numTowords = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
    "twenty",
  ];
  const [ManagePackage, setManagePackage] = useState({
    package_id: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    package_qtn_count: {
      value: "0",
      error: null,
      errmsg: null,
    },
    pillar_id: {
      value: "0",
      error: null,
      errmsg: null,
    },
    current_manage_package_id: {
      value: "0",
      error: null,
      errmsg: null,
    },
  });

  /** *Stepper */
  const [steps, setsteps] = React.useState([]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    var n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] !== 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] !== 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] !== 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] !== 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] !== 0
        ? (str !== "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
        : "";
    return str;
  }

  const handleNext = async (index) => {
    if (ManagePackage.package_id.value === "") {
      notification.warning({
        message: `Please select either Lightweight or Comprehensive or Lite assessment type.`,
      });
      return;
    }

    if (index || index === 0) {
      setActiveStep(index);
      dispatch(
        GetQuestionBank(index + 1, 1, {
          package_id: ManagePackage.package_id.value,
          pillar_id: 1,
        })
      );
    } else {
      if (
        activeStep < 7 &&
        TableItems[1][activeStep].length !==
          ManagePackage.package_qtn_count.value
      ) {
        notification.warning({
          message: `Please choose ${
            numTowords[ManagePackage.package_qtn_count.value]
          } assessment statement${
            ManagePackage.package_qtn_count.value === 1 ? "" : "s"
          }`,
        });
        return;
      }
      if (activeStep === 7 && TableItems[1][activeStep].length === 0) {
        notification.warning({
          message: `Please choose one assessment statement.`,
        });
        return;
      }

      dispatch(
        GetQuestionBank(activeStep + 2, 1, {
          package_id: ManagePackage.package_id.value,
          pillar_id: activeStep + 2,
        })
      );

      steps[activeStep + 1].checked = true;

      if (!index && steps.length !== activeStep + 1) {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
      }
    }
  };

  const OpenModel = () => {
    if (ManagePackage.package_id.value === "") {
      notification.warning({
        message: `Please select either Lightweight or Comprehensive assessment type.`,
      });
      return;
    }
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handlePackageOK = async () => {
    steps.map((data) => {
      data.checked = false;
      return true;
    });
    setQuestionBankTable([[], [], [], [], [], [], [], []]);
    dispatch(await GetQuestionBank(1, 1));
    axios({
      method: "post",
      url: `${apiurl}get_manage_package_by_packageId_pillarId`,
      data: {
        package_id: ManagePackage.package_id.value,
        pillar_id: 1,
      },
    }).then(async (response) => {
      if (response.data.status === 1) {
        setTableItems([
          [[], [], [], [], [], [], [], []],
          [[], [], [], [], [], [], [], []],
        ]);

        if (response.data.data.length > 0) {
          setEdit(true);
          dispatch({ type: GET_MANAGE_PACKAGE, payload: response.data.data });
        } else {
          setEdit(false);
        }
      }
    });
    setActiveStep(0);
    setPackageChange(false);
  };

  const MasterData = useSelector((state) => state.MasterReducer);
  const QuestionBankData = useSelector((state) => state.QuestionBankReducer);
  const GetManagePackageData = useSelector(
    (state) => state.ManagePackageReducer.GetManagePackage
  );

  const handlePackageCancel = async () => {
    setPackageChange(false);
    const packageData = MasterData?.GetPackages?.find((val) => {
      return PrevPackageId === val.package_id;
    });
    ManagePackage.package_qtn_count.value = packageData.statement_count;
    ManagePackage.package_id.value = PrevPackageId;
    setManagePackage((prevState) => ({
      ...prevState,
    }));
  };
  /** */

  const header = [
    { id: "sno", label: "No." },
    { id: "statement", label: "Statement" },
  ];

  /**
   * @function handleCheck used for handleCheck action for statement selection
   * @param {string} e input field data
   * @param {string} index input field position
   * @param {number} id input statement id
   */
  function handleCheck(e, index, id) {
    if (e.target.checked) {
      if (
        activeStep < 7 &&
        TableItems[1][activeStep].length >
          ManagePackage.package_qtn_count.value - 1
      ) {
        notification.warning({
          message: `Please choose ${
            numTowords[ManagePackage.package_qtn_count.value]
          } assessment statement${
            ManagePackage.package_qtn_count.value === 1 ? "" : "s"
          }`,
        });
        return;
      }
      if (activeStep === 7 && TableItems[1][activeStep].length > 0) {
        notification.warning({
          message: `Please choose one assessment statement.`,
        });
        return;
      }

      QuestionBankTable[activeStep][index].checked = true;
      QuestionBankTable[activeStep][index].pillars_id = activeStep + 1;
      if (Edit) {
        QuestionBankTable[activeStep][index].manage_package_id =
          ManagePackage.current_manage_package_id.value;
      }

      TableItems[1][activeStep].push(QuestionBankTable[activeStep][index]);
      TableItems[0][activeStep].push({
        id: TableItems[0][activeStep].length + 1,
        question: QuestionBankTable[activeStep][index].question,
      });
    } else {
      QuestionBankTable[activeStep][index].checked = false;
      let newIndex;
      TableItems[1][activeStep].find((val, i) => {
        newIndex = i;
        return id === val.qus_bank_id;
      });

      TableItems[1][activeStep].splice(newIndex, 1);
      TableItems[0][activeStep].splice(newIndex, 1);
      const updatedTable = [];
      TableItems[0][activeStep].map((data, index) => {
        updatedTable.push({ id: index + 1, question: data.question });
        return 0;
      });

      TableItems[0][activeStep] = updatedTable;
    }
    setTableItems((prevState) => [...prevState]);
    setQuestionBankTable((prevState) => ({
      ...prevState,
    }));
  }
  /// /////**** API DATA PROCESS******////////////

  useEffect(async () => {
    dispatch(GetClients());
    dispatch(GetPackages());
    dispatch(GetPillars());

    await axios({
      method: "post",
      url: `${apiurl}get_question_bank`,
      data: {
        pillars_id: 1,
        status: 1,
      },
    }).then((response) => {
      if (response.data.status === 1) {
        setLoad(true);
        dispatch({ type: GET_QUESTION_BANK, payload: response.data.data });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const getClientsList = [];
    MasterData.GetClients.map((data) => {
      getClientsList.push({ id: data.client_id, value: data.client_name });
      return 0;
    });
    const getPackagesList = [];
    MasterData?.GetPackages?.map((data) => {
      getPackagesList.push({ id: data.package_id, value: data.package_name });
      return 0;
    });
    setMasterDropdows({ getClientsList, getPackagesList });
  }, [MasterData]);

  useEffect(() => {
    const pillarList = [];
    MasterData.GetPillars.map((data, index) => {
      pillarList.push({ name: data.pillar_name, checked: index === 0 });
      return 0;
    });
    setsteps(pillarList);
  }, [MasterData.GetPillars]);

  useEffect(() => {
    if (Load && !QuestionBankTable[activeStep].length > 0 && QuestionBankData) {
      const GetQuestionBankList = [];
      QuestionBankData.GetQuestionBank.map((data) => {
        GetQuestionBankList.push({
          qus_bank_id: data.qus_bank_id,
          question: data.question,
          checked: false,
        });
        return 0;
      });
      QuestionBankTable[activeStep] = GetQuestionBankList;
      setQuestionBankTable((prevState) => ({
        ...prevState,
      }));
    }
  }, [QuestionBankData]);

  useEffect(() => {
    if (
      ManagePackage.package_id.value !== "" &&
      TableItems[0][activeStep].length === 0 &&
      GetManagePackageData
    ) {
      GetManagePackageData.map((data, index) => {
        let newIndex;
        QuestionBankTable[activeStep].find((val, i) => {
          newIndex = i;
          return data.qus_bank_id === val.qus_bank_id;
        });

        QuestionBankTable[activeStep][newIndex] &&
          (QuestionBankTable[activeStep][newIndex].checked = true);
        setQuestionBankTable((prevState) => ({
          ...prevState,
        }));
        setManagePackage((prevState) => ({
          ...prevState,
          current_manage_package_id: { value: data.manage_package_id },
        }));
        TableItems[1][activeStep].push({
          manage_package_id: data.manage_package_id,
          pillars_id: data.pillar_id,
          qus_bank_id: data.qus_bank_id,
        });
        TableItems[0][activeStep].push({
          id: index + 1,
          question: data.question,
        });
        return 0;
      });

      setTableItems((prevState) => [...prevState]);
    }
  }, [GetManagePackageData]);
  /// //////////////

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   */
  async function checkValidation(data, key) {
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      ManagePackage[key].validation
    );
    const dynObj = {
      value: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
      validation: ManagePackage[key].validation,
    };
    if (key === "package_id") {
      if (TableItems[0][0].length > 0) {
        setPackageChange(true);
        setPrevPackageId(ManagePackage.package_id.value);
        // notification.success({
        //     message: `You want to change package type ?`,
        // });
      } else {
        axios({
          method: "post",
          url: `${apiurl}get_manage_package_by_packageId_pillarId`,
          data: {
            package_id: data,
            pillar_id: 1,
          },
        }).then(async (response) => {
          if (response.data.status === 1) {
            if (response.data.data.length > 0) {
              setEdit(true);
              dispatch({
                type: GET_MANAGE_PACKAGE,
                payload: response.data.data,
              });
            } else {
              dispatch(await GetQuestionBank(1, 1));
              setEdit(false);
              setTableItems([
                [[], [], [], [], [], [], [], []],
                [[], [], [], [], [], [], [], []],
              ]);
              setQuestionBankTable([[], [], [], [], [], [], [], []]);
            }
          }
        });
      }
      const packageData = MasterData?.GetPackages?.find((val) => {
        return data === val.package_id;
      });
      ManagePackage.package_qtn_count.value = packageData.statement_count;
    }
    setManagePackage((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }

  /**
   *
   */
  async function onSubmit() {
    const mainvalue = {};
    const targetkeys = Object.keys(ManagePackage);
    for (const i in targetkeys) {
      const errorcheck = ValidationLibrary.checkValidation(
        ManagePackage[targetkeys[i]].value,
        ManagePackage[targetkeys[i]].validation
      );
      ManagePackage[targetkeys[i]].error = !errorcheck.state;
      ManagePackage[targetkeys[i]].errmsg = errorcheck.msg;
      mainvalue[targetkeys[i]] = ManagePackage[targetkeys[i]].value;
    }
    const filtererr = targetkeys.filter(
      (obj) => ManagePackage[obj].error === true
    );

    if (filtererr.length > 0) {
    } else {
      setSaveButton(false);
      await dispatch(InsertManagePackage(ManagePackage, TableItems[1], Edit));
      setSaveButton(true);
      setEdit(false);
      history.push("/Admin/ManageInitiative");
    }
    setManagePackage((prevState) => ({
      ...prevState,
    }));
  }

  return (
    <div>
      <div className="Content_main_width">
        <div className="row">
          <div className="col-sm-4">
            <div className="TitlePane">Manage Assessment</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="ques_bank_inputs">
              <div className="heading">Assessment Type</div>
              <Labelbox
                type="select"
                dropdown={MasterDropdows.getPackagesList}
                changeData={(data) => checkValidation(data, "package_id")}
                value={ManagePackage.package_id.value}
                error={ManagePackage.package_id.error}
                errmsg={ManagePackage.package_id.errmsg}
              />
            </div>
          </div>
        </div>

        {/* ////////// Stepper ////////////// */}
        {count === 0 && (
          <div className="stepper">
            <Box sx={{ width: "100%" }}>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((data, index) => {
                  return (
                    <Step
                      key={data.name}
                      onClick={() => steps[index].checked && handleNext(index)}
                      className={
                        index === activeStep
                          ? classes.active_content
                          : data.checked
                          ? classes.content
                          : classes.blue_content
                      }
                    >
                      <div className="step_img">
                        <img src={Icon[index]} alt="" />
                      </div>
                      <div className="step_name">{data.name}</div>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </div>
        )}

        {/* ////////// Pillar Statement ////////////// */}
        {count === 0 && (
          <div className="row">
            <div className="col-sm-12 manage-table">
              <div className="select-ques" onClick={() => OpenModel()}>
                <div id="plus">+</div>
                <div>Select Statement</div>
              </div>
              <EnhancedTable
                tableMainStatus="orange_tab"
                headCells={header}
                rows={TableItems[0][activeStep]}
                aligncss="mp-table"
                enableID
              />
            </div>
            <div className="col-sm-12 d-flex justify-content-end">
              <div className="next_btn">
                {activeStep === 7 && (
                  <CustomButton
                    btnName={Edit ? "UPDATE" : "SAVE"}
                    btnDisable={!SaveButton}
                    custombtnCSS="Primary"
                    onBtnClick={() => onSubmit()}
                  />
                )}
                {activeStep !== 7 && (
                  <CustomButton
                    btnName="NEXT"
                    custombtnCSS="Success"
                    onBtnClick={() => handleNext()}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {/* ///////////////////////// */}

        {/** ** Pop-Up */}
        <Modal
          title={
            <div>
              <span id="modal">
                {steps.length > 0 && steps[activeStep].name}
              </span>{" "}
              Select Statement
            </div>
          }
          centered
          visible={visible}
          width={1000}
          zIndex={1201}
          closable
          onCancel={() => setVisible(false)}
          footer={[
            <div className="okbtn">
              <CustomButton
                btnName="OK"
                custombtnCSS="Success"
                onBtnClick={handleOk}
              />
            </div>,
          ]}
        >
          <div>
            <div className="statements">
              {QuestionBankTable[activeStep]?.map((data, index) => {
                return (
                  <div className="state">
                    <Labelbox
                      type="checkbox"
                      changeData={(e) =>
                        handleCheck(e, index, data.qus_bank_id)
                      }
                      checked={data.checked}
                      label={data.question}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
        <Modal
          title={<div> Assessment Type Change</div>}
          centered
          visible={PackageChange}
          width={500}
          zIndex={1201}
          closable
          onCancel={handlePackageCancel}
          footer={[
            <div className="okbtn">
              <CustomButton
                btnName="OK"
                custombtnCSS="Success"
                onBtnClick={handlePackageOK}
              />
            </div>,
          ]}
        >
          <div className="package_change">
            Do you want to change Assessment Type ?
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ManagePackage;
