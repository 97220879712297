/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import axios from "axios";
import moment from "moment";
import { notification } from "antd";
import {
  GET_MANAGE_PACKAGE,
  GET_MANAGE_INITIATIVE,
  GET_VIEW_INITIATIVE,
} from "../utils/Constants";
import { apiurl } from "../utils/baseUrl";

export const InsertManagePackage = (data, statement, Edit) => async () => {
  const statement_arr = [];
  let insert_data = {};
  let update_data = {};
  statement.map((statementArr) => {
    statementArr.map((item) => {
      statement_arr.push(item);
      return 0;
    });
    return 0;
  });
  try {
    insert_data = {
      package_id: data.package_id.value,
      qus_bank_id: statement_arr,
      created_on: moment().format("YYYY-MM-DD"),
      created_by: localStorage.getItem("user_id"),
    };
    update_data = {
      manage_package: statement_arr,
    };

    await axios({
      method: "post",
      url: apiurl + (Edit ? "update_manage_package" : "insert_manage_package"),
      data: Edit ? update_data : insert_data,
    }).then((response) => {
      if (response.data.status === 1) {
        notification.success({
          message: `Initiative package ${Edit ? "updated." : "created."}`,
        });
      }
    });
  } catch (err) {}
};

export const GetManagePackageOld =
  (pillar_id, package_id) => async (dispatch) => {
    try {
      axios({
        method: "post",
        url: `${apiurl}get_manage_package_by_packageId_pillarId`,
        data: {
          package_id,
          pillar_id,
        },
      }).then((response) => {
        dispatch({ type: GET_MANAGE_PACKAGE, payload: response.data.data });
      });
    } catch (err) {}
  };

export const GetManagePackage = (package_id) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_manage_package_by_package_pillar_ID`,
      data: {
        package_id,
      },
    }).then((response) => {
      dispatch({ type: GET_MANAGE_PACKAGE, payload: response.data.data });
    });
  } catch (err) {}
};

export const Respondent_mail_resend = (respondent_id) => async () => {
  try {
    axios({
      method: "post",
      url: `${apiurl}respondent_mail_resend`,
      data: {
        respondent_id,
        client_id: JSON.parse(localStorage.getItem("client_id")),
      },
    }).then((response) => {
      if (response.data.status === 1) {
        notification.success({
          message: `Respondent email resent.`,
        });
      } else if (response.data.status === 0) {
        notification.success({
          message: response.data.msg,
        });
      }
    });
  } catch (err) {
    notification.success({
      message: `An application error occurred while resending the respondent email. Please contact the AlignedAround support team.`,
    });
  }
};

export const GetManageInitiative = (initiative_id) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_manage_initiative_details`,
      data: {
        initiative_id,
      },
    }).then((response) => {
      dispatch({ type: GET_MANAGE_INITIATIVE, payload: response.data.data });
    });
  } catch (err) {}
};
export const InsertManageInitiative =
  (ManageInitiative, statement) => async () => {
    const statement_arr = [];
    statement.map((data, index) => {
      const p_weight = ManageInitiative[`p_weight_${index + 1}`]?.value;
      data.rows.map((item) => {
        item.p_weight = p_weight;
        statement_arr.push(item);
        return 0;
      });
      return 0;
    });

    const respondents_arr = [];
    Array.from({ length: ManageInitiative.no_of_respondents.value }, (x, i) => {
      i += 1;
      const resp_name = `resp_name_${i}`;
      // const resp_weight = `resp_weight_${i}`;
      const resp_role = `resp_role_${i}`;
      const resp_email = `resp_email_${i}`;
      respondents_arr.push({
        respondent_name: ManageInitiative[resp_name].value,
        resp_mail: ManageInitiative[resp_email].value,
        resp_role_: ManageInitiative[resp_role].value,
        r_weight: 0,
      });
      return 0;
    });
    // let reminder_mail_date = "";
    // if (ManageInitiative.reminder_mail_date.value.length > 0) {
    //   reminder_mail_date = ManageInitiative.reminder_mail_date.value.map(
    //     (dt) => {
    //       return dt.format("YYYY-MM-DD");
    //     }
    //   );
    // }

    try {
      await axios({
        method: "post",
        url: `${apiurl}insert_manage_initiative`,
        data: {
          package_id: ManageInitiative.package_id.value,
          client_id: ManageInitiative.client_id.value,
          start_date: moment(ManageInitiative.start_date.value).format(
            "YYYY-MM-DD"
          ),
          end_date: moment(ManageInitiative.end_date.value).format(
            "YYYY-MM-DD"
          ),
          no_of_respondents: ManageInitiative.no_of_respondents.value,
          initiative_name: ManageInitiative.initiative_name.value,

          initiative_description: `'${ManageInitiative.initiative_description.value}'`,
          initiative_stage: ManageInitiative.initiative_stage.value,
          initiative_scope: ManageInitiative.initiative_scope.value,
          /// /
          statement: statement_arr,
          mail: respondents_arr,
          welcome_message: ManageInitiative.salutation.value,
          date_of_mail: moment(ManageInitiative.date_of_mail.value).format(
            "YYYY-MM-DD"
          ),
          reminder_mail_date: ManageInitiative.reminder_mail_date.value,
          reminder_content: ManageInitiative.reminder_content.value,
          invite_content: ManageInitiative.invite_content.value,
          ///
          created_on: moment().format("YYYY-MM-DD"),
          created_by: localStorage.getItem("user_id"),
        },
      }).then(async (response) => {
        if (response.data.status === 1) {
          notification.success({
            message: `Client initiative created.`,
          });
        }
      });
    } catch (err) {
      notification.success({
        message: `An application error occurred while creating the initiative. Please contact the AlignedAround support team.`,
      });
    }
  };
export const GetViewInitiative = (data) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_view_details_by_initiative_id`,
      data: {
        initiative_id: data,
      },
    }).then((response) => {
      dispatch({ type: GET_VIEW_INITIATIVE, payload: response.data.data });
    });
  } catch (err) {}
};
