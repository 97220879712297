/* eslint-disable no-empty */
import axios from 'axios';
import { GET_PILLARS, GET_CLIENTS, GET_PACKAGES, GET_DASHBOARD_COUNT } from '../utils/Constants';
import { apiurl } from '../utils/baseUrl';

export const GetPillars = () => async (dispatch) => {
  const response = await axios.get(`${apiurl}/get_pillars`);
  return dispatch({ type: GET_PILLARS, payload: response.data.data });
};

export const GetClients = () => async (dispatch) => {
  const response = await axios.get(`${apiurl}/get_clients`);
  return dispatch({ type: GET_CLIENTS, payload: response.data.data });
};

export const GetPackages = (status) => async (dispatch) => {
  try {
    axios({
      method: 'post',
      url: `${apiurl}get_packages`,
      data: {
        status: status || 0,
      },
    }).then((response) => {
      dispatch({ type: GET_PACKAGES, payload: response.data.data });
    });
  } catch (err) {}
};
export const GetDashboardCount = () => async (dispatch) => {
  const response = await axios.get(`${apiurl}/get_dashboard_count`);
  return dispatch({ type: GET_DASHBOARD_COUNT, payload: response.data.data });
};
