/* eslint-disable no-empty */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import moment from 'moment';
import { notification } from 'antd';
import { apiurl } from '../utils/baseUrl';
import { GET_QUESTION_BANK } from '../utils/Constants';
import { GetManagePackageOld } from './ManagePackageAction';

export const GetQuestionBank = (pillarsId, status, ManagePackageEdit) => async (dispatch) => {
  try {
    axios({
      method: 'post',
      url: `${apiurl}get_question_bank`,
      data: {
        pillars_id: pillarsId || '0',
        status: status || '',
      },
    }).then((response) => {
      if (response.data.status === 1) {
        dispatch({ type: GET_QUESTION_BANK, payload: response.data.data });
        ManagePackageEdit && dispatch(GetManagePackageOld(ManagePackageEdit.pillar_id, ManagePackageEdit.package_id));
      }
    });
  } catch (err) {}
};

export const InsertUpdateDeleteQuestionBank = (data, deleteData) => async (dispatch) => {
  try {
    let APIName = 'insert_question_bank';

    if (
      data.qus_bank_id.value &&
      data.qus_bank_id.value !== '' &&
      data.qus_bank_id.value !== 0 &&
      data.qus_bank_id.Edit === true
    ) {
      APIName = 'update_question_bank';
    } else if (deleteData) {
      APIName = 'delete_question_bank';
    }
    await axios({
      method: 'post',
      url: apiurl + APIName,
      data: {
        qus_bank_id: data.qus_bank_id.value,
        pillars_id: data.pillar.value,
        statement: data.statement.value,
        status: data.status.value,
        created_on: moment().format('YYYY-MM-DD'),
        created_by: localStorage.getItem('user_id'),
        updated_on: moment().format('YYYY-MM-DD'),
        updated_by: localStorage.getItem('user_id'),
      },
    }).then((response) => {
      if (response.data.status === 1) {
        notification.success({
          message: `Assessment statement ${deleteData ? 'created.' : data.qus_bank_id.Edit ? 'updated.' : 'created.'} `,
        });
        dispatch(GetQuestionBank(data.pillar.value));
      } else if (response.data.status === 0) {
        notification.warning({
          message: response.data.msg,
        });
      }
      return Promise.resolve();
    });
  } catch (err) {}
};
