/* eslint-disable react/jsx-no-bind */
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import CopyRightFooter from "../Footer/footer";
import login from "../../images/login_img.png";
import Logo from "../../images/aa_logo.svg";
import CustomButton from "../../component/Buttons/button";
import { videoURL } from "../../utils/baseUrl";
import "./intro.scss";
import Checkbox from "@mui/material/Checkbox";

const styles = {
  paperContainer: {
    backgroundImage: `url(${login})`,
    width: "100%",
    height: "100vh",
    backgroundSize: "100% 100%",
  },
};

const IntroPage = () => {
  const history = useHistory();
  const handleSubmit = () => {
    history.push("/User/demo");
  };
  const [istermsChecked, setIstermsChecked] = useState(false);
  return (
    <div className="login">
      <div className="container-fluid">
        <div
          className="row"
          style={{ marginRight: "0 !important", marginLeft: "0 !important" }}
        >
          <div
            className="col-sm-5"
            style={{
              paddingRight: "0 !important",
              paddingLeft: "0 !important",
            }}
          >
            <div style={styles.paperContainer} className="left_panel">
              <div className="IntroLogo">
                <img src={Logo} alt="" />
              </div>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="row justify-content-center">
              <div className="intro-head">
                <h6>
                  <span style={{ color: "#EE8E54" }}>AlignedAround</span> Lite
                  Assessment
                </h6>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="list">
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      AlignedAround offers an alignment and decision-making
                      analytics platform that enables an organisation to Define,
                      Measure and Improve alignment around its most strategic
                      investments and initiatives.
                    </p>
                  </div>
                </div>

                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      A range of services and assessment types are available.
                      Your organisation is leveraging the AlignedAround Lite
                      assessment to start its journey.
                    </p>
                  </div>
                </div>

                {/* <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      The assessment starts with an anonymous demographic survey
                      that is added to our global dataset of transformation
                      leaders.
                    </p>
                  </div>
                </div> */}
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      The Lite assessment consists of two statements for each of
                      the seven AlignedAround pillars of Vision, Strategy,
                      Success, Leader/Follower, Accountability, Investment and
                      Advocacy. This is followed by a single initiative
                      sentiment statement.
                    </p>
                  </div>
                </div>
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      You will be asked to rate each alignment statement on a
                      seven-point scale from Strongly Disagree to Strongly
                      Agree. It should take about five to ten minutes to
                      complete the assessment. We encourage you to be candid.
                    </p>
                  </div>
                </div>
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      All of your responses and comments are anonymous and only
                      visible to you and the AlignedAround team. Once all of
                      your peers have completed the assessment, the team results
                      will be available to you.
                    </p>
                  </div>
                </div>
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      We strongly recommend you discuss the results as a group
                      and use them to assess and improve the level of leadership
                      alignment around the initiative.
                    </p>
                  </div>
                </div>
                {/* <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ paddingRight: 10 }}>
                        If you have any questions, please contact us at
                      </p>
                      <a
                        href="mailto:admin@alignedaround.com"
                        style={{ fontSize: "18px", marginLeft: "-4px" }}
                      >
                        admin@alignedaround.com
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="list" style={{ marginLeft: "40px" }}>
                <Checkbox
                  value={istermsChecked}
                  onChange={(e) => setIstermsChecked(e.target.checked)}
                  style={{ marginRight: "10px" }}
                />
                I agree to the{" "}
                <a
                  target="_blank"
                  href="https://www.alignedaround.com/termsofuse"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href="https://www.alignedaround.com/privacy"
                  rel="noopener noreferrer"
                >
                  Privacy Policy{" "}
                </a>
                of AlignedAround
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="btnBegin">
                <CustomButton
                  btnName="BEGIN"
                  custombtnCSS="Primary"
                  onBtnClick={handleSubmit}
                  btnDisable={!istermsChecked}
                />
              </div>
            </div>
            <div className="user-footer">
              <CopyRightFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IntroPage;
