/* eslint-disable no-empty */
import axios from "axios";
import {
  GET_MATRIX_RESPONDENT,
  GET_RESPONDENT_BY_INITIATIVE,
  GET_MATRIX_INITIATIVE_OVERALL,
} from "../utils/Constants";
import { apiurl } from "../utils/baseUrl";

export const GetRespondentByInitiative = (data) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_respondent_by_initiative_id`,
      data: {
        initiative_id: data,
        client_id: JSON.parse(localStorage.getItem("client_id")),
        status: 1,
      },
    }).then((response) => {
      dispatch({
        type: GET_RESPONDENT_BY_INITIATIVE,
        payload: response.data.data,
      });
    });
  } catch (err) {}
};
export const GetMatrixRespondent = (data) => async (dispatch) => {
  try {
    axios({
      method: "post",
      url: `${apiurl}get_matrix_by_respondent`,
      data: {
        respondent_id: data.resp_id,
        client_id: JSON.parse(localStorage.getItem("client_id")),
      },
    }).then((response) => {
      dispatch({ type: GET_MATRIX_RESPONDENT, payload: response.data.data });
    });
  } catch (err) {}
};

export const GetMatrixInitiativeOverall = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_MATRIX_INITIATIVE_OVERALL, payload: [] });
    axios({
      method: "post",
      url: `${apiurl}get_matrix_initiative_over_all`,
      data: {
        initiative_id: data,
        client_id: JSON.parse(localStorage.getItem("client_id")),
      },
    }).then((response) => {
      dispatch({
        type: GET_MATRIX_INITIATIVE_OVERALL,
        payload: response.data.data,
      });
    });
  } catch (err) {}
};
