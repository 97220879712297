/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { Collapse } from "antd";
import EnhancedTable from "../../../component/DynTable/table";
// import CustomButton from "../../../component/Buttons/button";
import "./individuals.scss";
import { GetMatrixRespondent } from "../../../actions/ScoreCardAction";

const IResult = (props) => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  // const history = useHistory();
  const [rows, setrows] = useState([]);
  const [sentiment, setSentiment] = useState();
  const [alignMean, setAlignMean] = useState();
  const [comments, setComments] = useState([]);
  const header = [
    { id: "id", label: "Pillar" },
    { id: "q1", label: "Vision" },
    { id: "q2", label: "Strategy" },
    { id: "q3", label: "Success" },
    { id: "q4", label: "Leader/Follower" },
    { id: "q5", label: "Accountability" },
    { id: "q6", label: "Investment" },
    { id: "q7", label: "Advocacy" },
    // { id: 'all', label: 'User-Score' }
  ];
  // const BackFunc = () => {
  //   history.push("/Admin/dashboard");
  // };

  /** ********* DISPATCH */
  useEffect(() => {
    dispatch(GetMatrixRespondent({ resp_id: props.resp_id }));
  }, [dispatch, props.resp_id]);

  /** ******* PAYLOAD */
  const Result = useSelector((state) => state.ScoreCardReducer);

  useEffect(() => {
    const rows = [];
    if (Result?.GetMatrixByRespondent.length > 0) {
      setComments(Result?.GetMatrixByRespondent[1]);
      if (Result?.GetMatrixByRespondent[2][0]?.package_type === "LightWeight") {
        Result?.GetMatrixByRespondent[0].map((data, index) => {
          if (index === Result?.GetMatrixByRespondent[0].length - 2) {
            rows.push({
              id: <div className="light_score">Score</div>,
              q1: (
                <div
                  className={
                    (data.Pillar_1_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_1_mean < 6 &&
                      "mean") ||
                    (data.Pillar_1_mean > 5.9 && "above") ||
                    (data.Pillar_1_mean < 5 && "below")
                  }
                >
                  {data.Pillar_1_mean && data.Pillar_1_mean.toFixed(0)}
                </div>
              ),
              q2: (
                <div
                  className={
                    (data.Pillar_2_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_2_mean < 6 &&
                      "mean") ||
                    (data.Pillar_2_mean > 5.9 && "above") ||
                    (data.Pillar_2_mean < 5 && "below")
                  }
                >
                  {data.Pillar_2_mean && data.Pillar_2_mean.toFixed(0)}
                </div>
              ),
              q3: (
                <div
                  className={
                    (data.Pillar_3_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_3_mean < 6 &&
                      "mean") ||
                    (data.Pillar_3_mean > 5.9 && "above") ||
                    (data.Pillar_3_mean < 5 && "below")
                  }
                >
                  {data.Pillar_3_mean && data.Pillar_3_mean.toFixed(0)}
                </div>
              ),
              q4: (
                <div
                  className={
                    (data.Pillar_4_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_4_mean < 6 &&
                      "mean") ||
                    (data.Pillar_4_mean > 5.9 && "above") ||
                    (data.Pillar_4_mean < 5 && "below")
                  }
                >
                  {data.Pillar_4_mean && data.Pillar_4_mean.toFixed(0)}
                </div>
              ),
              q5: (
                <div
                  className={
                    (data.Pillar_5_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_5_mean < 6 &&
                      "mean") ||
                    (data.Pillar_5_mean > 5.9 && "above") ||
                    (data.Pillar_5_mean < 5 && "below")
                  }
                >
                  {data.Pillar_5_mean && data.Pillar_5_mean.toFixed(0)}
                </div>
              ),
              q6: (
                <div
                  className={
                    (data.Pillar_6_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_6_mean < 6 &&
                      "mean") ||
                    (data.Pillar_6_mean > 5.9 && "above") ||
                    (data.Pillar_6_mean < 5 && "below")
                  }
                >
                  {data.Pillar_6_mean && data.Pillar_6_mean.toFixed(0)}
                </div>
              ),
              q7: (
                <div
                  className={
                    (data.Pillar_7_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_7_mean < 6 &&
                      "mean") ||
                    (data.Pillar_7_mean > 5.9 && "above") ||
                    (data.Pillar_7_mean < 5 && "below")
                  }
                >
                  {data.Pillar_7_mean && data.Pillar_7_mean.toFixed(0)}
                </div>
              ),
              // all: ((data.over_all_mean) && (data.over_all_mean).toFixed(2)) || "-"
            });
            setSentiment(
              data.over_all_sentiment && data.over_all_sentiment.toFixed(2)
            );
            setAlignMean(data.over_all_mean && data.over_all_mean.toFixed(2));
          }
          return true;
        });
      } else if (
        Result?.GetMatrixByRespondent[2][0]?.package_type === "LimitedEdition"
      ) {
        Result?.GetMatrixByRespondent[0].map((data, index) => {
          if (index === Result?.GetMatrixByRespondent[0].length - 2) {
            rows.push({
              id: <div className="light_score">Score</div>,
              q1: (
                <div
                  className={
                    (data.Pillar_1_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_1_mean < 6 &&
                      "mean") ||
                    (data.Pillar_1_mean > 5.9 && "above") ||
                    (data.Pillar_1_mean < 5 && "below")
                  }
                >
                  {data.Pillar_1_mean && data.Pillar_1_mean.toFixed(2)}
                </div>
              ),
              q2: (
                <div
                  className={
                    (data.Pillar_2_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_2_mean < 6 &&
                      "mean") ||
                    (data.Pillar_2_mean > 5.9 && "above") ||
                    (data.Pillar_2_mean < 5 && "below")
                  }
                >
                  {data.Pillar_2_mean && data.Pillar_2_mean.toFixed(2)}
                </div>
              ),
              q3: (
                <div
                  className={
                    (data.Pillar_3_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_3_mean < 6 &&
                      "mean") ||
                    (data.Pillar_3_mean > 5.9 && "above") ||
                    (data.Pillar_3_mean < 5 && "below")
                  }
                >
                  {data.Pillar_3_mean && data.Pillar_3_mean.toFixed(2)}
                </div>
              ),
              q4: (
                <div
                  className={
                    (data.Pillar_4_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_4_mean < 6 &&
                      "mean") ||
                    (data.Pillar_4_mean > 5.9 && "above") ||
                    (data.Pillar_4_mean < 5 && "below")
                  }
                >
                  {data.Pillar_4_mean && data.Pillar_4_mean.toFixed(2)}
                </div>
              ),
              q5: (
                <div
                  className={
                    (data.Pillar_5_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_5_mean < 6 &&
                      "mean") ||
                    (data.Pillar_5_mean > 5.9 && "above") ||
                    (data.Pillar_5_mean < 5 && "below")
                  }
                >
                  {data.Pillar_5_mean && data.Pillar_5_mean.toFixed(2)}
                </div>
              ),
              q6: (
                <div
                  className={
                    (data.Pillar_6_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_6_mean < 6 &&
                      "mean") ||
                    (data.Pillar_6_mean > 5.9 && "above") ||
                    (data.Pillar_6_mean < 5 && "below")
                  }
                >
                  {data.Pillar_6_mean && data.Pillar_6_mean.toFixed(2)}
                </div>
              ),
              q7: (
                <div
                  className={
                    (data.Pillar_7_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_7_mean < 6 &&
                      "mean") ||
                    (data.Pillar_7_mean > 5.9 && "above") ||
                    (data.Pillar_7_mean < 5 && "below")
                  }
                >
                  {data.Pillar_7_mean && data.Pillar_7_mean.toFixed(2)}
                </div>
              ),
              // all: ((data.over_all_mean) && (data.over_all_mean).toFixed(2)) || "-"
            });
            setSentiment(
              data.over_all_sentiment && data.over_all_sentiment.toFixed(2)
            );
            setAlignMean(data.over_all_mean && data.over_all_mean.toFixed(2));
          }
          return true;
        });
      } else if (
        Result?.GetMatrixByRespondent[2][0].package_type === "Comprehensive"
      ) {
        Result?.GetMatrixByRespondent[0].map((data, index) => {
          if (index === Result?.GetMatrixByRespondent[0].length - 2) {
            rows.push({
              id: <div className="mean-div">Mean</div>,
              q1: (
                <div
                  className={
                    (data.Pillar_1_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_1_mean < 6 &&
                      "mean") ||
                    (data.Pillar_1_mean > 5.9 && "above") ||
                    (data.Pillar_1_mean < 5 && "below")
                  }
                >
                  {data.Pillar_1_mean && data.Pillar_1_mean.toFixed(2)}
                </div>
              ),
              q2: (
                <div
                  className={
                    (data.Pillar_2_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_2_mean < 6 &&
                      "mean") ||
                    (data.Pillar_2_mean > 5.9 && "above") ||
                    (data.Pillar_2_mean < 5 && "below")
                  }
                >
                  {data.Pillar_2_mean && data.Pillar_2_mean.toFixed(2)}
                </div>
              ),
              q3: (
                <div
                  className={
                    (data.Pillar_3_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_3_mean < 6 &&
                      "mean") ||
                    (data.Pillar_3_mean > 5.9 && "above") ||
                    (data.Pillar_3_mean < 5 && "below")
                  }
                >
                  {data.Pillar_3_mean && data.Pillar_3_mean.toFixed(2)}
                </div>
              ),
              q4: (
                <div
                  className={
                    (data.Pillar_4_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_4_mean < 6 &&
                      "mean") ||
                    (data.Pillar_4_mean > 5.9 && "above") ||
                    (data.Pillar_4_mean < 5 && "below")
                  }
                >
                  {data.Pillar_4_mean && data.Pillar_4_mean.toFixed(2)}
                </div>
              ),
              q5: (
                <div
                  className={
                    (data.Pillar_5_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_5_mean < 6 &&
                      "mean") ||
                    (data.Pillar_5_mean > 5.9 && "above") ||
                    (data.Pillar_5_mean < 5 && "below")
                  }
                >
                  {data.Pillar_5_mean && data.Pillar_5_mean.toFixed(2)}
                </div>
              ),
              q6: (
                <div
                  className={
                    (data.Pillar_6_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_6_mean < 6 &&
                      "mean") ||
                    (data.Pillar_6_mean > 5.9 && "above") ||
                    (data.Pillar_6_mean < 5 && "below")
                  }
                >
                  {data.Pillar_6_mean && data.Pillar_6_mean.toFixed(2)}
                </div>
              ),
              q7: (
                <div
                  className={
                    (data.Pillar_7_mean > 4.9 &&
                      "mean" &&
                      data.Pillar_7_mean < 6 &&
                      "mean") ||
                    (data.Pillar_7_mean > 5.9 && "above") ||
                    (data.Pillar_7_mean < 5 && "below")
                  }
                >
                  {data.Pillar_7_mean && data.Pillar_7_mean.toFixed(2)}
                </div>
              ),
            });
            setSentiment(
              data.over_all_sentiment && data.over_all_sentiment.toFixed(2)
            );
            setAlignMean(data.over_all_mean && data.over_all_mean.toFixed(2));
          } else if (index === Result?.GetMatrixByRespondent[0].length - 1) {
            if (Number(localStorage.getItem("user_type")) === 0) {
              // rows.push({
              //   id: "P-Weight %",
              //   q1: data?.Pillar_1_p_weight,
              //   q2: data?.Pillar_2_p_weight,
              //   q3: data?.Pillar_3_p_weight,
              //   q4: data?.Pillar_4_p_weight,
              //   q5: data?.Pillar_5_p_weight,
              //   q6: data?.Pillar_6_p_weight,
              //   q7: data?.Pillar_7_p_weight,
              // });
            }
          } else {
            rows.push({
              id: data?.qus_no,
              q1: data?.qus_ans_id_1,
              q2: data?.qus_ans_id_2,
              q3: data?.qus_ans_id_3,
              q4: data?.qus_ans_id_4,
              q5: data?.qus_ans_id_5,
              q6: data?.qus_ans_id_6,
              q7: data?.qus_ans_id_7,
            });
          }
          return true;
        });
      }
      // ```````Z```````
    }
    setrows(rows);
  }, [Result]);

  /**
   * @function roundoff used for roundoff
   * @param {string} value input value
   * @returns {string} returns string value
   */
  function roundoff(value) {
    const x = Math.round(value);

    if (x === 0) {
      return "";
    }
    if (x === 1) {
      return "Strongly Disagree";
    }
    if (x === 2) {
      return "Disagree";
    }
    if (x === 3) {
      return "Somewhat Disagree";
    }
    if (x === 4) {
      return "Neutral";
    }
    if (x === 5) {
      return "Somewhat Agree";
    }
    if (x === 6) {
      return "Agree";
    }
    if (x === 7) {
      return "Strongly Agree";
    }
    return true;
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <EnhancedTable
            headCells={header}
            aligncss="individual-table"
            rows={rows}
            tableMainStatus="score_tab"
            scorecard={9}
            enableID
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div style={{ width: "45%", padding: "15px" }}>
          <div className="rate">
            <div
              style={{ padding: "0px 10px", width: "60%", color: "#4566df" }}
            >
              {props.state === "user" && "Your"} Overall Alignment Score
            </div>
            <div style={{ width: "10%", color: "#4566df" }}>{alignMean}</div>
            <div style={{ width: "40%", color: "#4566df" }}>
              {roundoff(alignMean)}
            </div>
          </div>
          <div className="rate">
            <div
              style={{ padding: "0px 10px", width: "60%", color: "#4566df" }}
            >
              {props.state === "user" && "Your"} Overall Sentiment
            </div>
            <div style={{ width: "10%", color: "#4566df" }}>{sentiment}</div>
            <div style={{ width: "40%", color: "#4566df" }}>
              {roundoff(sentiment)}
            </div>
          </div>
          <div className="rate">
            <div
              style={{ padding: "0px 10px", width: "60%", color: "#4566df" }}
            >
              Minimum Alignment Score
            </div>
            <div style={{ width: "10%", color: "#4566df" }}>5.00</div>
            <div style={{ width: "40%", color: "#4566df" }}>Agree</div>
          </div>
          <div className="indicate">
            <div />
            <div>Below Min. Alignment</div>
            <div />
            <div>Min. Alignment</div>
            <div />
            <div>Above Min. Alignment</div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <Collapse expandIconPosition="right">
          <Panel header="Comments" key="1">
            <div className="comment_row">
              {comments &&
                comments.map((data, index) => {
                  return (
                    <div
                      className={`answer ${index % 2 === 0 ? "odd" : "even"}`}
                    >
                      <div className="comment_name">{data.name}</div>
                      <div className="comments">{data.comment || "-"}</div>
                    </div>
                  );
                })}
            </div>
          </Panel>
        </Collapse>
      </div> */}
    </div>
  );
};
export default IResult;
