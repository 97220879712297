import { useState, useEffect } from "react";
import { apiurl } from "../../utils/baseUrl";
import EnhancedTable from "../../component/DynTable/table";
import axios from "axios";
import { notification } from "antd";

const AllClients = () => {
  const [clientDetails, setClientDetails] = useState([]);
  const [clientModified, setClientModified] = useState(1);
  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${apiurl}getClientsInSignup`,
      }).then((response) => {
        console.log(response);
        setClientDetails(response.data.data);
      });
    } catch (err) {
      notification.error({
        message: `Error loading dashboard`,
      });
      console.error("ERR", err);
    }
  }, [clientModified]);
  const initiativeHeader = [
    // { id: 'serialno', label: 'Serial No' },
    { id: "client_name", label: "Client Name" },
    { id: "client_id", label: "Client ID" },
    { id: "contact_person", label: "Contact Person" },
    { id: "email", label: "Email" },
    { id: "phone_number", label: "Phone Number" },
    { id: "action", label: "Action" },
  ];
  const tableActions = (clientId, action = "delete") => {
    try {
      const url =
        action === "delete"
          ? `${apiurl}deleteUser`
          : `${apiurl}respondent_mail_resend_Signup`;
      const message =
        action === "delete"
          ? `Successfully Deleted Client`
          : `Mail sent successfully`;
      axios({
        method: "post",
        url: url,
        data: {
          client_id: clientId,
        },
      }).then((response) => {
        console.log(response);
        notification.success({
          message: message,
        });
        setClientModified(clientModified + 1);
      });
    } catch (err) {
      notification.error({
        message: `Error updading the action`,
      });
      console.error("ERR", err);
    }
  };
  const getTableRows = (header, rows) => {
    // resendLogin
    const finalArr = [];
    if (rows.length > 0) {
      rows.forEach((value) => {
        const finalObj = {};
        const tempValue = {
          action: (
            <div className="action-div">
              <div
                className="cursor"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  console.log("Resend", value.client_id);
                  tableActions(value.client_id, "resend");
                }}
              >
                Resend Invite
              </div>

              <div
                className="cursor"
                onClick={() => {
                  console.log("Delete", value.client_id);
                  tableActions(value.client_id, "delete");
                }}
              >
                {" "}
                Delete
              </div>
            </div>
          ),
          ...value,
        };
        // console.log("finalObj", value);
        header.forEach((val) => {
          const id = val.id;
          if (tempValue[id]) {
            finalObj[id] = tempValue[id];
          } else {
            finalObj[id] = "";
            console.log(`${id} does not exist on the object`);
          }
        });
        finalArr.push(finalObj);
      });
    }
    return finalArr;
  };
  return (
    <>
      <EnhancedTable
        header
        search
        tableMainStatus="Upcoming"
        tabletitle="Assement Leads"
        headCells={initiativeHeader}
        rows={getTableRows(initiativeHeader, clientDetails)}
        //   rows={
        //     DashboardTable.length === 0
        //       ? DashboardTable
        //       : DashboardTable.getDashboardList
        //   }
      />
    </>
  );
};

export default AllClients;
