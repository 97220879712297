/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./login.scss";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import axios from "axios";
import { notification } from "antd";
import CopyRightFooter from "../Footer/footer";
import login from "../../images/login_img.png";
import Mail from "../../images/mail_icon.svg";
import Logo from "../../images/aa_logo.svg";
import Password from "../../images/password_icon.svg";
import CustomButton from "../../component/Buttons/button";
import { apiurl } from "../../utils/baseUrl";
import { useAuth } from "../../context/auth";
import CryptoJS from "crypto-js";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import emailsList from "./emailsList.json";

const styles = {
  paperContainer: {
    backgroundImage: `url(${login})`,
    width: "100%",
    height: "100vh",
    backgroundSize: "100% 100%",
  },
};

const Login = () => {
  const history = useHistory();
  const location = useLocation();

  const { setAuthTokens } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const url = window.location.href.split("/");
  const pathname = url[url.length - 1];

  const [signup, setSignup] = useState(false);

  const storeUserDetails = (event) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);

      if (event.target.value === "") {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(event.target.value)) {
        setInvalidEmail(false);
      } else {
        setInvalidEmail(true);
      }
    }

    if (event.target.name === "password") {
      setPassword(event.target.value);
      if (event.target.value === "") {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email === "") {
      setEmailError(true);
      return;
    }

    if (password === "") {
      setPasswordError(true);
      return;
    }

    if (!emailError && !passwordError && !invalidEmail) {
      const encryptPassword =
        email == "admin@alignedaround.com" || pathname === "Userlogin"
          ? CryptoJS.AES.encrypt(password, "alignedaround").toString()
          : password;
      // console.log("hash", CryptoJS.AES.decrypt)
      const url =
        email == "admin@alignedaround.com" || pathname === "Userlogin"
          ? `${apiurl}login`
          : `${apiurl}limitedaccess-login`;
      axios
        .post(url, {
          email,
          password: encryptPassword,
        })
        .then((res) => {
          if (res.status === 200 && res.data.status === 1) {
            setAuthTokens(res.data);
            console.log("data", res.data);
            if (res.data.data.user_type === 0) {
              history.push("/Admin/dashboard");
            } else if (res.data.data.user_type === 4) {
              console.log(res.data.data);
              if (res.data.data.initiative == "initiative not started")
                history.push("assesementleadIntro");
              else history.push("/Admin/dashboard/assesment-lead");
            } else if (res.data.data.status_respondent === 0) {
              if (res.data.data.package_name === "Comprehensive") {
                history.push("/Intro");
              } else if (res.data.data.package_name === "Lightweight") {
                history.push("/Light_Intro");
              } else if (res.data.data.package_name === "Limited Edition") {
                history.push("/Light_Intro1");
              }
            } else if (res.data.data.status_respondent === 1) {
              history.push(
                `/User/pillar?id=${
                  res.data.data.pillar_id ? res.data.data.pillar_id + 1 : 1
                }`
              );
            } else if (res.data.data.status_respondent === 2) {
              // history.push('/User/demo');
              history.push({
                pathname: `/User/score`,
                state: {
                  pathname: "user",
                  params: {
                    type: "user",
                    id: JSON.parse(localStorage.getItem("user_id")),
                  },
                },
              });
            }
          } else {
            notification.error({
              message: res.data.msg,
            });
          }
        })
        .catch(() => {
          notification.error({
            message:
              "An unknown application error has occurred. Please contact the AlignedAround support team.",
          });
        });
    }
  };
  const reasonQuestions = [
    "Initiative at design/business case stage",
    "Initiative just started",
    "Initiative approaching key milestone",
    "Initiative at risk",
    "Other (Specify)",
  ];
  const numberOfEmployees = [
    "< 50",
    "< 500",
    "< 2,500",
    "< 5,000",
    "< 10,000",
    "> 10,000",
  ];

  const enterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleSubmit(event);
    }
  };
  const initialSignUpData = {
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    client_name: "",
    contact_person: "",
    industry: "",
    hq_location: "",
    created_on: "2023-01-01",
    created_by: 4,
    registered_company_name: "",
    headquarters_in: "",
    dba: "",
    company_website: "",
    phone_number: "",
    reason_for_signing_up: "",
    role: "",
    number_of_employees: "",
  };
  const initialErrorData = {
    first_name: "",
    last_name: "",
    registered_company_name: "",
    headquarters_in: "",
    company_website: "",
    email: "",
    phone_number: "",
    role: "",
    number_of_employees: "",
    reason_for_signing_up: "",
  };
  const [signupData, setSignupData] = useState(initialSignUpData);
  const [signupDataError, setSignupDataError] = useState(initialErrorData);
  console.log(signupData);
  const checkError = (val) => {
    if (val === "NO_ERROR") return false;
    if (val === "ERROR") return true;
  };
  function validateEmail(email) {
    const emailDomain = email.split("@")[1];
    const isRestrictedDomain = emailsList.includes(emailDomain);
    if (isRestrictedDomain) {
      notification.error({
        message: "Kindly enter your company email address",
      });
    }

    var re = /\S+@\S+\.\S+/;
    return re.test(email) && !isRestrictedDomain;
    // return re.test(email);
  }
  return (
    <div className="login">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-8">
            <div style={styles.paperContainer} className="left_panel">
              <div className="LogoContainer">
                <img id="logo_img" src={Logo} alt="" />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="login-containern">
              {signup ? (
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "100%" },
                    p: "50px",
                    height: "100vh",
                    overflow: "scroll",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Typography
                    style={{
                      fontSize: "25px",
                      color: "#EE8E53",
                    }}
                  >
                    Register
                  </Typography>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        first_name: e.target.value,
                        contact_person: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      setSignupDataError({
                        ...signupDataError,
                        first_name: error,
                      });
                    }}
                    error={checkError(signupDataError.first_name)}
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        last_name: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      setSignupDataError({
                        ...signupDataError,
                        last_name: error,
                      });
                    }}
                    error={checkError(signupDataError.last_name)}
                  />
                  <TextField
                    label="Registered Company Name"
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        company_name: e.target.value,
                        registered_company_name: e.target.value,
                        client_name: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      setSignupDataError({
                        ...signupDataError,
                        registered_company_name: error,
                      });
                    }}
                    error={checkError(signupDataError.registered_company_name)}
                  />
                  <TextField
                    label="Your Role/Title"
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        role: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      setSignupDataError({
                        ...signupDataError,
                        role: error,
                      });
                    }}
                    error={checkError(signupDataError.role)}
                  />
                  <TextField
                    label="Headquarters Location"
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        headquarters_in: e.target.value,
                        hq_location: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      setSignupDataError({
                        ...signupDataError,
                        headquarters_in: error,
                      });
                    }}
                    error={checkError(signupDataError.headquarters_in)}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label-number">
                      Number of Employees
                    </InputLabel>
                    <Select
                      id="reason-for-signing-up-number"
                      // value={age}
                      label="Number of Employees"
                      onChange={(e) => {
                        setSignupData({
                          ...signupData,
                          number_of_employees: e.target.value,
                        });
                        const value = e.target.value;
                        let error = "NO_ERROR";
                        if (value == "") error = "ERROR";
                        setSignupDataError({
                          ...signupDataError,
                          number_of_employees: error,
                        });
                      }}
                      error={checkError(signupDataError.number_of_employees)}
                    >
                      {numberOfEmployees.map((question) => (
                        <MenuItem key={question} value={question}>
                          {question}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Company Website"
                    variant="outlined"
                    type="url"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        company_website: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      setSignupDataError({
                        ...signupDataError,
                        company_website: error,
                      });
                    }}
                    error={checkError(signupDataError.company_website)}
                  />
                  <TextField
                    label="Business Email Address"
                    variant="outlined"
                    type="email"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        email: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      if (!validateEmail(value)) {
                        error = "ERROR";
                      } else {
                        error = "NO_ERROR";
                      }
                      setSignupDataError({
                        ...signupDataError,
                        email: error,
                      });
                    }}
                    error={checkError(signupDataError.email)}
                  />
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    type="tel"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        phone_number: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      setSignupDataError({
                        ...signupDataError,
                        phone_number: error,
                      });
                    }}
                    error={checkError(signupDataError.phone_number)}
                  />
                  {/* <TextField
                    label="Number of Employees"
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setSignupData({
                        ...signupData,
                        number_of_employees: e.target.value,
                      });
                      const value = e.target.value;
                      let error = "NO_ERROR";
                      if (value == "") error = "ERROR";
                      setSignupDataError({
                        ...signupDataError,
                        number_of_employees: error,
                      });
                    }}
                    error={checkError(signupDataError.number_of_employees)}
                  /> */}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Reason for signing up
                    </InputLabel>
                    <Select
                      id="reason-for-signing-up"
                      // value={age}
                      label="Reason for signing up"
                      onChange={(e) => {
                        setSignupData({
                          ...signupData,
                          reason_for_signing_up: e.target.value,
                        });
                        const value = e.target.value;
                        let error = "NO_ERROR";
                        if (value == "") error = "ERROR";
                        setSignupDataError({
                          ...signupDataError,
                          reason_for_signing_up: error,
                        });
                      }}
                      error={checkError(signupDataError.reason_for_signing_up)}
                    >
                      {reasonQuestions.map((question) => (
                        <MenuItem key={question} value={question}>
                          {question}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {signupData.reason_for_signing_up != "" &&
                    signupData.reason_for_signing_up !=
                      "Initiative at design/business case stage" &&
                    signupData.reason_for_signing_up !=
                      "Initiative just started" &&
                    signupData.reason_for_signing_up !=
                      "Initiative approaching key milestone" &&
                    signupData.reason_for_signing_up !=
                      "Initiative at risk" && (
                      <TextField
                        label="Specify"
                        variant="outlined"
                        type="text"
                        onChange={(e) => {
                          setSignupData({
                            ...signupData,
                            reason_for_signing_up: e.target.value,
                          });
                          const value = e.target.value;
                          let error = "NO_ERROR";
                          if (value == "") error = "ERROR";
                          setSignupDataError({
                            ...signupDataError,
                            reason_for_signing_up: error,
                          });
                        }}
                        error={checkError(
                          signupDataError.reason_for_signing_up
                        )}
                      />
                    )}
                  <CustomButton
                    btnName="Submit"
                    custombtnCSS="Warning"
                    onBtnClick={() => {
                      console.log(signupData);
                      if (
                        signupData.reason_for_signing_up == 4 &&
                        signupData.reason_for_signing_up == ""
                      ) {
                        notification.warning({
                          message: "Please enter a reason for signing up",
                        });
                      } else {
                        const tempSignupError = { ...signupDataError };
                        let hasError = 0;
                        Object.keys(signupDataError).forEach((val) => {
                          if (signupDataError[val] == "") {
                            console.log("ERR", val);
                            tempSignupError[val] = "ERROR";
                            hasError++;
                          } else if (signupDataError[val] == "ERROR")
                            hasError++;
                        });
                        setSignupDataError(tempSignupError);
                        if (hasError == 0) {
                          const tempSignupData = { ...signupData };
                          tempSignupData.contact_person = `${tempSignupData.first_name} ${tempSignupData.last_name}`;
                          axios
                            .post(`${apiurl}limitedaccess`, tempSignupData)
                            .then((res) => {
                              console.log(res);
                              if (res.data.status == 1) {
                                notification.success({
                                  message: `Registration successful. Please check your email.`,
                                });
                                setSignupData(initialSignUpData);
                                setSignupDataError(initialErrorData);
                                if (location.pathname == "/")
                                  history.push("/alLogin");
                                setSignup(false);
                              } else {
                                if (
                                  res.data.msg ==
                                  "This email domain already exists"
                                ) {
                                  notification.error({
                                    message:
                                      "Someone from your organisation already exists. Please contact administrator",
                                  });
                                } else {
                                  notification.error({
                                    message: res.data.msg,
                                  });
                                }
                              }
                            })
                            .catch(() => {
                              notification.error({
                                message:
                                  "An unknown application error has occurred. Please contact the AlignedAround support team.",
                              });
                            });
                        } else {
                          notification.warning({
                            message: "Please fill in all fields properly",
                          });
                        }
                      }
                    }}
                  />
                </Box>
              ) : (
                <div style={{ paddingTop: "40px" }}>
                  <div className="row justify-content-center">
                    <div className="col-sm-12">
                      <Typography gutterBottom className="head_div">
                        <h4>
                          Welcome to the{" "}
                          <span style={{ color: "#EE8E54" }}>
                            AlignedAround
                          </span>
                        </h4>
                        <h4>Assessment</h4>
                      </Typography>
                      {pathname === "Userlogin" && (
                        <Typography gutterBottom className="side_head_div">
                          <h6>
                            Please enter your organisation email address and the
                            individual access code provided to you
                          </h6>
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-sm-10">
                      <div className="input_div">
                        <div className="err_div">
                          {emailError && "Email-id Required"}
                          {!emailError && invalidEmail && (
                            <span className="loginErrMsg">Invalid email</span>
                          )}
                        </div>
                        <TextField
                          required
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          placeholder="Email Address"
                          name="email"
                          value={email}
                          type="email"
                          autoComplete={false}
                          onKeyPress={enterPressed}
                          onChange={(e) => storeUserDetails(e)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <img src={Mail} alt="" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="input_div">
                        <div className="err_div">
                          {passwordError && "Password Required"}
                        </div>
                        <TextField
                          required
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          placeholder={
                            pathname === "Userlogin"
                              ? "Individual Access Code"
                              : "Password"
                          }
                          name="password"
                          value={password}
                          type="password"
                          autoComplete={false}
                          onKeyPress={enterPressed}
                          onChange={(e) => storeUserDetails(e)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <img src={Password} alt="" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-sm-10">
                      <div className="submit_div">
                        <CustomButton
                          btnName="LOGIN"
                          custombtnCSS="Warning"
                          onBtnClick={handleSubmit}
                        />
                      </div>
                      {/* <div>
                        <p
                          onClick={() => setSignup(true)}
                          style={{ color: "#EE8E54", cursor: "pointer" }}
                        >
                          Get Limited Edition Access
                        </p>
                      </div> */}
                      {/* {pathname === "alLogin" ? (
                        ""
                      ) : (
                        <div>
                          <p
                            onClick={() => history.push("/alLogin")}
                            style={{ color: "#EE8E54", cursor: "pointer" }}
                          >
                            Assessment Lead Login
                          </p>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="login-footer" style={{ marginTop: "100px" }}>
              {!signup && pathname != "Userlogin" && (
                <div
                  className="submit_div"
                  style={{
                    position: "absolute",
                    bottom: "25px",
                    width: "84%",
                  }}
                >
                  {/* <b style={{ fontSize: "20px" }}>
                    Register for AlignedAround Lite
                  </b> */}
                  <CustomButton
                    btnName="Register for AlignedAround Lite"
                    needsBolderText
                    custombtnCSS="Primary"
                    onBtnClick={() => setSignup(true)}
                  />
                </div>
              )}
              <CopyRightFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
