/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import EnhancedTable from '../../component/DynTable/table';
import Labelbox from '../../helpers/labelbox/labelbox';
import CustomButton from '../../component/Buttons/button';
import './QuestionBank.scss';
import { GetPillars } from '../../actions/MasterAction';
import { GetQuestionBank, InsertUpdateDeleteQuestionBank } from '../../actions/QuestionBankAction';

import ValidationLibrary from '../../helpers/ValidationFunction/validationfunction';

const QuestionBankPage = () => {
  const dispatch = useDispatch();
  const [TableSearchItemsValue, setTableSearchItemsValue] = useState([]);
  const [QuestionBankTable, setQuestionBankTable] = useState([]);
  const [MasterDropdows, setMasterDropdows] = useState([]);
  const [QuestionBank, setQuestionBank] = useState({
    qus_bank_id: {
      value: '0',
      Edit: false,
    },
    pillar: {
      type: 'select',
      value: '',
      validation: [{ name: 'required' }],
    },
    table_pillar: {
      name: 'table_pillar',
      type: 'select',
      value: '',
      validation: [],
      dropdown: [],
    },
    statement: {
      value: '',
      validation: [{ name: 'required' }],
      error: null,
      errmsg: null,
    },
    status: {
      value: '1',
      validation: [{ name: 'required' }],
      error: null,
      errmsg: null,
    },
  });

  const header = [
    { id: 'serialno', label: 'No.' },
    { id: 'pillar', label: 'Pillar' },
    { id: 'statement', label: 'Statement' },
    { id: 'status', label: 'Status' },
    { id: 'create_date', label: 'Create Date' },
    { id: 'action', label: 'Action' },
  ];

  /// /////**** API DATA PROCESS******////////////

  useEffect(() => {
    dispatch(GetPillars());
    dispatch(GetQuestionBank('0'));
  }, [dispatch]);

  useEffect(() => {
    if (TableSearchItemsValue && TableSearchItemsValue[0]?.table_pillar?.value) {
      dispatch(GetQuestionBank(TableSearchItemsValue[0].table_pillar.value));
      setQuestionBank((prevState) => ({
        ...prevState,
        table_pillar: {
          name: 'table_pillar',
          type: 'select',
          value: TableSearchItemsValue[0]?.table_pillar?.value,
          validation: [{ name: 'required' }],
          dropdown: QuestionBank.table_pillar.dropdown,
        },
      }));
    }
  }, [TableSearchItemsValue]);

  const MasterData = useSelector((state) => state.MasterReducer);
  const QuestionBankData = useSelector((state) => state.QuestionBankReducer);

  useEffect(() => {
    const getPillarsList = [];
    const list = [];
    list.push({ id: '0', value: 'All' });
    MasterData.GetPillars.map((data) => {
      getPillarsList.push({ id: data.pillars_id, value: data.pillar_name });
      list.push({ id: data.pillars_id, value: data.pillar_name });
      return 0;
    });
    setMasterDropdows({ getPillarsList });
    QuestionBank.table_pillar.dropdown = list;
    setQuestionBank((prevState) => ({
      ...prevState,
    }));
  }, [MasterData]);

  useEffect(() => {
    const GetQuestionBankList = [];

    QuestionBankData.GetQuestionBank.map((data, index) => {
      GetQuestionBankList.push({
        id: index + 1,
        pillar_name: data.pillar_name,
        question: data.question,
        status: data.status === 1 ? 'Active' : 'Inactive',
        created_on: moment(data.created_on).format('MMM DD yyyy'),
        action: (
          <div className='action-div'>
            <div
              className={`actionicon ${QuestionBank.qus_bank_id.Edit ? 'edit' : 'qb_none'}`}
              onClick={() => onAction(data, 1)}
            >
              Edit
            </div>
            &nbsp;
            <div
              className={`actionicon ${QuestionBank.qus_bank_id.Edit ? 'delete' : 'qb_none'}`}
              onClick={() => onAction(data, 2)}
            >
              Delete
            </div>{' '}
          </div>
        ),
      });
      return true;
    });

    setQuestionBankTable({ GetQuestionBankList });
  }, [QuestionBankData, QuestionBank]);

  /// ///////////////////////

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   */
  function checkValidation(data, key) {
    const errorcheck = ValidationLibrary.checkValidation(data, QuestionBank[key].validation);
    const dynObj = {
      value: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
      validation: QuestionBank[key].validation,
      /// /// edit /////
      edit: QuestionBank[key].Edit ? QuestionBank[key].Edit : false,
      /// /// table data//////
      dropdown: QuestionBank[key].dropdown ? QuestionBank[key].dropdown : false,
      name: QuestionBank[key].name ? QuestionBank[key].name : false,
      type: QuestionBank[key].type ? QuestionBank[key].type : false,
    };

    setQuestionBank((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }
  const handleCancel = () => {
    const QuestionBankKey = ['pillar', 'statement', 'status', 'qus_bank_id'];
    QuestionBankKey.map((data) => {
      data !== 'status' ? (QuestionBank[data].value = '') : (QuestionBank[data].value = '1');
      return true;
    });
    QuestionBank.qus_bank_id.Edit = false;
    setQuestionBank((prevState) => ({
      ...prevState,
    }));
  };
  /**
   *
   */
  async function onSubmit() {
    const mainvalue = {};
    const targetkeys = Object.keys(QuestionBank);
    for (const i in targetkeys) {
      const errorcheck = ValidationLibrary.checkValidation(
        QuestionBank[targetkeys[i]].value,
        QuestionBank[targetkeys[i]].validation,
      );
      QuestionBank[targetkeys[i]].error = !errorcheck.state;
      QuestionBank[targetkeys[i]].errmsg = errorcheck.msg;
      mainvalue[targetkeys[i]] = QuestionBank[targetkeys[i]].value;
    }
    const filtererr = targetkeys.filter((obj) => QuestionBank[obj].error === true);

    if (filtererr.length > 0) {
      // setQuestion({ error: true });
    } else {
      await dispatch(InsertUpdateDeleteQuestionBank(QuestionBank));
      handleCancel();
    }
    setQuestionBank((prevState) => ({
      ...prevState,
    }));
  }

  const onAction = async (actionData, type) => {
    if (type === 1) {
      QuestionBank.qus_bank_id.value = actionData.qus_bank_id;
      QuestionBank.qus_bank_id.Edit = true;

      const QuestionBankKey = ['pillar', 'statement', 'status', 'qus_bank_id'];

      const QuestionBankValue = ['pillars_id', 'question', 'status', 'qus_bank_id'];

      QuestionBankKey.map((data, index) => {
        data !== 'status'
          ? (QuestionBank[data].value = actionData[QuestionBankValue[index]])
          : (QuestionBank[data].value = actionData[QuestionBankValue[index]] === 1 ? '1' : '0');
        return true;
      });
      setQuestionBank((prevState) => ({
        ...prevState,
      }));
    } else {
      QuestionBank.qus_bank_id.value = actionData.qus_bank_id;
      QuestionBank.qus_bank_id.Edit = false;
      setQuestionBank((prevState) => ({
        ...prevState,
      }));
      await dispatch(InsertUpdateDeleteQuestionBank(QuestionBank, true));
    }
  };

  return (
    <div>
      <div className='Content_main_width'>
        <div className='row'>
          <div className='col-sm-4'>
            <div className='TitlePane'>Create Statement</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4'>
            <div className='ques_bank_inputs'>
              <div className='heading'>Pillar</div>
              <Labelbox
                type='select'
                dropdown={MasterDropdows.getPillarsList}
                changeData={(data) => checkValidation(data, 'pillar')}
                value={QuestionBank.pillar.value}
                error={QuestionBank.pillar.error}
                errmsg={QuestionBank.pillar.errmsg}
              />
            </div>
          </div>
          <div className='col-sm-8'>
            <div className='ques_bank_inputs'>
              <div className='heading'>Statement</div>
              <Labelbox
                type='textarea'
                changeData={(data) => checkValidation(data, 'statement')}
                value={QuestionBank.statement.value}
                error={QuestionBank.statement.error}
                errmsg={QuestionBank.statement.errmsg}
              />
            </div>
          </div>
          {QuestionBank.qus_bank_id?.Edit && (
            <div className='col-sm-3'>
              <div className='ques_bank_inputs'>
                <div className='heading'>Status</div>
                <div style={{ width: '80%' }}>
                  <Labelbox
                    type='radio'
                    options={[
                      { label: 'Active', value: '1' },
                      { label: 'Inactive', value: '0' },
                    ]}
                    changeData={(data) => checkValidation(data, 'status')}
                    value={QuestionBank.status.value}
                    error={QuestionBank.status.error}
                    errmsg={QuestionBank.status.errmsg}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='row d-flex justify-content-end'>
          <div className='col-sm-4 btn_css'>
            <CustomButton
              btnName={`${QuestionBank.qus_bank_id.Edit ? 'UPDATE' : 'CREATE'}`}
              custombtnCSS={QuestionBank.qus_bank_id.Edit ? 'Update' : 'Primary'}
              onBtnClick={onSubmit}
            />
            <CustomButton btnName='CANCEL' custombtnCSS='Cancel' onBtnClick={handleCancel} />
          </div>
        </div>
        <div className='input-div' />
        <div className='row'>
          <div className='col-sm-12 question-table'>
            <div className='Pillar_lbl'>Pillar</div>
            <EnhancedTable
              header
              enableID
              search
              className=''
              tableSearchItems={[QuestionBank.table_pillar]}
              tableSearchItemsValue={(data) => setTableSearchItemsValue([data])}
              tableMainStatus='orange_tab'
              tabletitle='Alignment Statements'
              headCells={header}
              rows={QuestionBankTable.length === 0 ? QuestionBankTable : QuestionBankTable.GetQuestionBankList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(QuestionBankPage);
