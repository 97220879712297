/* eslint-disable react/jsx-no-bind */
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import CopyRightFooter from "../Footer/footer";
import login from "../../images/login_img.png";
import Logo from "../../images/aa_logo.svg";
import CustomButton from "../../component/Buttons/button";
import { videoURL } from "../../utils/baseUrl";
import "./intro.scss";
import Checkbox from "@mui/material/Checkbox";

const styles = {
  paperContainer: {
    backgroundImage: `url(${login})`,
    width: "100%",
    height: "100vh",
    backgroundSize: "100% 100%",
  },
};

const IntroPage = () => {
  const history = useHistory();
  const handleSubmit = () => {
    history.push("/Admin/ManageInitiative2");
  };

  const [istermsChecked, setIstermsChecked] = useState(false);
  return (
    <div className="login">
      <div className="container-fluid">
        <div
          className="row"
          style={{ marginRight: "0 !important", marginLeft: "0 !important" }}
        >
          <div
            className="col-sm-5"
            style={{
              paddingRight: "0 !important",
              paddingLeft: "0 !important",
            }}
          >
            <div style={styles.paperContainer} className="left_panel">
              <div className="IntroLogo">
                <img src={Logo} alt="" />
              </div>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="row justify-content-center">
              <div className="intro-head">
                <h6>
                  <span style={{ color: "#EE8E54" }}>
                    Welcome! Please take a moment to read
                  </span>
                </h6>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="list">
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      AlignedAround helps organisations define, measure, and
                      improve leadership alignment around their most strategic
                      investments and initiatives to improve outcomes.
                    </p>
                  </div>
                </div>

                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      As the initiator of the AlignedAround assessment, you will
                      need to know the number of people participating in the
                      process, between four and seven and have their names and
                      business email addresses on hand. These individuals should
                      be key leaders with significant influence over the
                      direction and delivery of the initiative being assessed.
                      If you are one of those people, you are also welcome to
                      participate as a respondent by entering your own details
                      on the next page.
                    </p>
                  </div>
                </div>

                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      Once you have entered the names and email addresses and
                      completed the other mandatory fields, the platform will
                      email the assessment respondents automatically.
                    </p>
                  </div>
                </div>
                <div className="intro_content">
                  <div className="intro_align_content">
                    <span />
                    <p>
                      As you are using the Lite version of our platform, the
                      assessment will remain open for seven days, which will
                      commence once respondent invitation emails have been sent.
                      A reminder email will automatically be sent to any
                      respondents who have not completed the assessment four
                      days after the first email.
                    </p>
                  </div>
                </div>

                <div className="intro_content2">
                  <div className="video_joint">
                    <div>
                      <div className="intro_align_content1">
                        <span />
                        <p>
                          Respondents will only be able to see the team results
                          when everyone has provided their input. Once this
                          occurs an email will be sent to respondents with a
                          link to view Group Summary and Group Overall Alignment
                          results.
                        </p>
                      </div>
                      <div className="intro_align_content1">
                        <span />
                        <p>
                          All responses are anonymous and only visible to the
                          AlignedAround team. All respondents are encouraged to
                          be candid with their answers.
                        </p>
                      </div>
                      {/* <div className="intro_align_content1">
                        <span />
                        <p>
                          If you have any questions, please contact us at
                        </p>{" "}
                        <a
                          href="mailto:admin@alignedaround.com"
                          style={{ fontSize: "18px", marginLeft: "-4px" }}
                        >
                          {" "}
                          admin@alignedaround.com
                        </a>
                      </div> */}
                    </div>

                    {/* <div className='reactPlayerView'>
                      <ReactPlayer url={videoURL} width={250} height={200} playing controls />
                      <div className='welcomeTitle'>Welcome Video</div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="intro_content" style={{ marginTop: "-13px" }}>
                <div className="intro_align_content">
                  <span />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ paddingRight: 10 }}>
                      If you have any questions, please contact us at
                    </p>
                    <a
                      href="mailto:admin@alignedaround.com"
                      style={{ fontSize: "18px", marginLeft: "-4px" }}
                    >
                      admin@alignedaround.com
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="list" style={{ marginLeft: "40px" }}>
                <Checkbox
                  style={{ marginRight: "10px" }}
                  value={istermsChecked}
                  onChange={(e) => setIstermsChecked(e.target.checked)}
                />
                I agree to the{" "}
                <a
                  target="_blank"
                  href="https://www.alignedaround.com/termsofuse"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href="https://www.alignedaround.com/privacy"
                  rel="noopener noreferrer"
                >
                  Privacy Policy{" "}
                </a>
                of AlignedAround
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="btnBegin">
                <CustomButton
                  btnName="BEGIN"
                  custombtnCSS="Primary"
                  onBtnClick={handleSubmit}
                  btnDisable={!istermsChecked}
                />
              </div>
            </div>
            <div className="user-footer">
              <CopyRightFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IntroPage;
