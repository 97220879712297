/* eslint-disable no-empty */
import axios from 'axios';
import { notification } from 'antd';
import {
  GET_CLIENT_DASHBOARD_MAIN,
  GET_DASHBOARD,
  GET_CLIENT_DASHBOARD_COUNT,
  GET_CLIENT_DASHBOARD,
  GET_INITIATIVE_DASHBOARD_COUNT,
} from '../utils/Constants';
import { GetMatrixInitiativeOverall } from './ScoreCardAction';
import { apiurl } from '../utils/baseUrl';

export const GetInitiativeDashboard = (data) => async (dispatch) => {
  try {
    axios({
      method: 'post',
      url: `${apiurl}get_dashboard_status_by_initiative_id`,
      data: {
        initiative_id: data.initiative_id || 0,
        status: (data.status === 0 ? '0' : data.status) || '',
        initiative: data.initiative || 0,
        client_id: data.client_id || 0,
      },
    }).then((response) => {
      dispatch({ type: GET_DASHBOARD, payload: response.data.data });
    });
  } catch (err) {}
};

export const GetClientsDashboard = () => async (dispatch) => {
  try {
    axios({
      method: 'get',
      url: `${apiurl}get_clients_dashboard`,
    }).then((response) => {
      dispatch({ type: GET_CLIENT_DASHBOARD_MAIN, payload: response.data.data });
    });
  } catch (err) {}
};

export const GetInitiativeDashboardCount = (data) => async (dispatch) => {
  try {
    axios({
      method: 'post',
      url: `${apiurl}get_dashboard_count_by_initiative_id`,
      data: {
        initiative_id: data,
      },
    }).then((response) => {
      dispatch({ type: GET_CLIENT_DASHBOARD_COUNT, payload: response.data.data });
    });
  } catch (err) {}
};

export const GetClientDashboardCount = (data) => async (dispatch) => {
  try {
    axios({
      method: 'post',
      url: `${apiurl}get_dashboard_client_details_counts`,
      data: {
        client_id: data.client_id,
        status: data.status,
      },
    }).then((response) => {
      if (data.status === 0) {
        dispatch({ type: GET_INITIATIVE_DASHBOARD_COUNT, payload: response.data.data });
      } else {
        dispatch({ type: GET_CLIENT_DASHBOARD, payload: response.data.data });
      }
    });
  } catch (err) {}
};

export const StopRespondent = (data) => async (dispatch) => {
  try {
    axios({
      method: 'post',
      url: `${apiurl}insert_status_stop`,
      data: {
        client_id: data.client_id,
        initiative_id: '',
        respondent_id: data.respondent_id,
      },
    }).then((response) => {
      if (response.data.status === 1) {
        notification.success({
          message: `This respondent is no longer active.`,
        });
        dispatch(
          GetInitiativeDashboard({ initiative_id: data.initiative_id, initiative: 1, client_id: '0', status: 0 }),
        );
      }
    });
  } catch (err) {}
};

export const updatePWeightRWeightEASoverAll =
  ({ initiativeId, pillarArr, respArr, EASDetails }) =>
  async (dispatch) => {
    try {
      axios({
        method: 'post',
        url: `${apiurl}update_pWeight_rWeight_eas_overAll`,
        data: {
          initiative_id: initiativeId || 0,
          client_id: JSON.parse(localStorage.getItem('client_id')),
          pillar: pillarArr,
          respondent: respArr,
          eas: EASDetails.EAS,
          p_eas: EASDetails.pEAS,
          i_eas: EASDetails.iEAS,
          o_iss: EASDetails.oISS,
        },
      }).then((response) => {
        dispatch(GetMatrixInitiativeOverall(initiativeId));
        if (response.data.status === 1) {
          notification.success({
            message: `Pillar and/or respondent weighting updated.`,
          });
        }
      });
    } catch (err) {}
  };
