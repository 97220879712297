/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import "./dash.scss";
// import CustomButton from "../../component/Buttons/button";

const Metrics = () => {
  // const history = useHistory();
  const [mostalign, setMostAlign] = useState({});
  const [leastalign, setLeastAlign] = useState({});
  const [overall, setOverall] = useState({});
  const dispatch = useDispatch();
  // const BackFunc = () => {
  //   history.push("/Admin/dashboard");
  // };

  
  /** ******* PAYLOAD */
  const Result = useSelector((state) => state.ScoreCardReducer);

  useEffect(() => {
    Result.GetMatrixInitiativeOverall.map((data, index) => {
      if (index === 1) {
        data.map((objs) => {
          setLeastAlign(objs.least_aligned);
          setMostAlign(objs.most_aligned);
          setOverall(objs.overall_success);
          return true;
        });
      }
      return true;
    });
  }, [dispatch, Result.GetMatrixInitiativeOverall]);
  return (
    <div>
      <div className="row">
        <div className="col-sm-3 space-around">
          {/* Structure 1 */}
          <div className="coresel c1">
            <div className="wave w1">
              Most <br />
              Aligned
            </div>
            <div>
              <div className="header">{mostalign.pillar_name}</div>
              <div className="grid-container">
                <div className="item1">
                  <div className="score">
                    <span style={{ color: "black" }}>High</span>
                    <br />
                    <span className="s1">{mostalign.high}</span>
                  </div>
                </div>
                <div className="item2">
                  <div className="score">
                    <span style={{ color: "black" }}>Low</span>
                    <br />
                    <span className="s2">{mostalign.low}</span>
                  </div>
                </div>
                <div className="item3">
                  <div className="score">
                    <span style={{ color: "black" }}>Avg</span>
                    <br />
                    <span className="s3">{mostalign.over_all_mean}</span>
                  </div>
                </div>
                <div className="item4">
                  <div className="score">
                    <span style={{ color: "black" }}>Std. Dev.</span>
                    <br />
                    <span className="s4">
                      {isNaN(mostalign.std_dev) ? "-" : mostalign.std_dev}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** */}
        </div>
        <div className="col-sm-3 space-around">
          <div className="coresel c2">
            <div className="wave w2">
              Least <br />
              Aligned
            </div>
            <div>
              <div className="header">{leastalign.pillar_name}</div>
              <div className="grid-container">
                <div className="item1">
                  <div className="score">
                    <span style={{ color: "black" }}>High</span>
                    <br />
                    <span className="s1">{leastalign.high}</span>
                  </div>
                </div>
                <div className="item2">
                  <div className="score">
                    <span style={{ color: "black" }}>Low</span>
                    <br />
                    <span className="s2">{leastalign.low}</span>
                  </div>
                </div>
                <div className="item3">
                  <div className="score">
                    <span style={{ color: "black" }}>Avg</span>
                    <br />
                    <span className="s3">{leastalign.over_all_mean}</span>
                  </div>
                </div>
                <div className="item4">
                  <div className="score">
                    <span style={{ color: "black" }}>Std. Dev.</span>
                    <br />
                    <span className="s4">
                      {isNaN(leastalign.std_dev) ? "-" : leastalign.std_dev}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3 space-around">
          {/* Structure 2 */}
          <div className="coresel c3">
            <div className="wave w3">
              Most <br />
              Aligned Pillars
            </div>
            <div className="metrics">
              <div>
                {Result?.GetMatrixInitiativeOverall.length > 0 &&
                  Result?.GetMatrixInitiativeOverall[1][0].most_aligned_pillars.map(
                    (data, index) => {
                      return (
                        <div className="metrics-grid" key={index}>
                          <div className="pname">{data.pillar_name}</div>
                          <div className="pvalue">{data.group_value}</div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
          {/** */}
        </div>
        <div className="col-sm-3 space-around">
          <div className="coresel c4">
            <div className="wave w4">
              Least <br />
              Aligned Pillars
            </div>
            <div className="metrics">
              <div>
                {Result?.GetMatrixInitiativeOverall.length > 0 &&
                  Result?.GetMatrixInitiativeOverall[1][0].least_aligned_pillars.map(
                    (data, index) => {
                      return (
                        <div className="metrics-grid" key={index}>
                          <div className="pname">{Result?.GetMatrixInitiativeOverall[1][0].most_aligned_pillars.find(
                              (o) => o.group_value === data.group_value
                            )
                              ? "-"
                              : data.pillar_name}</div>
                          <div className="pvalue">
                            {Result?.GetMatrixInitiativeOverall[1][0].most_aligned_pillars.find(
                              (o) => o.group_value === data.group_value
                            )
                              ? "-"
                              : data.group_value}
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="overall c7">
            <div className="wave w7">Sentiment (Overall Success)</div>
            <div>
              <div className="overall-grid-container">
                <div className="overall-item item1">
                  <div className="score">
                    <span style={{ color: "black" }}>High</span>
                    <br />
                    <span className="s1">{overall.high_value}</span>
                  </div>
                </div>
                <div className="overall-item item2">
                  <div className="score">
                    <span style={{ color: "black" }}>Low</span>
                    <br />
                    <span className="s2">{overall.low_value}</span>
                  </div>
                </div>
                <div className="overall-item item3">
                  <div className="score">
                    <span style={{ color: "black" }}>Avg</span>
                    <br />
                    <span className="s3">{overall.average}</span>
                  </div>
                </div>
                <div className="overall-item item4">
                  <div className="score">
                    <span style={{ color: "black" }}>Std. Dev.</span>
                    <br />
                    <span className="s4">
                      {isNaN(overall.std_dev) ? "-" : overall.std_dev}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {Result?.GetMatrixInitiativeOverall.length > 0 &&
          Result?.GetMatrixInitiativeOverall[1][0].package_type === 2 && (
            <div className="col-sm-9">
              <div className="row">
                <div className="col-sm-6">
                  <div className="coresel c5">
                    <div className="wave w5">
                      Most Aligned <br />
                      Statements
                    </div>
                    <div className="metrics">
                      <div>
                        {Result?.GetMatrixInitiativeOverall.length > 0 &&
                          Result?.GetMatrixInitiativeOverall[1][0].most_aligned_statements.map(
                            (data, index) => {
                              return (
                                <div className="metrics-grid" key={index}>
                                  <div className="pname">
                                    {data.pillar_name} ( {data.question} )
                                  </div>
                                  <div className="pvalue">{data.qus_mean}</div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6" style={{ padding: 0 }}>
                  <div className="coresel c6">
                    <div className="wave w6">
                      Least Aligned <br />
                      Statements
                    </div>
                    <div className="metrics">
                      <div>
                        {Result?.GetMatrixInitiativeOverall.length > 0 &&
                          Result?.GetMatrixInitiativeOverall[1][0].least_aligned_statements.map(
                            (data, index) => {
                              return (
                                <div className="metrics-grid" key={index}>
                                  <div className="pname">
                                    {data.pillar_name} ( {data.question} )
                                  </div>
                                  <div className="pvalue">{data.qus_mean}</div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
     {
     // <div className="btngrid">
      //  <CustomButton
      //    btnName="BACK"
       //   custombtnCSS="Success"
       //   onBtnClick={() => BackFunc()}
       // />
     // </div>
      }
    </div>
  );
};
export default Metrics;
