/* eslint-disable default-param-last */
import { GET_QUESTION_BANK } from '../utils/Constants';

const initalState = {
  GetQuestionBank: [],
};

const Questionbank = (state = initalState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_QUESTION_BANK:
      return { ...state, GetQuestionBank: payload };
    default:
      return state;
  }
};
export default Questionbank;
