/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Radar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../component/Buttons/button";
import { GetSummaryByResp } from "../../../actions/UserAction";
/*
import vision from "../../../images/chart_vision.svg";
import strategy from "../../../images/chart_strategy.svg";
import success from "../../../images/chart_success.svg";
import leader from "../../../images/chart_leader.svg";
import account from "../../../images/chart_account.svg";
import invest from "../../../images/chart_invest.svg";
import advocacy from "../../../images/chart_advocacy.svg";*/

const IChart = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [overall, setOverall] = useState([]);
  const [group, setGroup] = useState([]);
  const [pweight, setPweight] = useState([]);
  const [radar, setRadar] = useState({});
  const BackFunc = () => {
    history.push("/Admin/dashboard");
  };
  /** ****** API DISPATCH */
  useEffect(() => {
    dispatch(GetSummaryByResp({ resp_id: props.resp_id }));
  }, [dispatch, props.resp_id]);

  /** ******* PAYLOAD */
  const Result = useSelector((state) => state.UserReducer);

  useEffect(() => {
    Result.GetSummaryByResp.map((data, index) => {
      if (index === 0) {
        const overall = [];
        data.map((item) => {
          overall.push({
            name: item.name,
            average: item.average,
          });
          return true;
        });
        setOverall(overall);
      }
      if (index === 2) {
        const grp = [];
        data.map((item) => {
          grp.push({
            name: item.name,
            average: item.average,
          });
          return true;
        });
        setGroup(grp);
      }
      if (index === 6) {
        const grp = [];
        data.map((item) => {
          grp.push({
            name: item.name,
            p_weight: item.p_weight,
          });
          return true;
        });
        setPweight(grp);
      }
      return true;
    });
  }, [dispatch, Result]);

  useEffect(() => {
    let handler = "";
    const resp = [];
    const lbl = [];
    const grp = [];
    overall.map((data, index) => {
      if (index !== 7 && index !== 8) {
        lbl.push(data.name);
        resp.push(parseFloat(data.average.toFixed(2)));
      }
      return true;
    });
    group.map((data, index) => {
      if (index !== 7 && index !== 8) {
        grp.push(parseFloat(data.average.toFixed(2)));
      }
      return true;
    });
    if (props.state === "user") {
      handler = "You";
    }
    if (props.state === "admin") {
      handler = props.name;
    }

    const state = {
      dataRadar: {
        labels: lbl,
        datasets: [
          {
            label: "",
            fill: "#FFFFFF",
            borderWidth: 0,
            backgroundColor: "rgba(255,255,255, 0)",
            data: [1, 7, 7, 7, 7, 7, 7],
          },
          {
            label: "Min. Alignment",
            backgroundColor: "rgba(217,71,58,0)",
            borderColor: "#DC473A",
            data: [5.5, 5.5, 5.5, 5.5, 5.5, 5.5, 5.5],
          },
          {
            label: "Group",
            backgroundColor: "rgba(255, 255, 0, 0)",
            borderColor: "#FFFF00",
            data: grp,
          },
          {
            label: handler,
            backgroundColor: "rgba(177,217,239,0)",
            borderColor: "#0A829B",
            data: resp,
          },
        ],
      },
    };
    setRadar(state);
  }, [overall]);

  const scales = {
    scales: {
      r: {
        pointLabels: {
          color: "#204768",
          // fontSize: 15,
          fontWeight: 500,
          font: {
            size: 16,
          },
        },
      },
    },
    tick: {
      // beginAtZero: true,
      min: 0,
      max: 7,
      stepSize: 1,
    },
    plugins: {
      legend: {
        labels: {
          borderWidth: 0.8,
          // This more specific font property overrides the global property
          font: {
            size: 14,
          },
          boxWidth: 12,
        },
        // pointStyle: 'circle',
        position: "bottom",
        align: "center",
      },
    },
  };

  const getName = (name) => {
    if (name == "EAS") return "EAS: Executive Alignment Score";
    if (name == "OSS") return "OSS: Overall Sentiment Score";
    else return name;
  };
  return (
    <div>
      {Result?.GetSummaryByResp.length > 0 &&
      Result?.GetSummaryByResp[4][0].completed === 1 ? (
        <div>
          <div className=" row d-flex justify-content-around">
            <div className="chart-col-3">
              <div className="I-chart">
                {/* <img
                  src={vision}
                  alt=""
                  id={props.state === "user" ? "user_vision" : "vision"}
                  className="chart-img"
                />
                <img
                  src={strategy}
                  alt=""
                  id={props.state === "user" ? "user_strategy" : "strategy"}
                  className="chart-img"
                />
                <img
                  src={success}
                  alt=""
                  id={props.state === "user" ? "user_success" : "success"}
                  className="chart-img"
                />
                <img
                  src={leader}
                  alt=""
                  id={props.state === "user" ? "user_leader" : "leader"}
                  className="chart-img"
                />
                <img
                  src={account}
                  alt=""
                  id={props.state === "user" ? "user_account" : "account"}
                  className="chart-img"
                />
                <img
                  src={invest}
                  alt=""
                  id={props.state === "user" ? "user_invest" : "invest"}
                  className="chart-img"
                />
                <img
                  src={advocacy}
                  alt=""
                  id={props.state === "user" ? "user_advocacy" : "advocacy"}
                  className="chart-img"
                />
      */}
                <Radar
                  options={scales}
                  data={radar.dataRadar}
                  // width={200}
                  // height={200}
                />
              </div>
            </div>

            <div className="chart-col-9">
              <div className="chart-col-12">
                <div className="alignscore c1">
                  <div className="alignwave w1">
                    {props.state === "user" ? "Your" : ""} Alignment
                  </div>
                  <div>
                    <div className="IChart-grid">
                      <div className="group">Alignment Pillars</div>
                      {/* <div
                        className="group"
                        id={`${
                          Number(localStorage.getItem("user_type")) === 0
                            ? "sec-col"
                            : ""
                        }`}
                      >
                        {`${
                          Number(localStorage.getItem("user_type")) === 0
                            ? "P Weight %"
                            : ""
                        }`}
                      </div> */}
                      <div className="group" id="sec-col">
                        Group
                      </div>
                      <div className="group" id="sec-col">
                        You
                      </div>
                      <div>
                        {overall.map((item, index) => {
                          return (
                            <div key={index}>
                              {index !== 8 && index !== 7 && (
                                <div className="col1">{item.name}</div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {/* <div className={`${Number(localStorage.getItem('user_type')) === 0 ? 'col-group' : ''}`}>
                        {Number(localStorage.getItem('user_type')) === 0 &&
                          pweight.map((item, index) => {
                            return (
                              <div className='col2' key={index}>
                                {item.p_weight || '-'}
                              </div>
                            );
                          })}
                      </div> */}
                      <div className="col-group">
                        {group.map((item, index) => {
                          return (
                            <div key={index}>
                              {index !== 8 && index !== 7 && (
                                <div className="col2">
                                  {(item.average && item.average.toFixed(2)) ||
                                    "-"}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-group">
                        {overall.map((item, index) => {
                          return (
                            <div key={index}>
                              {index !== 8 && index !== 7 && (
                                <div className="col2">
                                  {(item.average && item.average.toFixed(2)) ||
                                    "-"}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sentiment-group">
                  <div className="I-sentiment">
                    <div>{overall ? getName(overall[7]?.name) : ""}</div>
                    <div>{group ? group[7]?.average.toFixed(2) : ""}</div>
                    <div>{overall ? overall[7]?.average.toFixed(2) : ""}</div>
                  </div>
                  <div className="I-sentiment">
                    <div>{overall ? getName(overall[8]?.name) : ""} </div>
                    <div>{group ? group[8]?.average.toFixed(2) : ""}</div>
                    <div>{overall ? overall[8]?.average.toFixed(2) : ""}</div>
                  </div>
                </div>
                {/* {Number(localStorage.getItem("user_type")) === 0 && (
                  <div className="r_weight w1">
                    <div>R-Weight %</div>
                    <div>
                      {(Result?.GetSummaryByResp.length > 0 &&
                        Result?.GetSummaryByResp[7][0].r_weight) ||
                        "-"}
                    </div>
                  </div>
                )} */}
              </div>

              <div className="chart-row overallInterface">
                <div className="chart-col-10">
                  <div className="I-coresel c1">
                    <div className="wave w1">Overall Alignment Inferences</div>
                    <div style={{ padding: "10px" }}>
                      {Result?.GetSummaryByResp.length > 0 &&
                        Result?.GetSummaryByResp[3][1].map((data, index) => {
                          return (
                            <div style={{ display: "flex" }} key={index}>
                              <div className="dot" />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data.inference,
                                }}
                              ></div>
                              {/* <div>{data.inference}</div> */}
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  {/* <div>
                    <div className="legendContainerAlignment">
                      <div>EAS: Executive Alignment Score.</div>
                    </div>
                    <div className="legendContainerAlignment">
                      <div>OSS: Overall Sentiment Score.</div>
                    </div>
                  </div> */}
                  {
                    //props.showBtn ? (
                    //  <div className="btnalign">
                    // <CustomButton
                    //   btnName="BACK"
                    //  custombtnCSS="Success"
                    //   onBtnClick={() => BackFunc()}
                    // />
                    // </div>
                    //  ) : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="waitmsg">
          This initiative is in progress. Group alignment and comparison data
          will be available once all respondents have completed the assessment
          or by the end date.
        </div>
      )}
    </div>
  );
};
export default IChart;
