/* eslint-disable default-param-last */
import {
  GET_RANGE,
  GET_COUNTRY,
  GET_ETHNICITY,
  GET_QUESTION_PILLARWISE,
  INSERT_ANSWERS,
  INSERT_DEMO,
  GET_SUMMARY_RES,
  GET_RESP_DEMO,
  GET_ANCESTRY,
  GET_NATIONALITY,
} from "../utils/Constants";

const initalState = {
  GetRange: [],
  GetCountry: [],
  GetEthnicity: [],
  GetQuestion: [],
  PostDemo: "",
  PostAnswers: "",
  GetSummaryByResp: [],
  GetRespDemo: [],
  GetAncestry: [],
  getNationality: [],
};
const User = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RANGE:
      return { ...state, GetRange: payload };
    case GET_COUNTRY:
      return { ...state, GetCountry: payload };
    case GET_ETHNICITY:
      return { ...state, GetEthnicity: payload };
    case GET_NATIONALITY:
      return { ...state, getNationality: payload };
    case GET_QUESTION_PILLARWISE:
      return { ...state, GetQuestion: payload };
    case INSERT_DEMO:
      return { ...state, PostDemo: payload };
    case INSERT_ANSWERS:
      return { ...state, PostAnswers: payload };
    case GET_SUMMARY_RES:
      return { ...state, GetSummaryByResp: payload };
    case GET_RESP_DEMO:
      return { ...state, GetRespDemo: payload };
    case GET_ANCESTRY:
      return { ...state, GetAncestry: payload };
    default:
      return state;
  }
};
export default User;
