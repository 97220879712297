/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Route } from "react-router-dom";
import vision from "../../images/vision.svg";
import strategy from "../../images/strategy.svg";
import success from "../../images/success.svg";
import leader from "../../images/leader.svg";
import account from "../../images/account.svg";
import invest from "../../images/invest.svg";
import advocacy from "../../images/advocacy.svg";
import emotion from "../../images/emotion.svg";
import score from "../../images/score.svg";
import feedbackImg from "../../images/feedback.png";
import Book from "../../images/Book.svg";
import Logo from "../../images/aa_logo.svg";
import CopyRightFooter from "../../pages/Footer/footer";
import "./UserNavbar.scss";
// import LogoutImage from '../../images/Logout-icon.png';
// import Logout from '../../images/logout.png';
import CustomButton from "../../component/Buttons/button";
// ---------------------------------------------------Pages-------------------------------------------------------------//
import Demo from "../../pages/Demography/demo";
import feedback from "../../pages/Feedback";
import Pillars from "../../pages/Pillars/pillars";
import ScoreCardTab from "../../pages/ScoreCardDash/dash";
// ---------------------------------------------------------------------------------------------------------------------//

const useStyles = makeStyles(() => ({
  root: {
    // display: 'flex',
  },
  appBar: {
    zIndex: 99,
    height: 90,
    display: "flex",
    alignItems: "flex-end",
    paddingRight: "2%",
  },
}));

const UserNavbar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const url = window.location.href.split("/");
  const pathname = url[url.length - 1];
  const key = pathname.charAt(pathname.length - 1);
  const status = JSON.parse(localStorage.getItem("status_respondent"));
  console.log(key, status, "key");

  const onMenuClick = (id) => {
    const params = {
      type: "user",
      id: JSON.parse(localStorage.getItem("user_id")),
    };
    const items = document.querySelectorAll("li");
    items.forEach((item) => {
      item.classList.remove("active");
    });
    const element = document.getElementById(id);
    element.classList.add("active");
    history.push({
      pathname: `/User/${id.toString()}`,
      state: {
        pathname: "user",
        params,
      },
    });
  };

  /**
   *
   */
  function handleLogout() {
    localStorage.clear();
    history.push("/Userlogin");
    window.location.reload();
  }

  useEffect(() => {
    if (
      pathname !== "" &&
      pathname !== "Userlogin" &&
      !localStorage.getItem("user_type")
    ) {
      localStorage.clear();
      history.push("/Userlogin");
      window.location.reload();
    }
  }, [history, pathname]);
  return (
    <div className={`Admin_nav ${classes.root}`}>
      <div className="container-fluid">
        <div className="row margin-remove">
          <div className="side-menu-width">
            <div className="usernav-bar-body usernav">
              <div>
                <center>
                  <img src={Logo} alt="" />
                </center>
              </div>
              <ul>
                <li
                  className={
                    pathname === "demo" ? "active" : status === 2 ? "gray" : ""
                  }
                  id="demo"
                  // onClick={() => status === 2 && onMenuClick("demo")}
                >
                  <img src={Book} alt="" id="demo-img" />
                  <span id="demo">Demographics</span>
                </li>
                <li
                  className={
                    key === "1"
                      ? "active"
                      : status === 2 || 1 < Number(key)
                      ? "gray"
                      : ""
                  }
                >
                  <img src={vision} alt="" />
                  <span id="s1">Vision</span>
                </li>
                <li
                  className={
                    key === "2"
                      ? "active"
                      : status === 2 || 2 < Number(key)
                      ? "gray"
                      : ""
                  }
                >
                  <img src={strategy} alt="" />
                  <span id="s2">Strategy</span>
                </li>
                <li
                  className={
                    key === "3"
                      ? "active"
                      : status === 2 || 3 < Number(key)
                      ? "gray"
                      : ""
                  }
                >
                  <img src={success} alt="" />
                  <span id="s3">Success</span>
                </li>
                <li
                  className={
                    key === "4"
                      ? "active"
                      : status === 2 || 4 < Number(key)
                      ? "gray"
                      : ""
                  }
                >
                  <img src={leader} alt="" />
                  <span id="s4">Leader/Follower</span>
                </li>
                <li
                  className={
                    key === "5"
                      ? "active"
                      : status === 2 || 5 < Number(key)
                      ? "gray"
                      : ""
                  }
                >
                  <img src={account} alt="" />
                  <span id="s5">Accountability</span>
                </li>
                <li
                  className={
                    key === "6"
                      ? "active"
                      : status === 2 || 6 < Number(key)
                      ? "gray"
                      : ""
                  }
                >
                  <img src={invest} alt="" />
                  <span id="s6">Investment</span>
                </li>
                <li
                  className={
                    key === "7"
                      ? "active"
                      : status === 2 || 7 < Number(key)
                      ? "gray"
                      : ""
                  }
                >
                  <img src={advocacy} alt="" />
                  <span id="s7">Advocacy</span>
                </li>
                <li
                  className={
                    key === "8"
                      ? "active"
                      : status === 2 || 8 < Number(key)
                      ? "gray"
                      : ""
                  }
                >
                  <img src={emotion} alt="" />
                  <span id="s8">Sentiment</span>
                </li>
                {/* <li
                  className={
                    pathname === "feedback"
                      ? "active"
                      : status === 2 || pathname === "score"
                      ? "gray"
                      : ""
                  }
                  id="feedback"
                  // onClick={() => status === 2 && onMenuClick("feedback")}
                >
                  <img src={feedbackImg} alt="" />
                  <span id="feedback">Feedback</span>
                </li> */}
                <li
                  className={pathname === "score" ? "active" : ""}
                  id="score"
                  onClick={() => status === 2 && onMenuClick("score")}
                >
                  <img src={score} alt="" />
                  <span id="score_card">Scorecard</span>
                </li>
              </ul>
              <div className="version">Version 2.0.0</div>
            </div>
          </div>
          <div className="main-content-width main_content">
            <main>
              <div className="menu">
                <div className="nav_name">
                  <div className="user_name_text">
                    {localStorage
                      .getItem("res_name")
                      ?.substring(
                        1,
                        localStorage.getItem("res_name").length - 1
                      )}
                  </div>
                  {localStorage.getItem("status_respondent") &&
                    Number(localStorage.getItem("status_respondent")) === 2 && (
                      // <img src={Logout} className='logout' alt='' onClick={handleLogout} />
                      <CustomButton
                        btnName="Exit"
                        custombtnCSS="exit"
                        onBtnClick={handleLogout}
                      />
                    )}
                </div>
              </div>

              <Route path={`${props.match.path}/demo`} component={Demo} exact />
              <Route
                path={`${props.match.path}/feedback`}
                component={feedback}
                exact
              />
              <Route
                path={`${props.match.path}/pillar`}
                component={Pillars}
                exact
              />
              <Route
                path={`${props.match.path}/score`}
                component={ScoreCardTab}
              />
              <div className="myfooter">
                <CopyRightFooter />
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserNavbar;
