/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable from '../../component/DynTable/table';
import Labelbox from '../../helpers/labelbox/labelbox';
import CustomButton from '../../component/Buttons/button';
import './CreateClient.scss';
import { GetClients } from '../../actions/MasterAction';
import { InsertUpdateDeleteClients } from '../../actions/ClientAction';
import ValidationLibrary from '../../helpers/ValidationFunction/validationfunction';
import { GetCountry } from '../../actions/UserAction';

const CreateClientPage = () => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState([]);
  const [ClientsTable, setClientsTable] = useState([]);
  const [CreateClient, setCreateClient] = useState({
    client_id: {
      value: 0,
      Edit: false,
    },
    client_name: {
      value: '',
      validation: [{ name: 'required' }],
      error: null,
      errmsg: null,
    },
    email: {
      value: '',
      validation: [{ name: 'required' }, { name: 'emailNew' }],
      error: null,
      errmsg: null,
    },
    contact_person: {
      value: '',
      validation: [{ name: 'required' }],
      error: null,
      errmsg: null,
    },
    status: {
      value: '1',
      validation: [{ name: 'required' }],
      error: null,
      errmsg: null,
    },
    hq_location: {
      value: '',
      validation: [{ name: 'required' }],
      error: null,
      errmsg: null,
    },
    industry: {
      value: '',
      validation: [{ name: 'required' }],
      error: null,
      errmsg: null,
    },
  });

  const header = [
    { id: 'clientname', label: 'Client Name' },
    { id: 'contact_person', label: 'Contact Person' },
    { id: 'email', label: 'Email Address' },
    { id: 'industry', label: 'Industry' },
    { id: 'hq_location', label: 'HQ Location' },
    { id: 'status', label: 'Status' },
    { id: 'create_date', label: 'Create Date' },
    { id: 'action', label: 'Action' },
  ];

  /// /////************ API DATA PROCESS************////////////

  useEffect(() => {
    dispatch(GetClients());
    dispatch(GetCountry());
  }, [dispatch]);

  const MasterData = useSelector((state) => state.MasterReducer);
  const UserAction = useSelector((state) => state.UserReducer);

  /** ********* RESPONSE */
  useEffect(() => {
    const countryData = [
      { id: 'USA', value: 'USA' },
      { id: 'Australia', value: 'Australia' },
    ];
    UserAction.GetCountry &&
    UserAction.GetCountry.map((data) => {
        if (data.value !== 'USA' && data.value !== 'Australia') {
          countryData.push({
            id: data.value,
            value: data.value,
          });
        }
        return true;
      });
    setCountry(countryData);
  }, [UserAction]);
  useEffect(() => {
    const getClientsList = [];

    MasterData.GetClients.map((data, index) => {
      getClientsList.push({
        id: index + 1,
        client_name: data.client_name,
        contact_person: data.contact_person,
        email: data.email,
        industry: data.industry,
        hq_location: data.hq_location,
        status: data.status === 1 ? 'Active' : 'Inactive',
        created_on: moment(data.created_on).format('MMM DD yyyy'),
        action: (
          <div className='action-div'>
            <div
              className={`actionicon ${CreateClient.client_id.Edit ? 'edit' : 'cc_none'}`}
              onClick={() => onAction(data, 1)}
            >
              Edit
            </div>
            &nbsp;
            <div
              className={`actionicon ${CreateClient.client_id.Edit ? 'delete' : 'cc_none'}`}
              onClick={() => onAction(data, 2)}
            >
              Delete
            </div>{' '}
          </div>
        ),
      });
      return true;
    });

    setClientsTable({ getClientsList });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MasterData.GetClients, CreateClient]);

  /// ///////////////

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   */
  function checkValidation(data, key) {
    const errorcheck = ValidationLibrary.checkValidation(data, CreateClient[key].validation);
    const dynObj = {
      value: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
      validation: CreateClient[key].validation,
      /// /// edit /////
      edit: CreateClient[key].Edit ? CreateClient[key].Edit : false,
      /// /// table data//////
      // dropdown: CreateClient[key].dropdown ? CreateClient[key].dropdown : false,
      // name: CreateClient[key].name ? CreateClient[key].name : false,
      // type: CreateClient[key].type ? CreateClient[key].type : false,
    };

    setCreateClient((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }

  const handleCancel = () => {
    const CreateClientKey = ['client_name', 'email', 'contact_person', 'client_id', 'industry', 'hq_location'];

    CreateClientKey.map((data) => {
      data !== 'status' ? (CreateClient[data].value = '') : (CreateClient[data].value = '1');
      return true;
    });
    CreateClient.client_id.Edit = false;
    setCreateClient((prevState) => ({
      ...prevState,
    }));
  };
  /**
   *
   */
  async function onSubmit() {
    const mainvalue = {};
    const targetkeys = Object.keys(CreateClient);
    for (const i in targetkeys) {
      const errorcheck = ValidationLibrary.checkValidation(
        CreateClient[targetkeys[i]].value,
        CreateClient[targetkeys[i]].validation,
      );
      CreateClient[targetkeys[i]].error = !errorcheck.state;
      CreateClient[targetkeys[i]].errmsg = errorcheck.msg;
      mainvalue[targetkeys[i]] = CreateClient[targetkeys[i]].value;
    }
    const filtererr = targetkeys.filter((obj) => CreateClient[obj].error === true);
    if (filtererr.length > 0) {
      // setQuestion({ error: true });
    } else {
      await dispatch(InsertUpdateDeleteClients(CreateClient));
      handleCancel();
    }
    setCreateClient((prevState) => ({
      ...prevState,
    }));
  }

  const onAction = async (actionData, type) => {
    if (type === 1) {
      CreateClient.client_id.value = actionData.client_id;
      CreateClient.client_id.Edit = true;

      const CreateClientKey = ['client_name', 'email', 'contact_person', 'industry', 'hq_location', 'client_id'];

      const CreateClientValue = ['client_name', 'email', 'contact_person', 'industry', 'hq_location', 'client_id'];

      CreateClientKey.map((data, index) => {
        data !== 'status'
          ? (CreateClient[data].value = actionData[CreateClientValue[index]])
          : (CreateClient[data].value = actionData[CreateClientValue[index]] === 1 ? '1' : '0');
        return true;
      });

      setCreateClient((prevState) => ({
        ...prevState,
      }));
    } else {
      await dispatch(InsertUpdateDeleteClients(CreateClient, actionData.client_id));
    }
  };
  return (
    <div>
      <div className='Content_main_width'>
        <div className='row'>
          <div className='col-sm-4'>
            <div className='TitlePane'>Create Client</div>
          </div>
        </div>
        <div className='row input-row'>
          <div className='col-sm-4'>
            <div className='ques_bank_inputs'>
              <div className='heading'>Client Name</div>
              <Labelbox
                type='text'
                changeData={(data) => checkValidation(data, 'client_name')}
                value={CreateClient.client_name.value}
                error={CreateClient.client_name.error}
                errmsg={CreateClient.client_name.errmsg}
              />
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='ques_bank_inputs'>
              <div className='heading'>Contact Person</div>
              <Labelbox
                type='text'
                // placeholder={'Contact Person'}
                changeData={(data) => checkValidation(data, 'contact_person')}
                value={CreateClient.contact_person.value}
                error={CreateClient.contact_person.error}
                errmsg={CreateClient.contact_person.errmsg}
              />
            </div>
          </div>
          <div className='col-sm-4'>
            <div>
              <div className='ques_bank_inputs'>
                <div className='heading'>Email Address</div>
                <div>
                  <Labelbox
                    type='mailbox'
                    changeData={(data) => checkValidation(data, 'email')}
                    value={CreateClient.email.value}
                    error={CreateClient.email.error}
                    errmsg={CreateClient.email.errmsg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row input-row'>
          <div className='col-sm-4'>
            <div className='ques_bank_inputs'>
              <div className='heading'>HQ Location </div>
              <Labelbox
                type='select'
                dropdown={country}
                changeData={(data) => checkValidation(data, 'hq_location')}
                value={CreateClient.hq_location.value}
                error={CreateClient.hq_location.error}
                errmsg={CreateClient.hq_location.errmsg}
              />
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='ques_bank_inputs'>
              <div className='heading'>Industry </div>
              <Labelbox
                type='text'
                changeData={(data) => checkValidation(data, 'industry')}
                value={CreateClient.industry.value}
                error={CreateClient.industry.error}
                errmsg={CreateClient.industry.errmsg}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          {CreateClient.client_id?.Edit && (
            <div className='col-sm-3'>
              <div className='ques_bank_inputs'>
                <div className='heading'>Status</div>
                <div style={{ width: '80%' }}>
                  <Labelbox
                    type='radio'
                    options={[
                      { label: 'Active', value: '1' },
                      { label: 'Inactive', value: '0' },
                    ]}
                    changeData={(data) => checkValidation(data, 'status')}
                    value={CreateClient.status.value}
                    error={CreateClient.status.error}
                    errmsg={CreateClient.status.errmsg}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={`col-sm-${CreateClient.client_id?.Edit ? '9' : '12'} d-flex justify-content-end`}>
            <div className={`${CreateClient.client_id?.Edit ? 'btn_css_9' : 'btn_css_12'}`}>
              <CustomButton
                btnName={`${CreateClient.client_id.Edit ? 'UPDATE' : 'CREATE'}`}
                custombtnCSS={CreateClient.client_id.Edit ? 'Update' : 'Primary'}
                onBtnClick={onSubmit}
              />
              <CustomButton btnName='CANCEL' custombtnCSS='Cancel' onBtnClick={handleCancel} />
            </div>
          </div>
        </div>
        <div className='input-div' />
        <div className='row'>
          <div className='col-sm-12'>
            <EnhancedTable
              header
              search
              tableMainStatus='orange_tab'
              tabletitle='Clients '
              headCells={header}
              rows={ClientsTable.length === 0 ? ClientsTable : ClientsTable.getClientsList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateClientPage;
